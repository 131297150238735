import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { limitItemsCount } from "constants/index";
import ActionsTable from "components/ProjectDevelopersTable/ActionsTable";
import { DeveloperEntity } from "graphql/generated";
import NameCell from "components/ProjectDevelopersTable/NameCell";
import { GridCellParams } from "@mui/x-data-grid";
import { Developer } from "screens/ProjectDetailsScreen/types";
import RankCell from "components/ProjectDevelopersTable/RankCell";
import RateCell from "components/ProjectDevelopersTable/RateCell";
import { ColorPicker } from "./ColorPicker/ColorPicker";
import { useTranslation } from "react-i18next";

interface ProjectDeveloperTableProps {
  rows: Developer[];
  count: number;
  allDevelopers: Partial<DeveloperEntity>[];
  setDevelopers: (value: any) => void;
}

const ProjectDeveloperTable: React.FC<ProjectDeveloperTableProps> = ({
  rows,
  count,
  allDevelopers,
  setDevelopers,
}) => {
  const { t, i18n } = useTranslation(["project-details"]);
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("name"),
        width: 220,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <NameCell {...{ params }} allDevelopers={allDevelopers} />
        ),
      },
      {
        field: "defaultRank",
        headerName: t("rank"),
        width: 220,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => <RankCell {...{ params }} />,
      },
      {
        field: "defaultRate",
        headerName: t("rate"),
        width: 100,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => <RateCell {...{ params }} />,
      },
      {
        field: "color",
        headerName: t("color"),
        width: 100,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => {
          return params.row.firstName ? <ColorPicker params={params} /> : null;
        },
      },
      {
        field: "actions",
        headerName: t("actions"),
        renderCell: (params: GridCellParams) => {
          return params.row.firstName ? (
            <ActionsTable
              {...{ params }}
              developers={rows}
              setDevelopers={setDevelopers}
            />
          ) : (
            <></>
          );
        },
        width: 20,
        minWidth: 100,
        filterable: false,
        sortable: false,
      },
    ],
    [allDevelopers, rows, i18n.language]
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={count}
      checkboxSelection={false}
      autoHeight
      autoPageSize
      disableSelectionOnClick
      disableColumnMenu
    />
  );
};

export default ProjectDeveloperTable;
