import { TFunction } from "i18next";
import * as yup from "yup";

export const projectSchemaCreate = ({
  isAdmin,
  t,
}: {
  isAdmin: boolean;
  t: TFunction;
}) =>
  yup.object().shape({
    name: yup.string().required(
      t("requiredField", {
        fieldName: t("name"),
        interpolation: { escapeValue: false },
      })
    ),
    isWithChart: yup.boolean(),
    ...(isAdmin && {
      userId: yup
        .string()
        .required(t("requiredField", { fieldName: t("manager") })),
    }),
  });
