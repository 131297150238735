import Stack from "@mui/material/Stack";
import { MouseEvent, useState } from "react";
import { TemplatePdf } from "../TemplatePdf/TemplatePdf";
import { ColorSelectedBlock } from "../ColorSelectedBlock/ColorSelectedBlock";
import { useChangePdfColorsMutation } from "graphql/generated";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "constants/index";
import { useTranslation } from "react-i18next";
import { isEqual } from "utils";
import { INITIAL_COLOR_DEFAULT } from "./constants";
import { ChildColorStackType, PaletteSettingsTabProps } from "./types";
import { getInitValuesAfterRequest, getPDFColorsInput } from "./helpers";
import { ColorPickerPopover } from "./ColorPickerPopover";

export const PaletteSettingsTab = ({
  pdfColorsData,
}: PaletteSettingsTabProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  const { handleChangeAlertParameters } = useAlertContext();

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const initColorsAfterRequest = getInitValuesAfterRequest(pdfColorsData);

  const [colorsStack, setColorsStack] = useState(
    initColorsAfterRequest || INITIAL_COLOR_DEFAULT
  );
  const [parentStack, setParentStack] = useState<
    keyof typeof INITIAL_COLOR_DEFAULT | null
  >(null);

  const [nameForChange, setNameForChange] =
    useState<ChildColorStackType | null>(null);

  const [changePdfColors] = useChangePdfColorsMutation({
    onCompleted: () => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.success,
        message: t("successPdfColor"),
      });
    },
    onError: (error) => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.error,
        message: error.message,
      });
    },
  });

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    colorForStack: ChildColorStackType
  ) => {
    setNameForChange(colorForStack);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDisabledApplyBtn = isEqual(colorsStack, initColorsAfterRequest);
  const isDisabledDefaultBtn = isEqual(colorsStack, INITIAL_COLOR_DEFAULT);

  const handleApply = () => {
    changePdfColors({
      variables: {
        input: getPDFColorsInput(colorsStack),
      },
    });
  };

  const handleSkipToDefault = () => {
    setColorsStack(INITIAL_COLOR_DEFAULT);
  };

  const handleChangeColor = (newColor: string) => {
    if (!nameForChange) return;
    for (const key in colorsStack) {
      if (nameForChange in colorsStack[key as keyof typeof colorsStack]) {
        setColorsStack((prevState) => {
          return {
            ...prevState,
            [key]: {
              ...prevState[key as keyof typeof colorsStack],
              [nameForChange]: newColor,
            },
          };
        });
      }
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", gap: "20px", flex: 1 }}>
      <Stack
        sx={{
          height: "calc(100vh - 160px)",
          overflow: "auto",
          flex: 1,
          maxWidth: "400px",
          paddingTop: "10px",
          paddingRight: "5px",
        }}>
        <ColorSelectedBlock
          colorsStack={colorsStack}
          setParentStack={setParentStack}
          onClickByColor={handleClick}
          onApply={handleApply}
          onSkipToDefault={handleSkipToDefault}
          isDisabledApplyBtn={isDisabledApplyBtn}
          isDisabledDefaultBtn={isDisabledDefaultBtn}
        />
      </Stack>

      <Stack
        sx={{
          height: "calc(100vh - 160px)",
          overflow: "auto",
          flex: 1,
          borderLeft: "#000 solid 1px",
        }}>
        <TemplatePdf
          headerColors={colorsStack.header}
          estimateColors={colorsStack.estimate}
          rateColors={colorsStack.rate}
          paymentsColors={colorsStack.payments}
          recommendationColors={colorsStack.recommendation}
          teamColors={colorsStack.team}
          commentsColors={colorsStack.comments}
          aboutUsColors={colorsStack.aboutUs}
        />
      </Stack>
      <ColorPickerPopover
        anchorEl={anchorEl}
        colorsStack={colorsStack}
        handleChangeColor={handleChangeColor}
        handleClose={handleClose}
        nameForChange={nameForChange}
        open={open}
        parentStack={parentStack}
        idPopover={idPopover}
      />
    </Stack>
  );
};
