export const ROUTES = [
  {
    name: "projects",
    path: "/",
  },
  {
    name: "users",
    path: "/users",
  },
  {
    name: "developers",
    path: "/developers",
  },
  {
    name: "settings",
    path: "/settings",
  },
];
