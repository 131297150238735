import React from "react";
import { TasksTabProps } from "screens/ProjectDetailsScreen/types";
import ProjectFilesTable from "components/ProjectFilesTable";
import Button from "@mui/material/Button";
import { styles } from "screens/ProjectDetailsScreen/FilesTab/styles";
import Box from "@mui/material/Box";
import {
  useCreateFileMutation,
  useCreateFilePreviewMutation,
} from "graphql/generated";
import { useParams } from "react-router-dom";
import { GET_PROJECT } from "graphql/queries/getProject.gql";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FilesTab: React.FC<TasksTabProps> = ({ files, count }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [createFileMutation] = useCreateFileMutation();
  const [createFilePreviewMutation] = useCreateFilePreviewMutation();
  const navigate = useNavigate();

  const previewPDF = async () => {
    const res = await createFilePreviewMutation({
      variables: {
        projectId: Number(id),
      },
      refetchQueries: () => [
        {
          query: GET_PROJECT,
          variables: {
            projectId: Number(id),
          },
        },
      ],
    });

    if (res.data) {
      window.open(
        `${process.env.REACT_APP_URI}/uploads/previews/${res.data.createFilePreview}`,
        "_blank"
      );
    }
    if (res.errors) {
      navigate("*");
    }
  };

  const createPDF = async () => {
    const res = await createFileMutation({
      variables: {
        projectId: Number(id),
      },
      refetchQueries: () => [
        {
          query: GET_PROJECT,
          variables: {
            projectId: Number(id),
          },
        },
      ],
    });
    if (res.data) {
      saveAs(
        `${process.env.REACT_APP_URI}/uploads/files/${res.data.createFile.name}`,
        "estimale.pdf"
      );
    }
    if (res.errors) {
      navigate("*");
    }
  };

  return (
    <Box sx={styles.container}>
      <Button
        variant="contained"
        onClick={previewPDF}
        sx={styles.buttonPreview}>
        {t("preview")}
      </Button>
      <ProjectFilesTable rows={files} count={count} />
      <Button variant="contained" onClick={createPDF} sx={styles.buttonCreate}>
        {t("create")}
      </Button>
    </Box>
  );
};

export default FilesTab;
