import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "components/Layout/Layout";
import {
  GetProjectCustomQuery,
  ProjectEntity,
  useGetProjectCustomQuery,
} from "graphql/generated";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TasksTab from "screens/ProjectDetailsScreen/TasksTab";
import SettingsTab from "screens/ProjectDetailsScreen/SettingsTab";
import DevelopersTab from "screens/ProjectDetailsScreen/DevelopersTab";
import FilesTab from "screens/ProjectDetailsScreen/FilesTab";

import {
  Developer,
  FormDataProjectDevelopers,
} from "screens/ProjectDetailsScreen/types";
import { useUserAccess } from "../../hooks";
import { useTranslation } from "react-i18next";
import { a11yProps, getSprints } from "./helpers";
import { TabPanel } from "./TabPanel";

const ProjectDetailsScreen = () => {
  const { t } = useTranslation(["project-details"]);
  const [value, setValue] = useState<number>(0);
  const [isChart, setIsChart] = useState<boolean>(false);
  const { id } = useParams();
  const { isEstimator } = useUserAccess();

  const tabsData = [
    { label: t("tasks") },
    { label: t("settings") },
    ...(!isEstimator ? [{ label: t("developers") }] : []),
    ...(!isEstimator ? [{ label: t("files") }] : []),
  ];

  const [projectDevelopers, setProjectDevelopers] = useState<
    Array<Developer> | []
  >([]);

  const onCompleted = (data: GetProjectCustomQuery) => {
    setIsChart(data.getProject.isWithChart);

    if (data) {
      setProjectDevelopers(() =>
        data?.getProject.projectDevelopers.map(
          (item: FormDataProjectDevelopers) => {
            return {
              defaultRank: item.rank,
              defaultRate: item.rate,
              id: item.developerId,
              firstName: item.developer.firstName,
              lastName: item.developer.lastName,
            };
          }
        )
      );
    }
  };

  const { data, refetch } = useGetProjectCustomQuery({
    variables: {
      projectId: Number(id),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout namePage={data?.getProject?.name}>
      <Tabs value={value} onChange={handleChange}>
        {tabsData.map((tabData, i) => (
          <Tab label={tabData.label} key={tabData.label} {...a11yProps(i)} />
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <TasksTab
          isChart={isChart}
          sprints={getSprints(data)}
          developers={projectDevelopers}
          id={id}
          projectId={data?.getProject.id}
          refetchProjectData={refetch}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SettingsTab
          setIsChart={setIsChart}
          isChart={isChart}
          project={data?.getProject as ProjectEntity}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DevelopersTab id={Number(id)} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FilesTab
          files={data?.getProject?.files || []}
          count={data?.getProject?.files?.length || 0}
        />
      </TabPanel>
    </Layout>
  );
};

export default ProjectDetailsScreen;
