export const INITIAL_COLOR_DEFAULT = {
  header: {
    headerBackground: "#000000",
    headerColor: "#FFFFFF",
    projectNameTextColor: "#000000",
  },
  estimate: {
    estimateBackground: "#ffffff",
    estimateTableBackground: "#E5E5EE",
    estimateTableHeadBackground: "#ffffff",
    estimateTableBodyBackground: "#ffffff",
    estimateTableRowBackground: "#E5E5EE",
    estimationTitleColor: "#000000",
    estimationHeaderColor: "#000000",
    estimationSprintColor: "#000000",
    estimationStoryColor: "#000000",
    estimationTaskColor: "#000000",
    estimationHourColor: "#123eee",
  },
  rate: {
    rateBackground: "#ffffff",
    rateCardBackground: "#EFEFF5",
    ratesTitleColor: "#000000",
    ratesDevNameColor: "#000000",
    ratesRankColor: "#000000",
    ratesDevRateColor: "#ffffff",
  },
  payments: {
    paymentsBg: "#ffffff",
    paymentsTableBg: "#eee5e5",
    paymentsTableBodyBg: "#ffffff",
    paymentsTableFooterBg: "#ffffff",
    paymentHeaderBackground: "#ffffff",
    paymentPriceWrapperBackground: "#000000",
    paymentsTitleColor: "#000000",
    paymentsDevAmountAndHoursColor: "#000000",
    paymentsTextColor: "#000000",
    paymentsRankColor: "#ffffff",
    paymentsTotalTimeAndAmountTextColor: "#ffffff",
    paymentsTotalTimeAndAmountBackground: "#000000",
    paymentTotalPriceTextColor: "#ffffff",
  },
  recommendation: {
    recommendationBg: "#ffffff",
    recommendationsTitleColor: "#000000",
    recommendationsTextColor: "#000000",
  },
  team: {
    teamBg: "#E5E5EE",
    teamTitleColor: "#000000",
    teamRankColor: "#000000",
    teamDevNameColor: "#000000",
  },
  comments: {
    commentsBg: "#ffffff",
    projectCommentsTitleColor: "#000000",
    projectCommentsTextColor: "#000000",
  },
  aboutUs: {
    aboutUsBg: "#E5E5EE",
    aboutUsTitleColor: "#000000",
    aboutUsTextColor: "#000000",
  },
};
