import { Components } from "@mui/material";

export const MuiFormHelperText: Partial<Components["MuiFormHelperText"]> = {
  styleOverrides: {
    root: {
      fontSize: "11px",
      fontWeight: 400,
      position: "absolute",
      top: "100%",
      marginInline: "8px",
    },
  },
};
