import PostAddIcon from "@mui/icons-material/PostAdd";
import SprintsTable from "components/SprintsTable";
import Button from "@mui/material/Button";
import { styles } from "screens/ProjectDetailsScreen/TasksTab/styles";
import { useModalContext } from "context/ModalContext";
import { TYPE_MODAL } from "constants/constants";
import Icon from "@mui/material/Icon";
import { FC } from "react";
import { TasksTabProps } from "./types";
import { useTranslation } from "react-i18next";

const TasksTab: FC<TasksTabProps> = ({
  isChart,
  sprints,
  developers,
  id,
  projectId,
  refetchProjectData,
  isChangeName,
}) => {
  const { t } = useTranslation();
  const { handleChangeModalParameters } = useModalContext();

  const openModalCreateSprint = () => {
    const posX = localStorage.getItem("posX");
    handleChangeModalParameters({
      type: TYPE_MODAL.CREATE_SPRINT,
      isOpen: true,
      params: {
        id,
        refetchProjectData,
        isChangeName,
        posX,
      },
    });
  };

  return (
    <>
      <Button
        variant="contained"
        sx={styles.buttonCreate}
        onClick={openModalCreateSprint}>
        <Icon sx={styles.buttonIcon}>
          <PostAddIcon />
        </Icon>
        {t("addSprint")}
      </Button>
      <SprintsTable
        sprints={sprints}
        developers={developers}
        isChart={isChart}
        projectId={projectId}
        refetchProjectData={refetchProjectData}
      />
    </>
  );
};

export default TasksTab;
