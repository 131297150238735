import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { projectSchemaCreate } from "validations/Projects";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  LanguageEnum,
  useCreateProjectMutation,
  useGetLanguagesQuery,
  useGetUsersQuery,
  UserRoleEnum,
} from "graphql/generated";
import { GET_PROJECTS } from "graphql/queries/getProjects.gql";
import { limitItemsCount } from "constants/index";
import { styles } from "components/ModalController/ModalCreateProject/styles";
import { useModalContext } from "context/ModalContext";
import Stack from "@mui/material/Stack";
import { LanguagePicker, SelectRHF } from "components/UI";
import { useUserAccess } from "hooks";
import { getFormatDataForSelectAndAutocomplete } from "utils";
import { useTranslation } from "react-i18next";

interface ModalCreateProjectProps {
  open: boolean;
}

interface FormData {
  name: string;
  isWithChart: boolean;
  language: LanguageEnum;
  userId?: string | number;
}

const ModalCreateProject: React.FC<ModalCreateProjectProps> = ({ open }) => {
  const { t } = useTranslation(["common", "projects"]);
  const { isAdmin } = useUserAccess();

  const methods = useForm<FormData>({
    resolver: yupResolver(projectSchemaCreate({ isAdmin, t })),
    defaultValues: {
      name: "",
      isWithChart: false,
      language: LanguageEnum.Uk,
      ...(isAdmin && { userId: "" }),
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [createProject] = useCreateProjectMutation();
  const { isEstimator } = useUserAccess();
  const { data } = useGetLanguagesQuery();

  const { data: usersData } = useGetUsersQuery({
    variables: {
      input: {
        offset: 0,
        limit: Infinity,
        role: UserRoleEnum.Manager,
      },
    },

    skip: isEstimator,
  });

  const usersRoleData = getFormatDataForSelectAndAutocomplete(
    usersData?.getUsers?.users
  );

  const { modalParameters } = useModalContext();
  const { pageNumber, navigate } = modalParameters.params as {
    pageNumber: number;
    navigate: (path: string) => void;
  };

  const handleClose = () => {
    modalParameters.cancelAction?.();
  };

  const onSubmit = async (data: FormData) => {
    await createProject({
      variables: {
        input: {
          name: data.name,
          isWithChart: data.isWithChart,
          language: data.language,
          ...(isAdmin && { userId: Number(data.userId) }),
        },
      },
      refetchQueries: () => [
        {
          query: GET_PROJECTS,
          variables: {
            offset: pageNumber * limitItemsCount,
            limit: limitItemsCount,
          },
        },
      ],
      onCompleted: ({ createProject }) => {
        const id = createProject.id;
        navigate(`/project/${id}`);
      },
    });
    handleClose();
    reset();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2">
          {t("createProject", { ns: "projects" })}
        </Typography>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={styles.form}>
            <Stack margin={"12px 0"} direction={"row"} gap={"20px"}>
              <TextField
                {...register("name")}
                sx={styles.input}
                label={t("newProjects", { ns: "projects" })}
                variant="outlined"
                helperText={errors?.name && errors?.name?.message}
                error={!!errors?.name}
                autoFocus
              />
              <LanguagePicker
                name={"language"}
                options={data?.getLanguages || []}
                inputLabel={t("projectLanguage", { ns: "projects" })}
              />
            </Stack>
            {isAdmin && (
              <Stack margin={"12px 0"}>
                <SelectRHF
                  name={"userId"}
                  options={usersRoleData}
                  label={t("selectManager", { ns: "projects" })}
                  extraText
                />
              </Stack>
            )}
            <Box sx={styles.checkbox}>
              <FormControlLabel
                label={t("needChart", { ns: "projects" })}
                control={<Checkbox {...register("isWithChart")} />}
              />
            </Box>
            <Box sx={styles.buttonBox}>
              <Button variant="contained" type="submit">
                {t("create")}
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default ModalCreateProject;
