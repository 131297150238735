import { MouseEvent, useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { HexColorPicker } from "react-colorful";
import { ButtonColorGroup } from "./ButtonColorGroup";
import { DEFAULT_DEVELOPER_COLOR } from "../../../constants";
import { GridCellParams } from "@mui/x-data-grid";
import { useEditAttachedDeveloperInProjectMutation } from "../../../graphql/generated";
import { GET_PROJECT } from "../../../graphql/queries/getProject.gql";
import { useParams } from "react-router-dom";

interface ColorPickerProps {
  params: GridCellParams;
}

export const ColorPicker = ({ params }: ColorPickerProps) => {
  const { id } = useParams();

  const [color, setColor] = useState(
    params.row?.color || DEFAULT_DEVELOPER_COLOR
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [editAttachedDeveloperInProjectMutation] =
    useEditAttachedDeveloperInProjectMutation({
      onCompleted: () => {
        setAnchorEl(null);
      },
    });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setColor(params.row?.color || DEFAULT_DEVELOPER_COLOR);
  };

  const handleConfirm = () => {
    editAttachedDeveloperInProjectMutation({
      variables: {
        projectId: Number(id),
        developerId: Number(params.id),
        input: {
          rank: params.row.defaultRank,
          rate: params.row.defaultRate,
          color: color,
        },
      },
      refetchQueries: () => [
        {
          query: GET_PROJECT,
          variables: {
            projectId: Number(id),
          },
        },
      ],
    });
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        sx={{
          background: color,
          "&:hover": {
            background: color,
          },
        }}
        variant={"colorPicker"}
        onClick={handleClick}>
        &nbsp;
      </Button>
      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Stack sx={{ padding: "20px" }}>
          <HexColorPicker color={color} onChange={setColor} />
          <ButtonColorGroup onClose={handleClose} onConfirm={handleConfirm} />
        </Stack>
      </Popover>
    </>
  );
};
