import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";

interface SectionAccordionProps extends AccordionSummaryProps {
  summary: ReactNode;
  details: ReactNode;
  defaultExpanded?: boolean;
}

export const SectionAccordion = ({
  summary,
  details,
  defaultExpanded,
  ...rest
}: SectionAccordionProps) => {
  return (
    <Accordion
      sx={{
        mb: 2,
        backgroundColor: "#f7f7f7",
        "&:first-of-type": { border: 0 },
        "&:before": { display: "none" },
        "&.Mui-expanded": { m: 0 },
      }}
      defaultExpanded={defaultExpanded}>
      <AccordionSummary
        sx={{
          ".MuiAccordionSummary-content": { m: 0 },
          ".MuiAccordionSummary-content.Mui-expanded": {
            m: 0,
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        {...rest}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};
