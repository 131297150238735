import React, { useState } from "react";
import {
  useGetPdfColorsQuery,
  useGetSettingsQuery,
} from "graphql/generated/index";
import Layout from "components/Layout/Layout";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GeneralSettingsTab } from "./GeneralSettingsTab/GeneralSettingsTab";
import { PaletteSettingsTab } from "./PaletteSettingsTab/PaletteSettingsTab";
import Stack from "@mui/material/Stack";

const SettingsScreen = () => {
  const { t } = useTranslation(["settings"]);
  const { data: initValue } = useGetSettingsQuery();
  const initSettings = initValue?.getSettings;

  const { data: pdfColorsData } = useGetPdfColorsQuery();

  const tabsData = [
    { id: 0, label: t("generalSetting") },
    { id: 1, label: t("palettePdf") },
  ];

  const [value, setValue] = useState(tabsData[0].id);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const MainContentElement = {
    0: GeneralSettingsTab,
    1: PaletteSettingsTab,
  }[value];

  return (
    <Layout namePage={t("settings")}>
      <Tabs value={value} onChange={handleChange}>
        {tabsData.map((tabData) => (
          <Tab label={tabData.label} key={tabData.label} />
        ))}
      </Tabs>
      <Stack flex={1}>
        {MainContentElement && (
          <MainContentElement
            initSettings={initSettings}
            pdfColorsData={pdfColorsData?.getPdfColors}
          />
        )}
      </Stack>
    </Layout>
  );
};

export default SettingsScreen;
