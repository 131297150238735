import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { styles } from "components/ModalController/ModalDelete/styles";
import { useModalContext } from "context/ModalContext";
import {
  useDeleteTaskMutation,
  useDeleteSprintMutation,
  useDeleteStoryMutation,
  GetProjectCustomDocument,
} from "graphql/generated";
import { projectFieldTypes } from "constants/constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ModalDeleteSprintStoryTaskProps {
  open: boolean;
}

const ModalDeleteSprintStoryTask: FC<ModalDeleteSprintStoryTaskProps> = ({
  open,
}) => {
  const { t } = useTranslation();
  const { modalParameters, handleClose } = useModalContext();

  const [deleteTaskMutation] = useDeleteTaskMutation({
    refetchQueries: [GetProjectCustomDocument],
  });

  const [deleteStoryMutation] = useDeleteStoryMutation({
    refetchQueries: [GetProjectCustomDocument],
  });

  const [deleteSprintMutation] = useDeleteSprintMutation({
    refetchQueries: [GetProjectCustomDocument],
  });

  const { taskType, taskId } = modalParameters.params as {
    taskType: string;
    taskId: number;
  };

  const deleteTask = async () => {
    await deleteTaskMutation({
      variables: {
        taskId: Number(taskId),
      },
    });
    gantt.deleteTask(`task-${taskId}`);
  };

  const deleteStory = async () => {
    await deleteStoryMutation({
      variables: {
        storyId: Number(taskId),
      },
    });
    gantt.deleteTask(`story-${taskId}`);
  };

  const deleteSprint = async () => {
    await deleteSprintMutation({
      variables: {
        sprintId: Number(taskId),
      },
    });
    gantt.deleteTask(`sprint-${taskId}`);
  };

  const handleDelete = async () => {
    switch (taskType) {
      case projectFieldTypes.project:
        await deleteSprint();
        break;
      case projectFieldTypes.story:
        await deleteStory();
        break;
      case projectFieldTypes.task:
        await deleteTask();
        break;
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2">
          {`${t("doYouWantToDeleteThe")} ${
            taskType === "project" ? "sprint" : taskType
          }?`}
        </Typography>
        <Box sx={styles.buttonBox}>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            endIcon={<DeleteIcon />}>
            {t("delete")}
          </Button>
          <Button onClick={handleClose} variant="outlined">
            {t("cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDeleteSprintStoryTask;
