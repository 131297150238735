import Stack from "@mui/material/Stack";
import { PdfHeader } from "./PdfHeader/PdfHeader";
import { PdfEstimate } from "./PdfEstimate/PdfEstimate";
import { PdfRate } from "./PdfRate/PdfRate";
import { PdfPayments } from "./PdfPayments/PdfPayments";
import { PdfRecommendation } from "./PdfRecommendation/PdfRecommendation";
import { PdfTeam } from "./PdfTeam/PdfTeam";
import { PdfComments } from "./PdfComments/PdfComments";
import { PdfAboutUs } from "./PdfAboutUs/PdfAboutUs";
import { INITIAL_COLOR_DEFAULT } from "../PaletteSettingsTab/constants";

type InitColorsMap = typeof INITIAL_COLOR_DEFAULT;

interface TemplatePdfProps {
  headerColors: InitColorsMap["header"];
  estimateColors: InitColorsMap["estimate"];
  rateColors: InitColorsMap["rate"];
  paymentsColors: InitColorsMap["payments"];
  recommendationColors: InitColorsMap["recommendation"];
  teamColors: InitColorsMap["team"];
  commentsColors: InitColorsMap["comments"];
  aboutUsColors: InitColorsMap["aboutUs"];
}

export const TemplatePdf = ({
  headerColors,
  estimateColors,
  rateColors,
  paymentsColors,
  recommendationColors,
  teamColors,
  commentsColors,
  aboutUsColors,
}: TemplatePdfProps) => {
  return (
    <Stack flex={1}>
      <PdfHeader
        background={headerColors.headerBackground}
        color={headerColors.headerColor}
      />
      <Stack
        sx={{
          flex: 1,
        }}>
        <PdfEstimate
          background={estimateColors.estimateBackground}
          tableBackground={estimateColors.estimateTableBackground}
          tableBodyBackground={estimateColors.estimateTableBodyBackground}
          tableHeadBackground={estimateColors.estimateTableHeadBackground}
          tableRowBackground={estimateColors.estimateTableRowBackground}
          projectNameTextColor={headerColors.projectNameTextColor}
          hourColor={estimateColors.estimationHourColor}
          titleColor={estimateColors.estimationTitleColor}
          headerColor={estimateColors.estimationHeaderColor}
          sprintColor={estimateColors.estimationSprintColor}
          taskColor={estimateColors.estimationTaskColor}
        />
        <PdfRate
          background={rateColors.rateBackground}
          cardBackground={rateColors.rateCardBackground}
          titleColor={rateColors.ratesTitleColor}
          devNameColor={rateColors.ratesDevNameColor}
          rankColor={rateColors.ratesRankColor}
          devRateColor={rateColors.ratesDevRateColor}
        />
        <PdfPayments
          background={paymentsColors.paymentsBg}
          tableBackground={paymentsColors.paymentsTableBg}
          tableBodyBackground={paymentsColors.paymentsTableBodyBg}
          tableFooterBackground={paymentsColors.paymentsTableFooterBg}
          titleColor={paymentsColors.paymentsTitleColor}
          devAmountAndHoursColor={paymentsColors.paymentsDevAmountAndHoursColor}
          textColor={paymentsColors.paymentsTextColor}
          rankColor={paymentsColors.paymentsRankColor}
          totalTimeAndAmountTextColor={
            paymentsColors.paymentsTotalTimeAndAmountTextColor
          }
          totalTimeAndAmountBackground={
            paymentsColors.paymentsTotalTimeAndAmountBackground
          }
          paymentHeaderBackground={paymentsColors.paymentHeaderBackground}
          paymentPriceWrapperBackground={
            paymentsColors.paymentPriceWrapperBackground
          }
          paymentTotalPriceTextColor={paymentsColors.paymentTotalPriceTextColor}
        />
        <PdfRecommendation
          background={recommendationColors.recommendationBg}
          titleColor={recommendationColors.recommendationsTitleColor}
          textColor={recommendationColors.recommendationsTextColor}
        />
        <PdfTeam
          background={teamColors.teamBg}
          titleColor={teamColors.teamTitleColor}
          rankColor={teamColors.teamRankColor}
          devNameColor={teamColors.teamDevNameColor}
        />

        <PdfComments
          background={commentsColors.commentsBg}
          titleColor={commentsColors.projectCommentsTitleColor}
          textColor={commentsColors.projectCommentsTextColor}
        />

        <PdfAboutUs
          background={aboutUsColors.aboutUsBg}
          titleColor={aboutUsColors.aboutUsTitleColor}
          textColor={aboutUsColors.aboutUsTextColor}
        />
      </Stack>
      {/*!*/}
      <PdfHeader
        background={headerColors.headerBackground}
        color={headerColors.headerColor}
      />
    </Stack>
  );
};
