import React from "react";
import {
  SprintEntity,
  StoryAndTaskType,
  StoryEntity,
  TaskEntity,
} from "graphql/generated";
import { Gantt } from "components/Gantt";
import { Developer, ChartData } from "screens/ProjectDetailsScreen/types";
import { taskEntity, projectFieldTypes } from "constants/constants";
import { Task } from "components/Gantt/types";
import { getStoryStartedDateValue } from "./utils";

interface StoryWithAlias extends StoryEntity {
  startedAtStory: StoryEntity["startedAt"];
}

interface SprintsTableProps {
  sprints: SprintEntity[];
  developers: Developer[];
  isChart?: boolean;
  projectId?: string | number;
  refetchProjectData?: () => void;
}

const SprintsTable: React.FC<SprintsTableProps> = ({
  sprints,
  developers,
  isChart,
  projectId,
  refetchProjectData,
}) => {
  const sprintsOrder: number[] = [];

  const getData = () => {
    const data: ChartData[] = [];

    sprints.forEach(
      ({ id, name, storiesAndTasks, totalHours }: SprintEntity) => {
        sprintsOrder.push(+id);

        const newSprint = {
          ID: id,
          sprintId: id,
          name,
          text: name,
          totalHours,
          id: "sprint-" + id,
          type: projectFieldTypes.project,
          open: true,
        };
        data.push(newSprint);

        storiesAndTasks.forEach((story: StoryAndTaskType) => {
          const { tasks }: any = story;
          const newStory = {
            ID: story.id,
            sprintId: id,
            storyId: story.id,
            name: story.name,
            text: story.name,
            parent: "sprint-" + id,
            open: true,
          };

          data.push(
            story.__typename === taskEntity
              ? {
                  ...newStory,
                  id: "task-" + story.id,
                  developerId: story.developerId,
                  developerName: `${story?.developer?.firstName} ${story?.developer?.lastName}`,
                  developerAvatar: story?.developer?.avatar,
                  startedAt: story.startedAt,
                  duration: story?.duration,
                  type: projectFieldTypes.task,
                  start_date: new Date(Number(story.startedAt)),
                }
              : {
                  ...newStory,
                  id: "story-" + story.id,
                  totalHours: (story as StoryEntity).totalHours,
                  type: "story",
                  start_date: getStoryStartedDateValue(story as StoryWithAlias),
                  startedAt: new Date(
                    getStoryStartedDateValue(story as StoryWithAlias)
                  )
                    .getTime()
                    .toString(),
                }
          );

          if (tasks) {
            tasks.forEach((task: TaskEntity) => {
              const startedTime = new Date(Number(task.startedAt));
              const newTask = {
                ID: task.id,
                taskId: task.id,
                name: task.name,
                text: task.name,
                id: "task-" + task.id,
                parent: "story-" + story.id,
                developerId: task.developerId,
                startedAt: task.startedAt,
                duration: task.duration,
                start_date: startedTime,
                type: projectFieldTypes.task,
                developerName: `${task.developer.firstName} ${task.developer.lastName}`,
                developerAvatar: task.developer.avatar,
              };
              data.push(newTask);
            });
          }
        });
      }
    );

    return {
      tasks: [...data] as unknown as Task[],
    };
  };

  return (
    <Gantt
      inputData={getData()}
      developers={developers}
      isChart={isChart as boolean}
      projectId={projectId as string | number}
      sprintsOrder={sprintsOrder}
      sprints={sprints}
      refetchProjectData={refetchProjectData}
    />
  );
};

export default SprintsTable;
