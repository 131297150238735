import * as yup from "yup";
import { TFunction } from "i18next";

export const developerSchemaCreate = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    firstName: yup.string().required(
      t("requiredField", {
        fieldName: t("firstName"),
        interpolation: { escapeValue: false },
      })
    ),
    lastName: yup.string().required(
      t("requiredField", {
        fieldName: t("lastName"),
      })
    ),
    defaultRank: yup.string().required(
      t("requiredField", {
        fieldName: t("rank"),
      })
    ),
    status: yup.string(),
    defaultRate: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .test(
        "defaultRate",
        t("positive", {
          fieldName: t("rate"),
        }),
        (value) => (value ?? 0) > 0
      )
      .required(
        t("requiredField", {
          fieldName: t("rate"),
        })
      ),
  });
