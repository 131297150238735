import styles from "./colors.module.css";

export const drawerWidth = 240;

export const projectName = "EstiMale";

export const limitItemsCount = 10;

export const itemsOffset = 5;

export const TYPE_MODAL = {
  CREATE_PROJECT: "create_project",
  DELETE: "delete",
  CREATE_USER: "create_user",
  EDIT_USER: "edit_user",
  CREATE_DEVELOPER: "create_developer",
  EDIT_DEVELOPER: "edit_developer",
  CREATE_SPRINT: "create_sprint",
  CREATE_STORY_TASK: "create_story_task",
  DELETE_SPRINT_STORY_TASK: "delete_sprint_story_task",
};

export const projectFieldTypes = {
  task: "task",
  story: "story",
  project: "project",
};

export const taskEntity = "TaskEntity";

export const alertTypes = {
  success: "success",
  error: "error",
};

export const dayFormat = "%j %D";

export const avatarColorSchema = [
  styles.light,
  styles.dark,
  styles.yellow,
  styles.green,
  styles.blue,
  styles.orange,
  styles.purple,
  styles.red,
  styles.brown,
  styles.national,
];

export const USER_STATUS = [
  { id: "1", value: "ACTIVE", label: "ACTIVE" },
  { id: "2", value: "INACTIVE", label: "INACTIVE" },
  { id: "3", value: "BANNED", label: "BANNED" },
];

export const PROJECT_STATUS = [
  { id: "1", value: "ACTIVE", label: "ACTIVE" },
  { id: "2", value: "ARCHIVED", label: "ARCHIVED" },
];

export const USER_ROLE = [
  { id: "1", value: "MANAGER", label: "MANAGER" },
  { id: "2", value: "ADMIN", label: "ADMIN" },
  { id: "3", value: "ESTIMATOR", label: "ESTIMATOR" },
];

export const LANGUAGE_OPTION = [
  { value: "ua", label: "Український" },
  { value: "en", label: "English" },
  { value: "ru", label: "русский" },
];

export const DEFAULT_DEVELOPER_COLOR = "#000000";

export const NAME_COLOR_BTNS = {
  HEADER: {
    headerBackground: "bg",
    headerColor: "Color",
    projectNameTextColor: "Title",
  },
  ESTIMATE: {
    estimateBackground: "bg",
    estimateTableBackground: "Table bg",
    estimateTableHeadBackground: "Table head bg",
    estimateTableBodyBackground: "Table body bg",
    estimateTableRowBackground: "Table row bg",
    estimationHourColor: "Hour color",
    estimationTitleColor: "Title color",
    estimationHeaderColor: "Header color",
    estimationSprintColor: "Sprint color",
    estimationStoryColor: "Story color",
    estimationTaskColor: "Task color",
  },
  RATE: {
    rateBackground: "bg",
    rateCardBackground: "Card bg",
    ratesTitleColor: "Title color",
    ratesDevNameColor: "DevName color",
    ratesRankColor: "Rank color",
    ratesDevRateColor: "DevRate color",
  },
  PAYMENTS: {
    paymentsBg: "bg",
    paymentsTableBg: "Table bg",
    paymentsTableBodyBg: "Table body bg",
    paymentsTableFooterBg: "Table footer bg",
    paymentsTitleColor: "Title color",
    paymentsDevAmountAndHoursColor: "DevAmountAndHours color",
    paymentsTextColor: "Text color",
    paymentsRankColor: "Rank color",
    paymentsTotalTimeAndAmountTextColor: "TotalTimeAndAmountText color",
    paymentsTotalTimeAndAmountBackground: "TotalTimeAndAmount background",
    paymentHeaderBackground: "HeaderBackground",
    paymentPriceWrapperBackground: "PriceWrapper background",
    paymentTotalPriceTextColor: "TotalPriceText color",
  },
  RECOMMENDATION: {
    recommendationBg: "bg",
    recommendationsTitleColor: "Title color",
    recommendationsTextColor: "Text color",
  },
  TEAM: {
    teamBg: "bg",
    teamTitleColor: "Title color",
    teamRankColor: "Rank color",
    teamDevNameColor: "DevName color",
  },
  COMMENTS: {
    commentsBg: "bg",
    projectCommentsTitleColor: "Title color",
    projectCommentsTextColor: "Text color",
  },
  ABOUT_US: {
    aboutUsBg: "bg",
    aboutUsTitleColor: "Color",
    aboutUsTextColor: "Text color",
  },
};
