import { avatarColorSchema, projectFieldTypes } from "constants/constants";
import { Task } from "../types";
import { getInitials } from "utils";
import styles from "../index.module.css";
import { TFunction } from "i18next";

const createDevelopersTemplate = (task: Task) => {
  if (task.type === projectFieldTypes.task) {
    let colorIndex: string | number = 0;
    const devId = task.developerId.toString();

    colorIndex = devId[devId.length - 1] || 0;

    const avatarClassname = avatarColorSchema[Number(colorIndex)];

    let avatar = `<span 
                    class="${styles.avatar} ${avatarClassname}">
                        ${getInitials(task.developerName)}
                  </span>`;

    if (task.developerAvatar) {
      avatar = `<img class="${styles.avatar}" src="${process.env.REACT_APP_URI}/uploads/avatars/${task.developerAvatar}" alt="${task.developerName}"/>`;
    }

    // ${task.developerName} - has been deleted
    return `<div class="${styles.avatarWrapper}">${avatar}</div>`;
  }

  return "";
};

type CreateDevelopersColumnArguments = {
  isChart: boolean;
  t: TFunction;
};
export const createDevelopersColumn = ({
  isChart,
  t,
}: CreateDevelopersColumnArguments) => ({
  name: "priority",
  label: t("dev"),
  width: isChart ? 50 : 300,
  align: "center",
  template: createDevelopersTemplate,
});
