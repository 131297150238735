import { PdfColorsEntity } from "graphql/generated";
import { INITIAL_COLOR_DEFAULT } from "./constants";

export const getInitValuesAfterRequest = (pdfColorsData?: PdfColorsEntity) => {
  return {
    header: {
      headerBackground: pdfColorsData?.headerBackground || "#000000",
      headerColor: pdfColorsData?.headerColor || "#FFFFFF",
      projectNameTextColor: pdfColorsData?.projectNameTextColor || "#000000",
    },
    estimate: {
      estimateBackground: pdfColorsData?.estimateBackground || "#ffffff",
      estimateTableBackground:
        pdfColorsData?.estimateTableBackground || "#E5E5EE",
      estimateTableHeadBackground:
        pdfColorsData?.estimateTableHeadBackground || "#ffffff",
      estimateTableBodyBackground:
        pdfColorsData?.estimateTableBodyBackground || "#ffffff",
      estimateTableRowBackground:
        pdfColorsData?.estimateTableRowBackground || "#E5E5EE",
      estimationHourColor: pdfColorsData?.estimationHourColor || "#123eee",
      estimationTitleColor: pdfColorsData?.estimationTitleColor || "#000000",
      estimationHeaderColor: pdfColorsData?.estimationHeaderColor || "#000000",
      estimationSprintColor: pdfColorsData?.estimationSprintColor || "#000000",
      estimationStoryColor: pdfColorsData?.estimationStoryColor || "#000000",
      estimationTaskColor: pdfColorsData?.estimationTaskColor || "#000000",
    },
    rate: {
      rateBackground: pdfColorsData?.rateBackground || "#ffffff",
      rateCardBackground: pdfColorsData?.rateCardBackground || "#EFEFF5",
      ratesTitleColor: pdfColorsData?.ratesTitleColor || "#000000",
      ratesDevNameColor: pdfColorsData?.ratesDevNameColor || "#000000",
      ratesRankColor: pdfColorsData?.ratesRankColor || "#000000",
      ratesDevRateColor: pdfColorsData?.ratesDevRateColor || "#ffffff",
    },
    payments: {
      paymentsBg: pdfColorsData?.paymentsBackground || "#ffffff",
      paymentsTableBg: pdfColorsData?.paymentsTableBackground || "#eee5e5",
      paymentsTableBodyBg:
        pdfColorsData?.paymentsTableBodyBackground || "#ffffff",
      paymentsTableFooterBg:
        pdfColorsData?.paymentsTableFooterBackground || "#ffffff",
      paymentsTitleColor: pdfColorsData?.paymentsTitleColor || "#000000",
      paymentsDevAmountAndHoursColor:
        pdfColorsData?.paymentsDevAmountAndHoursColor || "#000000",
      paymentsTextColor: pdfColorsData?.paymentsTextColor || "#000000",
      paymentsRankColor: pdfColorsData?.paymentsRankColor || "#ffffff",
      paymentsTotalTimeAndAmountTextColor:
        pdfColorsData?.paymentsTotalTimeAndAmountTextColor || "#ffffff",
      paymentsTotalTimeAndAmountBackground:
        pdfColorsData?.paymentsTotalTimeAndAmountBackground || "#000000",
      paymentHeaderBackground:
        pdfColorsData?.paymentHeaderBackground || "#ffffff",
      paymentPriceWrapperBackground:
        pdfColorsData?.paymentPriceWrapperBackground || "#000000",
      paymentTotalPriceTextColor:
        pdfColorsData?.paymentTotalPriceTextColor || "#ffffff",
    },
    recommendation: {
      recommendationBg: pdfColorsData?.recommendationBackground || "#ffffff",
      recommendationsTitleColor:
        pdfColorsData?.recommendationsTitleColor || "#000000",
      recommendationsTextColor:
        pdfColorsData?.recommendationsTextColor || "#000000",
    },
    team: {
      teamBg: pdfColorsData?.teamBackground || "#E5E5EE",
      teamTitleColor: pdfColorsData?.teamTitleColor || "#000000",
      teamRankColor: pdfColorsData?.teamRankColor || "#000000",
      teamDevNameColor: pdfColorsData?.teamDevNameColor || "#000000",
    },
    comments: {
      commentsBg: pdfColorsData?.commentsBackground || "#ffffff",
      projectCommentsTitleColor:
        pdfColorsData?.projectCommentsTitleColor || "#000000",
      projectCommentsTextColor:
        pdfColorsData?.projectCommentsTextColor || "#000000",
    },
    aboutUs: {
      aboutUsBg: pdfColorsData?.aboutUsBackground || "#E5E5EE",
      aboutUsTitleColor: pdfColorsData?.aboutUsTitleColor || "#000000",
      aboutUsTextColor: pdfColorsData?.aboutUsTextColor || "#000000",
    },
  };
};

export const getPDFColorsInput = (
  colorsStack: typeof INITIAL_COLOR_DEFAULT
) => {
  return {
    headerBackground: colorsStack.header.headerBackground,
    headerColor: colorsStack.header.headerColor,
    projectNameTextColor: colorsStack.header.projectNameTextColor,

    estimateBackground: colorsStack.estimate.estimateBackground,
    estimateTableBackground: colorsStack.estimate.estimateTableBackground,
    estimateTableHeadBackground:
      colorsStack.estimate.estimateTableHeadBackground,
    estimateTableBodyBackground:
      colorsStack.estimate.estimateTableBodyBackground,
    estimateTableRowBackground: colorsStack.estimate.estimateTableRowBackground,
    estimationHourColor: colorsStack.estimate.estimationHourColor,
    estimationTitleColor: colorsStack.estimate.estimationTitleColor,
    estimationHeaderColor: colorsStack.estimate.estimationHeaderColor,
    estimationSprintColor: colorsStack.estimate.estimationSprintColor,
    estimationStoryColor: colorsStack.estimate.estimationTaskColor,
    estimationTaskColor: colorsStack.estimate.estimationTaskColor,

    rateBackground: colorsStack.rate.rateBackground,
    rateCardBackground: colorsStack.rate.rateCardBackground,
    ratesTitleColor: colorsStack.rate.ratesTitleColor,
    ratesDevNameColor: colorsStack.rate.ratesDevNameColor,
    ratesRankColor: colorsStack.rate.ratesRankColor,
    ratesDevRateColor: colorsStack.rate.ratesDevRateColor,

    paymentsBackground: colorsStack.payments.paymentsBg,
    paymentsTableBackground: colorsStack.payments.paymentsTableBg,
    paymentsTableBodyBackground: colorsStack.payments.paymentsTableBodyBg,
    paymentsTableFooterBackground: colorsStack.payments.paymentsTableFooterBg,
    paymentsTitleColor: colorsStack.payments.paymentsTitleColor,
    paymentsDevAmountAndHoursColor:
      colorsStack.payments.paymentsDevAmountAndHoursColor,
    paymentsTextColor: colorsStack.payments.paymentsTextColor,
    paymentsRankColor: colorsStack.payments.paymentsRankColor,
    paymentsTotalTimeAndAmountTextColor:
      colorsStack.payments.paymentsTotalTimeAndAmountTextColor,
    paymentsTotalTimeAndAmountBackground:
      colorsStack.payments.paymentsTotalTimeAndAmountBackground,
    paymentHeaderBackground: colorsStack.payments.paymentHeaderBackground,
    paymentPriceWrapperBackground:
      colorsStack.payments.paymentPriceWrapperBackground,
    paymentTotalPriceTextColor: colorsStack.payments.paymentTotalPriceTextColor,

    recommendationBackground: colorsStack.recommendation.recommendationBg,
    recommendationsTitleColor:
      colorsStack.recommendation.recommendationsTitleColor,
    recommendationsTextColor:
      colorsStack.recommendation.recommendationsTextColor,

    teamBackground: colorsStack.team.teamBg,
    teamTitleColor: colorsStack.team.teamTitleColor,
    teamRankColor: colorsStack.team.teamRankColor,
    teamDevNameColor: colorsStack.team.teamDevNameColor,

    commentsBackground: colorsStack.comments.commentsBg,
    projectCommentsTitleColor: colorsStack.comments.projectCommentsTitleColor,
    projectCommentsTextColor: colorsStack.comments.projectCommentsTextColor,

    aboutUsBackground: colorsStack.aboutUs.aboutUsBg,
    aboutUsTitleColor: colorsStack.aboutUs.aboutUsTitleColor,
    aboutUsTextColor: colorsStack.aboutUs.aboutUsTextColor,
  };
};
