import { Components } from "@mui/material";
import { MuiFormHelperText } from "./FormHelperText";

export const components: Components = {
  MuiSwitch: {
    styleOverrides: {
      track: {
        backgroundColor: "#0f59ff",
      },
      thumb: {
        backgroundColor: "#0f59ff",
      },
    },
  },
  MuiChip: {
    variants: [
      {
        props: {
          variant: "active",
        },
        style: {
          backgroundColor: "rgba(99,178,68,0.5)",
        },
      },
      {
        props: {
          variant: "inactive",
        },
        style: {
          backgroundColor: "rgba(197,171,16,0.49)",
        },
      },
      {
        props: {
          variant: "banned",
        },
        style: {
          backgroundColor: "rgba(182,0,0,0.3)",
        },
      },
    ],
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "colorPicker" },
        style: () => ({
          width: "100%",
          border: "none",
          "&:hover": {
            opacity: "0.9",
          },
        }),
      },
      {
        props: { variant: "smColorPicker" },
        style: () => ({
          width: "30px",
          minWidth: "30px",
          height: "30px",
          border: "1px solid #123eee",
          padding: 0,
          background: "#123eee",
          "&:hover": {
            opacity: "0.9",
          },
        }),
      },
    ],
  },
  MuiFormHelperText: MuiFormHelperText,
};

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    active: true;
    inactive: true;
    banned: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    colorPicker: true;
    smColorPicker: true;
  }
}
