import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styles } from "screens/AuthScreens/styles";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { userSchemaPasswordResetConfirm } from "validations/AuthValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useConfirmPasswordResetMutation } from "graphql/generated";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "constants/index";

interface FormData {
  password: string;
  confirmPassword: string;
}

const PasswordResetConfirmScreen: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = queryString.parse(location.search);

  const { handleChangeAlertParameters } = useAlertContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(userSchemaPasswordResetConfirm),
  });

  const [confirmPasswordReset] = useConfirmPasswordResetMutation();

  const handleErrors = () => {
    handleChangeAlertParameters({
      isOpen: true,
      message: "Something went wrong. Try it later.",
      type: alertTypes.error,
    });
  };

  const onSubmit = async (data: FormData) => {
    const res = await confirmPasswordReset({
      variables: {
        token: token as string,
        password: data.password,
      },
      onError: handleErrors,
    });
    if (res.data && res.data.confirmPasswordReset.status === 200) {
      navigate("/");
      handleChangeAlertParameters({
        isOpen: true,
        message: "Your password has been successfully changed.",
        type: alertTypes.success,
      });
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Typography sx={styles.title} align="center" variant="h4">
        Input Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.inputContainer}>
          <TextField
            {...register("password")}
            InputProps={{
              endAdornment: showPassword ? (
                <VisibilityIcon
                  onClick={handleShowPassword}
                  color={errors?.password ? "error" : "inherit"}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={handleShowPassword}
                  color={errors?.password ? "error" : "inherit"}
                />
              ),
            }}
            type={showPassword ? "text" : "password"}
            sx={styles.input}
            label="Password"
            variant="outlined"
            helperText={errors?.password && errors?.password?.message}
            error={!!errors?.password}
          />

          <TextField
            {...register("confirmPassword")}
            InputProps={{
              endAdornment: showConfirmPassword ? (
                <VisibilityIcon
                  onClick={handleShowConfirmPassword}
                  color={errors?.confirmPassword ? "error" : "inherit"}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={handleShowConfirmPassword}
                  color={errors?.confirmPassword ? "error" : "inherit"}
                />
              ),
            }}
            type={showConfirmPassword ? "text" : "password"}
            sx={styles.input}
            label="Confirm Password"
            variant="outlined"
            helperText={errors?.confirmPassword && "Passwords Should Match!"}
            error={!!errors?.confirmPassword}
          />
        </Box>
        <Button sx={styles.button} variant="contained" type="submit">
          <Typography variant="button">confirm password</Typography>
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordResetConfirmScreen;
