import React from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface CustomSwitchProps {
  isSwitch: boolean;
  setIsSwitch: (checked: boolean) => void;
  disabled?: boolean;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  setIsSwitch,
  isSwitch,
  disabled,
}) => {
  const { t } = useTranslation();
  const handleChange = () => {
    setIsSwitch(!isSwitch);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="h6" component="h2">
        {t("story")}
      </Typography>
      <Switch
        checked={isSwitch}
        disabled={disabled}
        onChange={handleChange}
        inputProps={{ "aria-label": "ant design" }}
      />
      <Typography variant="h6" component="h2">
        {t("task")}
      </Typography>
    </Stack>
  );
};

export default CustomSwitch;
