import { styles } from "../styles";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { EditorController } from "../../../components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingsSchema } from "../../../validations/SettingsValidations/settingsSchema";
import {
  SettingsEntity,
  useChangeSettingsMutation,
} from "../../../graphql/generated";
import { useAlertContext } from "../../../context/AlertContext";
import DropzoneRHF from "../../../components/DropzoneRHF";
import Stack from "@mui/material/Stack";
import { postUploadLogoThunk } from "../../../thunk/postUploadLogoThunk";
import * as process from "process";

interface GeneralSettingsTabProps {
  initSettings?: SettingsEntity | null;
}

interface FormData {
  mail: string;
  phone: string;
  defaultRecommendations: string;
  defaultComments: string;
  defaultAboutUs: string;

  sloganUa: string;
  sloganEn: string;
  sloganRu: string;
  linkCompany: string;

  logoCompany: File | null | string;
}

export const GeneralSettingsTab = ({
  initSettings,
}: GeneralSettingsTabProps) => {
  const { t } = useTranslation(["settings", "common"]);
  const [changeSettingsMutation] = useChangeSettingsMutation({});
  const { handleChangeAlertParameters } = useAlertContext();

  const handleError = () => {
    handleChangeAlertParameters({
      isOpen: true,
      message: t("somethingWentWrong"),
      type: "error",
    });
  };

  const onSubmit = async (data: FormData) => {
    const uploadFile = data.logoCompany;

    let responseLogoCompany = initSettings?.logo || "";

    if (uploadFile && uploadFile !== responseLogoCompany) {
      const formData = new FormData();
      formData.append("logo", uploadFile || "");
      const response: Response = await postUploadLogoThunk({ formData });
      if (!response.ok) {
        return handleError();
      }
      responseLogoCompany = await response.text();
    }

    const res = await changeSettingsMutation({
      variables: {
        input: {
          mail: data.mail,
          phone: data.phone,
          defaultRecommendations: data.defaultRecommendations,
          defaultComments: data.defaultComments,
          defaultAboutUs: data.defaultAboutUs,
          link: data.linkCompany,
          logo: responseLogoCompany,
          slogan: {
            uk: data.sloganUa,
            en: data.sloganEn,
            ru: data.sloganRu,
          },
        },
      },
      onError: handleError,
    });

    if (res.data) {
      handleChangeAlertParameters({
        isOpen: true,
        message: t("settingsWasSaved"),
        type: "success",
      });
    }
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(settingsSchema({ t })),
  });

  useEffect(() => {
    if (initSettings) {
      reset({
        mail: initSettings?.mail,
        phone: initSettings?.phone,
        defaultRecommendations: initSettings?.defaultRecommendations,
        defaultComments: initSettings?.defaultComments,
        defaultAboutUs: initSettings?.defaultAboutUs,

        logoCompany: initSettings?.logo || null,
        sloganUa: initSettings.slogan?.uk || "",
        sloganEn: initSettings.slogan?.en || "",
        sloganRu: initSettings.slogan?.ru || "",
        linkCompany: initSettings?.link || "",
      });
    }
  }, [initSettings]);

  const recomendationsError =
    errors?.defaultRecommendations && errors?.defaultRecommendations?.message;
  const commentsError =
    errors?.defaultComments && errors?.defaultComments?.message;
  const aboutUsError =
    errors?.defaultAboutUs && errors?.defaultAboutUs?.message;

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Typography sx={styles.title} align="center" variant="h4">
        {t("settings")}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.inputContainer}>
          <TextField
            {...register("mail")}
            sx={styles.input}
            label={t("emailAddress")}
            variant="outlined"
            helperText={errors?.mail && errors?.mail?.message}
            error={!!errors?.mail}
            InputLabelProps={{ shrink: true }}
            autoFocus
          />
          <TextField
            {...register("phone")}
            sx={styles.input}
            label={t("phone")}
            variant="outlined"
            helperText={errors?.phone && errors?.phone?.message}
            error={!!errors?.phone}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box sx={styles.inputContainer} my={3}>
          <Stack position={"relative"}>
            <Typography sx={styles.captionCustom}>
              {t("logoCompany")}
            </Typography>
            <Controller
              name="logoCompany"
              control={control}
              render={({ field: { onChange } }) => (
                <DropzoneRHF
                  name="logoCompany"
                  setRHFvalue={setValue}
                  onChange={onChange}
                  nameFieldToNullable={"logoCompany"}
                />
              )}
            />
            {initSettings?.logo && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}>
                <img
                  src={`${process.env.REACT_APP_URI}/uploads/logo/${initSettings.logo}`}
                  height={60}
                  alt={"logo"}
                />
              </Box>
            )}
          </Stack>
          <TextField
            {...register("sloganEn")}
            sx={styles.input}
            label={`${t("slogan")} EN`}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            {...register("sloganUa")}
            sx={styles.input}
            label={`${t("slogan")} UA`}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            {...register("sloganRu")}
            sx={styles.input}
            label={`${t("slogan")} ru`}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            {...register("linkCompany")}
            sx={styles.input}
            label={t("linkCompany")}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box sx={styles.editorWrapper(recomendationsError)}>
          <EditorController
            name="defaultRecommendations"
            control={control}
            label={t("recommendations")}
          />
          {!!recomendationsError && (
            <FormHelperText sx={styles.editorError}>
              {recomendationsError}
            </FormHelperText>
          )}
        </Box>
        <Box
          sx={styles.editorWrapper(
            errors?.defaultComments && errors?.defaultComments?.message
          )}>
          <EditorController
            name="defaultComments"
            control={control}
            label={t("comments")}
          />
          {!!commentsError && (
            <FormHelperText sx={styles.editorError}>
              {commentsError}
            </FormHelperText>
          )}
        </Box>
        <Box
          sx={styles.editorWrapper(
            errors?.defaultAboutUs && errors?.defaultAboutUs?.message
          )}>
          <EditorController
            name="defaultAboutUs"
            control={control}
            label={t("aboutUs")}
          />
          {!!aboutUsError && (
            <FormHelperText sx={styles.editorError}>
              {aboutUsError}
            </FormHelperText>
          )}
        </Box>

        <Button sx={styles.button} variant="contained" type="submit">
          <Typography variant="button">{t("submit")}</Typography>
        </Button>
      </Box>
    </Container>
  );
};
