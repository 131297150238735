import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useGetUsersLazyQuery, UserEntity } from "graphql/generated";
import { limitItemsCount, TYPE_MODAL } from "constants/index";
import styles from "./styles";
import { useModalContext } from "../../context/ModalContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getColorStatus } from "../../utils";
import { useTranslation } from "react-i18next";
interface UsersTableProps {
  rows: Partial<UserEntity>[];
  count: number;
  page: number;
  setPageNumberState: (page: number) => void;
}

const mutateRows = (rows: Partial<UserEntity>[]) => {
  return rows.map((row: Partial<UserEntity>) => {
    const createdTime = new Date(Number(row.createdAt));
    return {
      ...row,
      createdAt: format(createdTime, "dd-MM-yyyy 'at' h:mm a "),
    };
  });
};

const UsersTable = ({
  rows,
  count,
  page,
  setPageNumberState,
}: UsersTableProps) => {
  const { handleChangeModalParameters } = useModalContext();
  const { t, i18n } = useTranslation();

  const handleCloseEditModal = () => {
    handleChangeModalParameters({
      isOpen: false,
      type: TYPE_MODAL.EDIT_USER,
    });
  };

  const handleOpenEditModal = (row: Partial<UserEntity>) => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.EDIT_USER,
      cancelAction: handleCloseEditModal,
      params: {
        row: row,
      },
    });
  };

  const columns = useMemo(
    () =>
      [
        {
          field: "id",
          headerName: t("id"),
          width: 50,
          minWidth: 30,
          sortable: false,
        },
        {
          field: "name",
          headerName: t("name"),
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer", width: "100%" }}
                onClick={() => handleOpenEditModal(params.row)}>
                {params.value}
              </Box>
            );
          },
        },
        {
          field: "email",
          headerName: t("email"),
          width: 200,
          minWidth: 100,
          sortable: false,
        },
        {
          field: "numberOfCreatedProjects",
          headerName: t("numberProjects"),
          width: 150,
          minWidth: 100,
          sortable: false,
        },
        {
          field: "createdAt",
          headerName: t("createdAt"),
          width: 200,
          minWidth: 100,
          sortable: false,
        },
        {
          field: "status",
          headerName: t("status"),
          width: 150,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography component="span" color={getColorStatus(params.value)}>
                {params.value}
              </Typography>
            );
          },
        },
        {
          field: "role",
          headerName: t("role"),
          width: 130,
          minWidth: 100,
          sortable: false,
        },
      ] as GridColumns<Partial<UserEntity>>,
    [i18n.language]
  );

  const [rowState, setRowState] = useState<Array<Partial<UserEntity>>>(rows);
  const [rowCountState, setRowCountState] = useState(count);

  const [getUsers, { loading }] = useGetUsersLazyQuery();

  useEffect(() => {
    setRowCountState((prevRowCountState) => count ?? prevRowCountState);
  }, [count, setRowCountState]);

  useEffect(() => {
    setRowState(() => mutateRows(rows));
  }, [rows]);

  const handlePageChange = async (pageNumber: number) => {
    setPageNumberState(pageNumber);

    const res = await getUsers({
      variables: {
        input: {
          offset: pageNumber * limitItemsCount,
          limit: limitItemsCount,
        },
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    });

    const newRows = res.data && res.data.getUsers.users;
    if (newRows) setRowState(mutateRows(newRows));
  };

  return (
    <DataGrid
      page={page}
      rows={rowState}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={rowCountState}
      paginationMode="server"
      onPageChange={handlePageChange}
      loading={loading}
      autoHeight
      autoPageSize
      pagination
      disableSelectionOnClick
      disableColumnMenu
      sx={styles}
    />
  );
};

export default UsersTable;
