import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Logo from "../../../../images/logo.png";

interface PdfHeaderProps {
  background: string;
  color: string;
}

export const PdfHeader = ({ background, color }: PdfHeaderProps) => {
  return (
    <Stack
      className="header-footer"
      sx={{
        background: background,
        color: color,
        height: "100px",
        justifyContent: "space-between",
        padding: "10px 20px",
        alignItems: "center",
        flexDirection: "row",
      }}>
      <Typography>
        <img src={Logo} alt="i" height={60} />
      </Typography>
      <Typography fontSize={18}>https://example.com</Typography>
      <Typography fontSize={18}>info@example.com</Typography>
      <Typography fontSize={18}>+38(050)-000-00-00</Typography>
      <Typography fontSize={18}>Lorem ipsum dolor sit amet</Typography>
    </Stack>
  );
};
