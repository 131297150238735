import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface PdfEstimateProps {
  background: string;
  tableBackground: string;
  tableHeadBackground: string;
  tableBodyBackground: string;
  tableRowBackground: string;
  projectNameTextColor: string;
  hourColor: string;
  titleColor: string;
  headerColor: string;
  sprintColor: string;
  taskColor: string;
}

export const PdfEstimate = ({
  background,
  tableBackground,

  tableHeadBackground,
  tableBodyBackground,
  tableRowBackground,
  hourColor,
  projectNameTextColor,
  titleColor,
  headerColor,
  sprintColor,
  taskColor,
}: PdfEstimateProps) => {
  return (
    <Stack
      sx={{
        background: background,
        padding: "50px 100px",
      }}
      className="estimate-block">
      <Stack mb={3}>
        <Typography
          variant={"caption"}
          fontSize={36}
          color={projectNameTextColor}>
          Title
        </Typography>
      </Stack>
      <Typography variant={"caption"} color={titleColor}>
        Estimation
      </Typography>
      <Stack sx={{ padding: "10px", background: tableBackground }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            background: tableHeadBackground,
            padding: "5px",
            marginBottom: "10px",
          }}>
          <Typography textAlign={"start"} color={headerColor}>
            Sprint
          </Typography>
          <Typography textAlign={"center"} color={headerColor}>
            Employee
          </Typography>
          <Typography textAlign={"end"} color={headerColor}>
            Hours
          </Typography>
        </Box>
        <Stack
          sx={{
            background: tableBodyBackground,
            gap: "10px",
            padding: "10px",
          }}>
          <Typography color={sprintColor}>Sprint title</Typography>
          {Array.from({ length: 10 }, (v, k) => k).map((_, i) => {
            return (
              <Stack
                key={i}
                sx={{
                  flexDirection: "row",
                  background: tableRowBackground,
                  padding: "5px",
                  justifyContent: "space-between",
                }}>
                <Typography color={taskColor}>Some task</Typography>
                <Typography color={taskColor} ml={"-90px"}>
                  John Doe
                </Typography>
                <Typography color={hourColor}>3 hours</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
