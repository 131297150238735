import { Outlet } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "graphql/client/client";
import { ThemeProvider } from "@mui/material";
import { theme } from "theme/theme";
import { ModalContextProvider } from "context/ModalContext";
import { AlertContextProvider } from "context/AlertContext";
import ModalLayout from "components/ModalController/ModalLayout";
import AlertLayout from "components/Alert/AlertLayout";
import { DrawerContextProvider } from "context/DrawerContext";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { i18nInit } from "./utils/i18next";

function App() {
  const [i18nLoaded, setI18nLoaded] = useState(false);

  useEffect(() => {
    i18nInit().then(() => setI18nLoaded(true));
  }, []);

  if (!i18nLoaded) return null;

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <DrawerContextProvider>
          <AlertContextProvider>
            <ModalContextProvider>
              <AlertLayout>
                <ModalLayout>
                  <CssBaseline />
                  <Outlet />
                </ModalLayout>
              </AlertLayout>
            </ModalContextProvider>
          </AlertContextProvider>
        </DrawerContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
