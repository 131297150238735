import { gantt, GanttStatic, GanttTemplates, GridColumn } from "dhtmlx-gantt";
import {
  getMaxDateAmongTasks,
  getMinDateAmongTasks,
  getWeekFormatFunction,
} from "constants/utils";
import { dayFormat } from "constants/constants";
import { Task } from "./types";

interface OwnGantt extends GanttStatic {
  setColumns: (columns: unknown[]) => void;
  setScales: (data: Task[]) => void;
  setTemplate: (
    templateName: string,
    templateCallback: GanttTemplates[keyof GanttTemplates]
  ) => void;
}

type CreateOwnGanttFunc = (m: GanttStatic) => OwnGantt;

const createOwnGantt: CreateOwnGanttFunc = (model) => {
  const newGantt = {
    setColumns(columns: unknown[]) {
      // configures the columns of the table
      model.config.columns = columns as GridColumn[];
    },
    setScales(tasks: Task[]) {
      // defines configuration settings of the timescale
      model.config.scales = [
        { unit: "week", step: 1, format: getWeekFormatFunction },
        {
          unit: "day",
          step: 1,
          format: dayFormat,
          css: function (date: Date) {
            if (date.getDay() === 0 || date.getDay() === 6) {
              return "gantt_cell_weekend";
            }
          },
        },
      ];

      model.config.duration_unit = "hour";
      model.setWorkTime({ hours: ["8:00-16:00"] });

      // === шаг времени в 1 час
      model.config.step = 1;
      // позволяет округлять даты начала и окончания задачи до ближайших отметок масштаба
      model.config.round_dnd_dates = false;
      // ===

      if (tasks.length) {
        model.config.start_date = getMinDateAmongTasks(tasks);
        model.config.end_date = getMaxDateAmongTasks(tasks);
      }
    },
    setTemplate(
      templateName: string,
      templateCallback: GanttStatic[keyof GanttStatic]
    ) {
      model.templates[templateName as keyof GanttTemplates] = templateCallback;
    },
  };
  return Object.assign(model, newGantt);
};

export const advancedGantt = createOwnGantt(gantt);
