import { TFunction } from "i18next";
import * as yup from "yup";

export const userSchemaCreate = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    name: yup.string().required(
      t("requiredField", {
        fieldName: t("name"),
        interpolation: { escapeValue: false },
      })
    ),
    email: yup
      .string()
      .email(
        t("validField", {
          fieldName: t("email"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("email"),
        })
      ),
    status: yup.string(),
    role: yup.string(),
  });
