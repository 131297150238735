import { TFunction } from "i18next";
import * as yup from "yup";

export const settingsSchema = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    mail: yup
      .string()
      .email(
        t("validField", {
          ns: "common",
          fieldName: t("emailAddress"),
        })
      )
      .required(
        t("requiredField", {
          ns: "common",
          fieldName: t("emailAddress"),
        })
      ),
    defaultRecommendations: yup
      .string()
      .transform((value) =>
        value
          .replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "")
          .trim()
      )
      .required(
        t("requiredField", {
          ns: "common",
          fieldName: t("recommendations"),
        })
      ),
    defaultComments: yup
      .string()
      .transform((value) =>
        value
          .replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "")
          .trim()
      )
      .required(
        t("requiredField", {
          ns: "common",
          fieldName: t("comments"),
        })
      ),
    defaultAboutUs: yup
      .string()
      .transform((value) =>
        value
          .replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "")
          .trim()
      )
      .required(
        t("requiredField", {
          ns: "common",
          fieldName: t("aboutUs"),
        })
      ),
  });
