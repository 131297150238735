import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
  fontFamily: "Roboto",
  h1: {
    fontSize: "22px",
    fontWeight: 600,
  },
  caption: {
    fontSize: "30px",
    fontWeight: 600,
  },
};
