import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ModalTitle from "components/ModalController/ModalTitle";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { developerSchemaCreate } from "validations/DevelopersValidations/developerSchema";
import TextField from "@mui/material/TextField";
import { useModalContext } from "context/ModalContext";
import { GET_DEVELOPERS } from "graphql/queries/getDevelopers.gql";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import {
  CreateDeveloperInput,
  DeveloperStatusEnum,
  useCreateDeveloperMutation,
  useUpdateDeveloperMutation,
} from "graphql/generated";
import { styles } from "components/ModalController/ModalCreateEditDeveloper/styles";
import DropzoneRHF from "components/DropzoneRHF";
import { alertTypes, limitItemsCount } from "constants/index";
import { ModalCreateDeveloperProps } from "components/ModalController/ModalCreateEditDeveloper/types";
import { useAlertContext } from "context/AlertContext";
import { postUploadAvatarThunk } from "../../../thunk/postUploadAvatarThunk";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

type FormDataType = CreateDeveloperInput & { uploadAvatar: File | null };

const ModalCreateEditDeveloper = ({ open }: ModalCreateDeveloperProps) => {
  const { t } = useTranslation();
  const { modalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();
  const { row, pageNumber, setPageNumberState } = modalParameters.params as {
    row: FormDataType & { id: string };
    pageNumber: number;
    setPageNumberState: (state: number) => void;
  };
  const isEditMode = !!row;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormDataType>({
    mode: "onBlur",
    resolver: yupResolver(developerSchemaCreate({ t })),
    defaultValues: {
      avatar: isEditMode ? row.avatar : "",
      firstName: isEditMode ? row.firstName : "",
      lastName: isEditMode ? row.lastName : "",
      defaultRank: isEditMode ? row.defaultRank : "",
      status: isEditMode ? row.status : ("ACTIVE" as DeveloperStatusEnum),
      defaultRate: isEditMode ? row.defaultRate : 0,
      uploadAvatar: null,
    },
  });

  const rowAvatar = watch("avatar");

  const [createDeveloper] = useCreateDeveloperMutation();
  const [updateDeveloperMutation] = useUpdateDeveloperMutation();

  const handleClose = () => {
    modalParameters.cancelAction?.();
  };

  const handleError = () => {
    handleChangeAlertParameters({
      isOpen: true,
      message: t("somethingWentWrong"),
      type: alertTypes.error,
    });
  };

  const showSuccessAlert = (message: string) => {
    handleChangeAlertParameters({
      isOpen: true,
      type: alertTypes.success,
      message,
    });
  };

  const messages = {
    developerSavedSuccess: `${t("developerWasSaved")}.`,
    developerCreatedSuccess: `${t("developerWasCreated")}.`,
  };

  const onSubmit = async (data: FormDataType) => {
    const requestData: Omit<CreateDeveloperInput, "avatar"> = {
      firstName: data.firstName,
      lastName: data.lastName,
      defaultRank: data.defaultRank,
      defaultRate: data.defaultRate,
      status: data.status,
    };

    const uploadFile = data.uploadAvatar;

    let responseAvatar = "";
    if (uploadFile) {
      const formData = new FormData();
      formData.append("avatar", uploadFile || "");
      const response: Response = await postUploadAvatarThunk({ formData });
      if (!response.ok) {
        handleError();
      }
      responseAvatar = await response.text();
    }

    if (isEditMode) {
      const getAvatarFieldData = () => {
        if (!uploadFile && !rowAvatar) {
          return "";
        }
        if (uploadFile) {
          return responseAvatar;
        }
        return rowAvatar;
      };

      await updateDeveloperMutation({
        variables: {
          input: {
            ...requestData,
            avatar: getAvatarFieldData(),
          },
          id: row.id,
        },
        onError: handleError,
        refetchQueries: () => [
          {
            query: GET_DEVELOPERS,
            variables: {
              offset: pageNumber * limitItemsCount,
              limit: limitItemsCount,
            },
          },
        ],
      });
    } else {
      await createDeveloper({
        variables: {
          input: { ...requestData, avatar: responseAvatar },
        },
        onCompleted: () => {
          setPageNumberState(0);
        },
        onError: handleError,
        refetchQueries: () => [
          {
            query: GET_DEVELOPERS,
            variables: {
              offset: 0,
              limit: 10,
            },
          },
        ],
      });
    }

    handleClose();
    showSuccessAlert(
      isEditMode
        ? messages.developerSavedSuccess
        : messages.developerCreatedSuccess
    );
    reset();
  };

  // TODO: Didn't manage to find a way how to focus the first input
  // Maybe something related to MUI Focus Trap component. Needed deeper investigation
  // WTF: autoFocus works only for create flow. When field is pre-filled it doesn't work
  // Same in ModalCreateEditUser

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.form}>
          <ModalTitle>
            {isEditMode ? t("editDeveloper") : t("createDeveloper")}
          </ModalTitle>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register("firstName")}
                sx={styles.input}
                label={t("firstName")}
                variant="outlined"
                helperText={errors?.firstName && errors?.firstName?.message}
                error={!!errors?.firstName}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("lastName")}
                sx={styles.input}
                label={t("lastName")}
                variant="outlined"
                helperText={errors?.lastName && errors?.lastName?.message}
                error={!!errors?.lastName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ marginBlock: "12px" }}>
                <Controller
                  name="uploadAvatar"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DropzoneRHF
                      name="uploadAvatar"
                      nameFieldToNullable="avatar"
                      setRHFvalue={setValue}
                      onChange={onChange}
                      avatarName={row && row.avatar}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("defaultRank")}
                sx={styles.input}
                label={t("rank")}
                variant="outlined"
                helperText={errors?.defaultRank && errors?.defaultRank?.message}
                error={!!errors?.defaultRank}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    sx={styles.input}
                    onChange={onChange}
                    value={value}
                    fullWidth>
                    <MenuItem selected value="ACTIVE">
                      ACTIVE
                    </MenuItem>
                    <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("defaultRate")}
                type="number"
                sx={styles.input}
                label={t("rate")}
                variant="outlined"
                helperText={errors?.defaultRate && errors?.defaultRate?.message}
                error={!!errors?.defaultRate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.buttonBox}>
                <Button variant="contained" type="submit">
                  {isEditMode ? t("save") : t("create")}
                </Button>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  {t("cancel")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCreateEditDeveloper;
