import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import { palette } from "../../../theme/foundations";
import { ISelectCustomProps, ISelectRHFProps } from "./types";
import { getColorStatus } from "../../../utils";

export const SelectCustom = ({
  value,
  onChange,
  options,
  errorMessage,
  label,
  placeholder,
  mb,
  sx,
  name,
  extraText,
}: ISelectCustomProps) => {
  return (
    <FormControl sx={{ marginBottom: mb, ...sx, flex: 1 }}>
      <InputLabel
        id={name}
        sx={{
          color: errorMessage ? palette.error.main : palette.text.secondary,
        }}>
        {label}
      </InputLabel>
      <Select
        id={name}
        value={value}
        onChange={onChange}
        label={label}
        error={!!errorMessage}
        displayEmpty
        {...(placeholder && {
          renderValue: (value: string) => {
            if (!value) return <Typography>{placeholder}</Typography>;
            return options?.find((opt) => opt?.value === value)?.label;
          },
        })}>
        {options
          ? options.map(({ id, value, label, status }) => {
              return (
                <MenuItem key={id} value={value}>
                  <Typography component={"span"}>{label}</Typography>
                  {extraText && (
                    <Typography
                      component={"span"}
                      color={getColorStatus(status)}>
                      &nbsp; - &nbsp;{status?.toLowerCase()}
                    </Typography>
                  )}
                </MenuItem>
              );
            })
          : null}
      </Select>
      {!!errorMessage && (
        <FormHelperText error>{String(errorMessage)}</FormHelperText>
      )}
    </FormControl>
  );
};

export const SelectRHF = ({ name, ...rest }: ISelectRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <SelectCustom
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
