import { useState } from "react";
import { useGetProjectsQuery } from "graphql/generated";
import ProjectsTable from "components/ProjectsTable";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Layout from "components/Layout/Layout";
import { limitItemsCount, TYPE_MODAL } from "constants/index";
import { useDeleteProjectMutation } from "graphql/generated";
import { styles } from "screens/ProjectsScreen/styles";
import { useNavigate } from "react-router-dom";
import { ProjectsRowEntity } from "screens/ProjectsScreen/types";
import { useModalContext } from "context/ModalContext";
import { useAlertContext } from "context/AlertContext";
import { useUserAccess } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";

const ProjectsScreen = () => {
  const [pageNumberState, setPageNumberState] = useState<number>(0);
  const { t } = useTranslation(["projects"]);
  const navigate = useNavigate();
  const { isEstimator } = useUserAccess();

  const { data, refetch } = useGetProjectsQuery({
    variables: {
      offset: pageNumberState * limitItemsCount,
      limit: limitItemsCount,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const { handleChangeModalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();

  const [deleteProjectMutation] = useDeleteProjectMutation({});

  const rows: Array<ProjectsRowEntity> = data?.getProjects.projects || [];
  const count: number = data?.getProjects.count || 0;

  const handleCloseModalDelete = () => {
    handleChangeModalParameters({
      isOpen: false,
      type: TYPE_MODAL.DELETE,
    });
  };

  const handleOpenModalCreate = () => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.CREATE_PROJECT,
      cancelAction: handleCloseModalDelete,
      params: {
        pageNumber: pageNumberState,
        navigate: navigate,
      },
    });
  };

  const handleOpenModalDelete = (id: number) => () => {
    const project = rows.find((row) => row.id === id.toString());

    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.DELETE,
      cancelAction: handleCloseModalDelete,
      confirmAction: () => removeProject(id),
      params: {
        name: project?.name,
      },
    });
  };

  const removeProject = async (id: number) => {
    const res = await deleteProjectMutation({
      variables: {
        id,
      },
      onError: handleErrors,
    });
    await refetch({
      offset: pageNumberState * limitItemsCount,
      limit: limitItemsCount,
    });

    if (res.data && res.data.deleteProject.status === 200) {
      handleChangeAlertParameters({
        isOpen: true,
        message: t("projectWasDeleted"),
        type: "success",
        position: "bottom",
      });
    }
    handleCloseModalDelete();
  };

  const handleErrors = (error: ApolloError) => {
    handleChangeAlertParameters({
      isOpen: true,
      message: error.message,
      type: "error",
    });

    handleCloseModalDelete();
  };

  return (
    <Layout namePage={t("projects")}>
      <Box sx={styles.container}>
        <Box>
          {!isEstimator && (
            <Button
              variant="contained"
              onClick={handleOpenModalCreate}
              startIcon={<AddIcon />}
              sx={styles.buttonCreate}>
              {t("newProject")}
            </Button>
          )}
          <ProjectsTable
            rows={rows}
            rowCount={count}
            removeProject={handleOpenModalDelete}
            setPageNumberState={setPageNumberState}
            page={pageNumberState}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default ProjectsScreen;
