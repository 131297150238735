import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

interface ButtonColorGroupProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const ButtonColorGroup = ({
  onClose,
  onConfirm,
}: ButtonColorGroupProps) => {
  return (
    <Stack
      direction={"row"}
      gap={"20px"}
      justifyContent={"space-between"}
      mt={2}>
      <Button fullWidth variant={"contained"} onClick={onClose}>
        Cancel
      </Button>
      <Button fullWidth variant={"contained"} onClick={onConfirm}>
        Ok
      </Button>
    </Stack>
  );
};
