import { useMemo } from "react";
import { Developer } from "./types";
import { DeveloperEntity } from "../../../../graphql/generated";

export const useGetInOutDevelopers = (
  projectDevelopers: Developer[] = [],
  allDevelopers: Developer[] = []
): [DeveloperEntity[], DeveloperEntity[]] => {
  return useMemo(() => {
    if (allDevelopers.length) {
      const inDevsMap = new Map();
      const outDevsMap = new Map();
      const inDevsIds = (projectDevelopers as Developer[]).reduce(
        (acc, next) => {
          inDevsMap.set(next.id, next);

          acc.push(next.id);
          return acc;
        },
        [] as string[]
      );

      allDevelopers.forEach((item) => {
        if (inDevsIds.includes(item.id)) {
          inDevsMap.set(item.id, item);
        } else {
          outDevsMap.set(item.id, item);
        }
      });

      const inDevs = Array.from(inDevsMap, ([, value]) => {
        return {
          ...value,
        };
      });
      const outDevs = Array.from(outDevsMap, ([, value]) => ({
        ...value,
      }));

      return [inDevs, outDevs];
    }
    return [[], []];
  }, [allDevelopers, projectDevelopers]);
};
