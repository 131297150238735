import React from "react";
import { ListItem } from "@mui/material";
import {
  ListItemButtonCustom,
  ListItemIconCustom,
  ListItemTextCustom,
} from "components/ListItemMenu/styles";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useTranslation } from "react-i18next";

interface ListItemMenuProps {
  open?: boolean;
  title?: string;
}

const ListItemMenu: React.FC<ListItemMenuProps> = ({ open, title }) => {
  const { t } = useTranslation();
  const getItemIcon = () => {
    if (title === t("sidebar.projects")) {
      return <HomeIcon />;
    }
    if (title === t("sidebar.users")) {
      return <PersonOutlineIcon />;
    }
    if (title === t("sidebar.developers")) {
      return <PersonIcon />;
    }
    if (title === t("sidebar.settings")) {
      return <SettingsIcon />;
    }
  };

  return (
    <ListItem key={title} disablePadding sx={{ display: "block" }}>
      <ListItemButtonCustom open={open}>
        <ListItemIconCustom open={open}>{getItemIcon()}</ListItemIconCustom>
        <ListItemTextCustom primary={title} open={open} />
      </ListItemButtonCustom>
    </ListItem>
  );
};

export default ListItemMenu;
