export const styles = {
  container: {
    width: "43vw",
    padding: "8px",
    position: "relative",
  },
  title: {
    marginBottom: "24px",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBlock: "14px",
  },
  button: {
    width: "100%",
    marginTop: "10px",
  },
  linksContainer: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  captionCustom: {
    fontSize: "12px",
    lineHeight: "1.4375em",
    color: "#9c9c9c",
    position: "absolute",
    top: "-8px",
    left: "7px",
    background: "#fff",
    padding: "0 6px",
  },
  editorError: {
    top: "calc(100% + 130px)",
    color: "#f46e6f",
  },
  editorWrapper: (error?: string) => {
    return {
      ...(error && {
        label: {
          color: "#f46e6f",
        },
        ".ql-toolbar.ql-snow": {
          borderColor: "#f46e6f",
        },
        ".ql-container.ql-snow": {
          borderColor: "#f46e6f",
        },
      }),
      height: "170px",
      borderRadius: "5px",
      marginBottom: "150px",
      position: "relative",
    };
  },
};
