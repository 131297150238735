import {
  useContext,
  useState,
  createContext,
  FC,
  PropsWithChildren,
} from "react";

interface Params {
  cancelAction?: () => void;
  confirmAction?: () => void;
  value?: string;
  type?: string;
  isOpen: boolean;
  params?: Record<string, unknown>;
}

export const ModalContext = createContext<{
  isAuth: boolean;
  handleChangeModalParameters: (params: Params) => void;
  modalParameters: Params;
  handleClose: () => void;
}>({
  isAuth: false,
  handleChangeModalParameters: () => {},
  modalParameters: {} as Params,
  handleClose: () => {},
});

export function useModalContext() {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error("useModalContext must be used within a AuthProvider");
  }

  return modalContext;
}

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalParameters, setModalParameters] = useState({
    cancelAction: () => {},
    confirmAction: () => {},
    value: "",
    type: "",
    isOpen: false,
    params: {},
  });

  const handleChangeModalParameters = (params: Params) => {
    setModalParameters((prevModalParameters) => ({
      ...prevModalParameters,
      ...params,
    }));
  };

  const handleClose = () => {
    setModalParameters((prevState) => {
      return { ...prevState, isOpen: false };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        isAuth: false,
        handleChangeModalParameters: handleChangeModalParameters,
        modalParameters: modalParameters,
        handleClose,
      }}>
      {children}
    </ModalContext.Provider>
  );
};
