import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface PdfAboutUsProps {
  background: string;
  titleColor: string;
  textColor: string;
}

export const PdfAboutUs = ({
  background,
  titleColor,
  textColor,
}: PdfAboutUsProps) => {
  return (
    <Stack
      className="about-us-block"
      sx={{
        padding: "50px 100px",
        background: background,
      }}>
      <Typography
        color={titleColor}
        variant={"caption"}
        sx={{ marginBottom: "10px" }}>
        About us
      </Typography>
      <Typography color={textColor} sx={{ marginBottom: "10px" }}>
        Lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique.
        Turpis tincidunt id aliquet risus. Ac odio tempor orci dapibus ultrices
        in iaculis nunc. Pellentesque sit amet porttitor eget dolor morbi non.
        In nisl nisi scelerisque eu ultrices.
      </Typography>
      <Typography color={textColor} sx={{ marginBottom: "10px" }}>
        Arcu risus quis varius quam quisque id diam. Enim eu turpis egestas
        pretium aenean pharetra magna ac placerat. Nulla aliquet enim tortor at
        auctor urna nunc. Lorem sed risus ultricies tristique nulla aliquet. At
        in tellus integer feugiat scelerisque.
      </Typography>
      <Typography color={textColor} sx={{ marginBottom: "10px" }}>
        Dignissim sodales ut eu sem integer vitae justo. Eu consequat ac felis
        donec et odio pellentesque diam. Malesuada bibendum arcu vitae elementum
        curabitur vitae nunc sed velit.
      </Typography>
    </Stack>
  );
};
