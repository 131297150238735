import React, { useState, useEffect, useMemo } from "react";
import ProjectDeveloperTable from "components/ProjectDevelopersTable";
import {
  useGetDevelopersListLazyQuery,
  DeveloperEntity,
  GetProjectCustomDocument,
  GetProjectCustomQuery,
} from "graphql/generated";
import {
  Developer,
  DevelopersTabProps,
  FormDataProjectDevelopers,
} from "screens/ProjectDetailsScreen/types";
import Box from "@mui/material/Box";
import { styles } from "screens/ProjectDetailsScreen/DevelopersTab/styles";
import { removeArchivedDevelopers } from "../../../utils";
import { client } from "../../../graphql/client/client";

const emptyObject = {
  id: 0,
  name: "",
  defaultRank: "",
  defaultRate: NaN,
};

const DevelopersTab: React.FC<DevelopersTabProps> = ({ id }) => {
  const [allDevelopers, setAllDevelopers] = useState<
    Array<Partial<DeveloperEntity>>
  >([]);

  const [developers, setDevelopers] = useState<Array<Developer> | []>([]);

  const [getDevelopers] = useGetDevelopersListLazyQuery();

  // ! = this dirty code is related to updating component lists and their data

  const data = client.readQuery<GetProjectCustomQuery>({
    query: GetProjectCustomDocument,
    variables: {
      projectId: +id,
    },
  });

  useEffect(() => {
    if (data) {
      setDevelopers(() =>
        data?.getProject.projectDevelopers.map(
          (item: FormDataProjectDevelopers) => {
            return {
              defaultRank: item.rank,
              defaultRate: item.rate,
              id: item.developerId,
              firstName: item.developer.firstName,
              lastName: item.developer.lastName,
              color: item.color,
            };
          }
        )
      );
    }
  }, [data]);

  const fetchAllDevelopers = async () => {
    const res = await getDevelopers({
      variables: {
        offset: 0,
        limit: 100,
      },
    });

    if (res.data) {
      setAllDevelopers(
        removeArchivedDevelopers(res.data.getDevelopersList.developers)
      );
    }
  };

  useEffect(() => {
    fetchAllDevelopers();
  }, []);

  const formattedDevelopers = useMemo(() => {
    const ids = developers.map((item) => item.id);

    return allDevelopers.map((developer) => ({
      ...developer,
      isOnProject: ids.includes(developer.id ?? ""),
    }));
  }, [developers, allDevelopers]);

  return (
    <Box sx={styles.container}>
      <ProjectDeveloperTable
        rows={[...developers, emptyObject]}
        count={developers.length + 1}
        allDevelopers={formattedDevelopers}
        setDevelopers={setDevelopers}
      />
    </Box>
  );
};

export default DevelopersTab;
