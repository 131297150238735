import { alertTypes } from "../../constants";
import * as GENERATED from "../generated/index";
import { useAlertContext } from "../../context/AlertContext";

export const useMoveTask = (
  projectId: string | number,
  withNotification = false
) => {
  const { handleChangeAlertParameters } = useAlertContext();

  return GENERATED.useMoveTaskMutation({
    // refetchQueries: () => [
    //   {
    //     query: GET_PROJECT,
    //     variables: {
    //       projectId: +projectId,
    //     },
    //   },
    // ],
    // fetchPolicy: "network-only",
    onCompleted: () => {
      handleChangeAlertParameters({
        isOpen: withNotification,
        type: alertTypes.success,
        message: "Stories успешно відсортовано",
      });
    },
    onError: () => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.error,
        message: "Произошла ошибка",
      });
    },
    update: (cache, result) => {
      const moveTask = result.data?.moveTask;
      cache.modify({
        id: `ProjectEntity:${moveTask?.id}`,
        fields: {
          sprints() {
            return moveTask?.sprints || [];
          },
        },
      });
    },
  });
};
