import { IconButton, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { styles } from "../styles";

export const PasswordInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      {...register("password")}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleShowPassword}>
            {!showPassword ? (
              <VisibilityIcon color={errors?.password ? "error" : "inherit"} />
            ) : (
              <VisibilityOffIcon
                color={errors?.password ? "error" : "inherit"}
              />
            )}
          </IconButton>
        ),
      }}
      type={showPassword ? "text" : "password"}
      sx={styles.input}
      label="Password"
      variant="outlined"
      helperText={errors?.password?.message as string}
      error={!!errors?.password}
    />
  );
};
