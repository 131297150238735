/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { Controller } from "react-hook-form";
import { Typography, Box } from "@mui/material";
import { postUploadFileThunk } from "../../thunk/postUploadFileThunk";
import { Quill } from "react-quill";

const ColorAttributor = Quill.import("attributors/style/color");
const BackgroundAttributor = Quill.import("attributors/style/background");
ColorAttributor.whitelist = [];
BackgroundAttributor.whitelist = [];
Quill.register(ColorAttributor);
Quill.register(BackgroundAttributor);

type EditorType = {
  field: any;
};

export const Editor = ({ field }: EditorType) => {
  const { quillRef, quill } = useQuill({
    modules: {
      toolbar: toolbarOptions,
    },
    formats,
  });

  const insertToEditor = (url: string) => {
    const range = quill?.getSelection();
    quill?.insertEmbed(range?.index as number, "image", url);
  };

  const saveToServer = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await postUploadFileThunk({ formData });
    const responseData = await response.text();

    const uploadFile = `${process.env.REACT_APP_URI}/uploads/files/${responseData}`;
    insertToEditor(uploadFile as string);
  };

  const selectLocalImage = () => {
    const input: HTMLInputElement = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input?.files && input?.files[0];
      file && saveToServer(file);
    };
  };

  const [valueInitUse, setValueInitUse] = useState(false);

  useEffect(() => {
    if (quill && !valueInitUse && field.value) {
      setValueInitUse(true);
      const delta = quill.clipboard.convert(field.value);
      quill.setContents(delta);
    }
  }, [field?.value, quill]);

  useEffect(() => {
    if (quill?.root?.innerHTML) {
      quill.on("text-change", () => {
        field.onChange(quill.root.innerHTML);
      });
    }
  }, [quill?.root?.innerHTML]);

  useEffect(() => {
    if (quill) {
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill]);

  return (
    <Box sx={styles.container}>
      <div ref={quillRef} />
    </Box>
  );
};

type EditorControllerType = {
  control: any;
  name: any;
  label: string;
};

export const EditorController = ({
  control,
  name,
  label,
}: EditorControllerType) => {
  return (
    <>
      <label>
        <Typography>{label}</Typography>
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Editor field={field} />}
      />
    </>
  );
};

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote"],
  ["link", "image"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "link",
  "image",
  "header",
  "list",
  "indent",
  "direction",
  "size",
  "color",
  "background",
  "font",
  "align",
  "clean",
];

const styles = {
  container: { height: "180px" },
};
