import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateHeader } from "./DateHeader";

interface PdfPaymentsProps {
  background: string;
  tableBackground: string;
  tableBodyBackground: string;
  tableFooterBackground: string;
  titleColor: string;
  devAmountAndHoursColor: string;
  textColor: string;
  rankColor: string;
  totalTimeAndAmountTextColor: string;
  totalTimeAndAmountBackground: string;
  paymentHeaderBackground: string;
  paymentPriceWrapperBackground: string;
  paymentTotalPriceTextColor: string;
}

export const PdfPayments = ({
  background,
  tableBackground,
  tableBodyBackground,
  tableFooterBackground,
  titleColor,
  devAmountAndHoursColor,
  textColor,
  rankColor,
  totalTimeAndAmountTextColor,
  totalTimeAndAmountBackground,
  paymentHeaderBackground,
  paymentPriceWrapperBackground,
  paymentTotalPriceTextColor,
}: PdfPaymentsProps) => {
  return (
    <Stack
      className="payments-block"
      sx={{ background: background, padding: "50px 100px" }}>
      <Typography
        variant={"caption"}
        sx={{ marginBottom: "10px" }}
        color={titleColor}>
        Payments
      </Typography>
      <Stack sx={{ background: tableBackground, padding: "10px", gap: "10px" }}>
        <DateHeader
          backgroundColor={paymentHeaderBackground}
          priceBackground={paymentPriceWrapperBackground}
          textColor={paymentTotalPriceTextColor}
        />
        <Stack
          sx={{
            padding: "10px",
            background: tableBodyBackground,
            gap: "10px",
          }}>
          {Array.from({ length: 4 }, (v, k) => k).map((_, i) => {
            return (
              <Stack key={i} sx={{ padding: "10px", background: "#c052ce" }}>
                <Typography color={rankColor}>developer</Typography>
                <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                  <Stack
                    sx={{
                      padding: "5px",
                      background: "#fff",
                      alignItems: "center",
                    }}
                    color={devAmountAndHoursColor}>
                    $2 082
                  </Stack>
                  <Stack
                    sx={{
                      padding: "5px",
                      background: "#fff",
                      alignItems: "center",
                    }}
                    color={devAmountAndHoursColor}>
                    347hours
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Stack
          sx={{
            padding: "10px",
            background: tableFooterBackground,
            flexDirection: "row",
            gap: "10px",
          }}>
          <Stack
            sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography color={textColor}>Total amount:</Typography>
            <Stack
              sx={{
                background: totalTimeAndAmountBackground,
                color: totalTimeAndAmountTextColor,
                padding: "5px 20px",
              }}>
              <Typography>7777</Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography color={textColor}>Total hours:</Typography>
            <Stack
              sx={{
                background: totalTimeAndAmountBackground,
                color: totalTimeAndAmountTextColor,
                padding: "5px 20px",
              }}>
              <Typography>777</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
