import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { NAME_COLOR_BTNS } from "../../../constants";
import { Dispatch, MouseEvent, SetStateAction } from "react";

import { useTranslation } from "react-i18next";
import { SectionHeading } from "./SectionHeading";
import {
  ChildColorStackType,
  ColorStackType,
} from "../PaletteSettingsTab/types";
import { SectionAccordion } from "./SectionAccordion";
import { ColorPickerButton } from "./ColorPickerButton";

interface ColorSelectedBlockProps {
  colorsStack: ColorStackType;
  setParentStack: Dispatch<
    SetStateAction<
      | "header"
      | "estimate"
      | "rate"
      | "payments"
      | "recommendation"
      | "team"
      | "comments"
      | "aboutUs"
      | null
    >
  >;
  onClickByColor: (
    event: MouseEvent<HTMLButtonElement>,
    colorForStack: ChildColorStackType
  ) => void;
  onApply: () => void;
  onSkipToDefault: () => void;
  isDisabledApplyBtn: boolean;
  isDisabledDefaultBtn: boolean;
}

export const ColorSelectedBlock = ({
  colorsStack,
  setParentStack,
  onClickByColor,
  onApply,
  onSkipToDefault,
  isDisabledApplyBtn,
  isDisabledDefaultBtn,
}: ColorSelectedBlockProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionAccordion
        summary={
          <SectionHeading anchor="header-footer">Header/Footer</SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.header}
            onClickByColor={onClickByColor}
            parentStackKey="header"
            section={NAME_COLOR_BTNS.HEADER}
            setParentStack={setParentStack}
          />
        }
        aria-controls="header-footer-content"
        id="header-footer-content"
        defaultExpanded
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="estimate-block">
            Estimate block
          </SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.estimate}
            onClickByColor={onClickByColor}
            parentStackKey="estimate"
            section={NAME_COLOR_BTNS.ESTIMATE}
            setParentStack={setParentStack}
          />
        }
        aria-controls="estimate-content"
        id="estimate-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="rate-block">Rate block</SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.rate}
            onClickByColor={onClickByColor}
            parentStackKey="rate"
            section={NAME_COLOR_BTNS.RATE}
            setParentStack={setParentStack}
          />
        }
        aria-controls="rate-content"
        id="rate-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="payments-block">
            Payments block
          </SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.payments}
            onClickByColor={onClickByColor}
            parentStackKey="payments"
            section={NAME_COLOR_BTNS.PAYMENTS}
            setParentStack={setParentStack}
          />
        }
        aria-controls="payments-content"
        id="payments-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="recommendation-block">
            Recommendation block
          </SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.recommendation}
            onClickByColor={onClickByColor}
            parentStackKey="recommendation"
            section={NAME_COLOR_BTNS.RECOMMENDATION}
            setParentStack={setParentStack}
          />
        }
        aria-controls="recommendation-content"
        id="recommendation-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="team-block">Team block</SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.team}
            onClickByColor={onClickByColor}
            parentStackKey="team"
            section={NAME_COLOR_BTNS.TEAM}
            setParentStack={setParentStack}
          />
        }
        aria-controls="team-content"
        id="team-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="comments-block">
            Comments block
          </SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.comments}
            onClickByColor={onClickByColor}
            parentStackKey="comments"
            section={NAME_COLOR_BTNS.COMMENTS}
            setParentStack={setParentStack}
          />
        }
        aria-controls="comments-content"
        id="comments-content"
      />

      <SectionAccordion
        summary={
          <SectionHeading anchor="about-us-block">
            About us block
          </SectionHeading>
        }
        details={
          <ColorPickerButton
            colors={colorsStack.aboutUs}
            onClickByColor={onClickByColor}
            parentStackKey="aboutUs"
            section={NAME_COLOR_BTNS.ABOUT_US}
            setParentStack={setParentStack}
          />
        }
        aria-controls="aboutUs-content"
        id="aboutUs-content"
      />

      <Stack gap={1} mb={6}>
        <Button
          variant={"contained"}
          onClick={onApply}
          disabled={isDisabledApplyBtn}>
          {t("apply")}
        </Button>
        <Button
          variant={"contained"}
          onClick={onSkipToDefault}
          disabled={isDisabledDefaultBtn}>
          {t("toDefault")}
        </Button>
      </Stack>
    </>
  );
};
