import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { LanguagePickerCustom } from "../UI";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar } from "../Layout/styles";
import { useNavigate } from "react-router-dom";
import { useChangeLanguage } from "../../hooks";
import { LANGUAGE_OPTION } from "../../constants";

interface HeaderProps {
  userName?: string;
  namePage?: string;
  isOpen: boolean;
  onOpen: () => void;
}

export const Header = ({ isOpen, userName, namePage, onOpen }: HeaderProps) => {
  const navigate = useNavigate();
  const { changeLang, currentLanguage } = useChangeLanguage();

  const logout = () => {
    localStorage.removeItem("token");
    navigate(0);
  };

  const handleDrawerOpen = () => {
    onOpen();
    localStorage.setItem("open", "1");
    setTimeout(() => {
      gantt.render();
    }, 500);
  };

  return (
    <AppBar position="fixed" open={isOpen}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center" flex={1}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(isOpen && { display: "none" }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h1" noWrap>
            {namePage}
          </Typography>
        </Box>
        <Typography mr={3}>{userName}</Typography>
        <LanguagePickerCustom
          options={LANGUAGE_OPTION}
          value={currentLanguage}
          onChange={changeLang}
        />
        <IconButton color="inherit" onClick={logout}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
