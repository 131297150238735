import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ErrorImage from "../../../../images/defaultAvatar.png";

interface PdfTeamProps {
  background: string;
  titleColor: string;
  rankColor: string;
  devNameColor: string;
}

export const PdfTeam = ({
  background,
  titleColor,
  rankColor,
  devNameColor,
}: PdfTeamProps) => {
  return (
    <Stack
      className="team-block"
      sx={{
        padding: "50px 100px",
        background: background,
      }}>
      <Typography
        variant={"caption"}
        sx={{ marginBottom: "10px" }}
        color={titleColor}>
        Team
      </Typography>
      <Stack
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        }}>
        {Array.from({ length: 10 }, (v, k) => k).map((_, i) => {
          return (
            <Stack key={i}>
              <img src={ErrorImage} alt="i" width={"140px"} />
              <Typography color={devNameColor}>John Doe</Typography>
              <Typography color={rankColor}>Developer</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
