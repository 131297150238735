import { TFunction } from "i18next";
import * as yup from "yup";

export const storySchemaCreate = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    name: yup.string().required(
      t("requiredField", {
        fieldName: t("storyName"),
      })
    ),
  });
