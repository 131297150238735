import React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { styles } from "components/ModalController/ModalDelete/styles";
import { useModalContext } from "context/ModalContext";
import { useTranslation } from "react-i18next";

interface ModalDelete {
  open: boolean;
}

const ModalDelete: React.FC<ModalDelete> = ({ open }) => {
  const { t } = useTranslation();
  const { modalParameters } = useModalContext();

  const handleClose = () => {
    modalParameters.cancelAction?.();
  };

  const removeItem = () => {
    modalParameters.confirmAction?.();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2">
          {t("doYouWantToDeleteThis", {
            what: (modalParameters.params as { name: string }).name,
          })}
        </Typography>
        <Box sx={styles.buttonBox}>
          <Button
            onClick={removeItem}
            color="error"
            variant="contained"
            endIcon={<DeleteIcon />}>
            {t("delete")}
          </Button>
          <Button onClick={handleClose} variant="outlined">
            {t("cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDelete;
