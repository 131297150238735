/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AttachDeveloperToProjectInput = {
  color: Scalars["String"];
  developerId: Scalars["Int"];
  projectId: Scalars["Int"];
  rank?: InputMaybe<Scalars["String"]>;
  rate?: InputMaybe<Scalars["Int"]>;
};

export type AuthorizationResponseSchema = {
  __typename?: "AuthorizationResponseSchema";
  accessToken: Scalars["String"];
  user?: Maybe<UserEntity>;
};

export type CreateDeveloperInput = {
  avatar: Scalars["String"];
  defaultRank: Scalars["String"];
  defaultRate: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  /** Default value: ACTIVE */
  status?: DeveloperStatusEnum;
};

export type CreateProjectInput = {
  isWithChart: Scalars["Boolean"];
  language: LanguageEnum;
  name: Scalars["String"];
  userId?: InputMaybe<Scalars["Int"]>;
};

export type CreateSprintInput = {
  name: Scalars["String"];
};

export type CreateStoryInput = {
  name: Scalars["String"];
  sprintId: Scalars["Int"];
};

export type CreateTaskInput = {
  developerId: Scalars["Int"];
  duration: Scalars["Int"];
  name: Scalars["String"];
  sprintId: Scalars["Int"];
  startedAt: Scalars["DateTime"];
  storyId?: InputMaybe<Scalars["Int"]>;
};

export type CreateUserInput = {
  avatar?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  role: UserRoleEnum;
  status: UserStatusEnum;
};

export type DeveloperEntity = {
  __typename?: "DeveloperEntity";
  avatar?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  defaultRank: Scalars["String"];
  defaultRate: Scalars["Int"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  projectDevelopers: Array<ProjectDeveloperEntity>;
  /** Default value: ACTIVE */
  status: DeveloperStatusEnum;
  tasks: Array<TaskEntity>;
  updatedAt: Scalars["String"];
};

/** Default value: ACTIVE */
export enum DeveloperStatusEnum {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type DevelopersListSchema = {
  __typename?: "DevelopersListSchema";
  count: Scalars["Int"];
  developers: Array<DeveloperEntity>;
};

export type EditAttachedDeveloperInProjectInput = {
  color: Scalars["String"];
  newDeveloperId?: InputMaybe<Scalars["Int"]>;
  rank: Scalars["String"];
  rate: Scalars["Int"];
};

export type EditProjectInput = {
  aboutUs?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  isWithChart?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectEstimators?: InputMaybe<Array<Scalars["Int"]>>;
  recommendations?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ProjectStatusEnum>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type EditSprintInput = {
  name: Scalars["String"];
};

export type EditStoryInput = {
  name: Scalars["String"];
};

export type EditTaskInput = {
  developerId: Scalars["Int"];
  duration: Scalars["Int"];
  name: Scalars["String"];
  startedAt: Scalars["DateTime"];
};

export type EditUserInput = {
  avatar?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  role: UserRoleEnum;
  status: UserStatusEnum;
};

export type FileEntity = {
  __typename?: "FileEntity";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  project: ProjectEntity;
  projectId: Scalars["Int"];
  updatedAt: Scalars["String"];
};

export type FilesWithCountSchema = {
  __typename?: "FilesWithCountSchema";
  count: Scalars["Int"];
  files: Array<FileEntity>;
};

export type GetUsersInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<UserRoleEnum>;
};

export enum LanguageEnum {
  En = "en",
  Ru = "ru",
  Uk = "uk",
}

export type LanguageType = {
  __typename?: "LanguageType";
  value: Scalars["String"];
};

export type MoveStoryInput = {
  newSprintId: Scalars["Int"];
  sortOrder: Scalars["Int"];
  storyId: Scalars["Int"];
};

export type MoveTaskInput = {
  moveTo: MoveTaskToEnum;
  /** Depending on which entity was selected in the "moveTo" field, its ID must be provided here */
  moveToId: Scalars["Int"];
  sortOrder: Scalars["Int"];
  taskId: Scalars["Int"];
};

export enum MoveTaskToEnum {
  Sprint = "SPRINT",
  Story = "STORY",
}

export type Mutation = {
  __typename?: "Mutation";
  attachDeveloperToProject: ProjectDeveloperEntity;
  changePdfColors: PdfColorsEntity;
  changeProjectLanguage: ProjectEntity;
  changeSettings?: Maybe<SettingsEntity>;
  confirmPasswordReset: StatusResponseSchema;
  createDeveloper: DeveloperEntity;
  createFile: FileEntity;
  createFilePreview: Scalars["String"];
  createProject: ProjectEntity;
  createSprint: SprintEntity;
  createStory: StoryEntity;
  createTask: TaskEntity;
  createUser?: Maybe<UserEntity>;
  deleteProject: StatusResponseSchema;
  deleteSprint: StatusResponseSchema;
  deleteStory: StatusResponseSchema;
  deleteTask: StatusResponseSchema;
  detachDeveloperFromProject: StatusResponseSchema;
  editAttachedDeveloperInProject: ProjectDeveloperEntity;
  editProject: ProjectEntity;
  editSprint: SprintEntity;
  editStory: StoryEntity;
  editTask: TaskEntity;
  editUser?: Maybe<UserEntity>;
  moveStory: ProjectEntity;
  moveTask: ProjectEntity;
  removeDeveloper: RemoveDeveloperSchema;
  requestPasswordReset: StatusResponseSchema;
  sortStory: SprintEntity;
  /**
   * Sorts task at its current position.
   *     If task is in sprint, it will be sorted together with other stories and tasks.
   *     If task is in story, it will be sorted with other tasks.
   */
  sortTask: SprintEntity;
  sprintSorting: StatusResponseSchema;
  updateDeveloper: DeveloperEntity;
};

export type MutationAttachDeveloperToProjectArgs = {
  input: AttachDeveloperToProjectInput;
};

export type MutationChangePdfColorsArgs = {
  input: PdfColorsInput;
};

export type MutationChangeProjectLanguageArgs = {
  id: Scalars["Int"];
  language: Scalars["String"];
};

export type MutationChangeSettingsArgs = {
  input: ChangeSettingsInput;
};

export type MutationConfirmPasswordResetArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type MutationCreateDeveloperArgs = {
  input: CreateDeveloperInput;
};

export type MutationCreateFileArgs = {
  projectId: Scalars["Int"];
};

export type MutationCreateFilePreviewArgs = {
  projectId: Scalars["Int"];
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateSprintArgs = {
  input: CreateSprintInput;
  projectId: Scalars["Int"];
};

export type MutationCreateStoryArgs = {
  input: CreateStoryInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteProjectArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteSprintArgs = {
  sprintId: Scalars["Int"];
};

export type MutationDeleteStoryArgs = {
  storyId: Scalars["Int"];
};

export type MutationDeleteTaskArgs = {
  taskId: Scalars["Int"];
};

export type MutationDetachDeveloperFromProjectArgs = {
  developerId: Scalars["Int"];
  projectId: Scalars["Int"];
};

export type MutationEditAttachedDeveloperInProjectArgs = {
  developerId: Scalars["Int"];
  input: EditAttachedDeveloperInProjectInput;
  projectId: Scalars["Int"];
};

export type MutationEditProjectArgs = {
  id: Scalars["Int"];
  input: EditProjectInput;
};

export type MutationEditSprintArgs = {
  input: EditSprintInput;
  sprintId: Scalars["Int"];
};

export type MutationEditStoryArgs = {
  input: EditStoryInput;
  storyId: Scalars["Int"];
};

export type MutationEditTaskArgs = {
  input: EditTaskInput;
  taskId: Scalars["Int"];
};

export type MutationEditUserArgs = {
  input: EditUserInput;
};

export type MutationMoveStoryArgs = {
  input: MoveStoryInput;
};

export type MutationMoveTaskArgs = {
  input: MoveTaskInput;
};

export type MutationRemoveDeveloperArgs = {
  developerId: Scalars["ID"];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"];
};

export type MutationSortStoryArgs = {
  input: SortStoryInput;
};

export type MutationSortTaskArgs = {
  input: SortTaskInput;
};

export type MutationSprintSortingArgs = {
  sprintIds: Array<Scalars["Int"]>;
};

export type MutationUpdateDeveloperArgs = {
  id: Scalars["ID"];
  input: UpdateDeveloperInput;
};

export type PdfColorsEntity = {
  __typename?: "PdfColorsEntity";
  aboutUsBackground: Scalars["String"];
  aboutUsTextColor: Scalars["String"];
  aboutUsTitleColor: Scalars["String"];
  commentsBackground: Scalars["String"];
  estimateBackground: Scalars["String"];
  estimateTableBackground: Scalars["String"];
  estimateTableBodyBackground: Scalars["String"];
  estimateTableHeadBackground: Scalars["String"];
  estimateTableRowBackground: Scalars["String"];
  estimationHeaderColor: Scalars["String"];
  estimationHourColor: Scalars["String"];
  estimationSprintColor: Scalars["String"];
  estimationStoryColor: Scalars["String"];
  estimationTaskColor: Scalars["String"];
  estimationTitleColor: Scalars["String"];
  headerBackground: Scalars["String"];
  headerColor: Scalars["String"];
  id: Scalars["ID"];
  paymentHeaderBackground: Scalars["String"];
  paymentPriceWrapperBackground: Scalars["String"];
  paymentTotalPriceTextColor: Scalars["String"];
  paymentsBackground: Scalars["String"];
  paymentsDevAmountAndHoursColor: Scalars["String"];
  paymentsRankColor: Scalars["String"];
  paymentsTableBackground: Scalars["String"];
  paymentsTableBodyBackground: Scalars["String"];
  paymentsTableFooterBackground: Scalars["String"];
  paymentsTextColor: Scalars["String"];
  paymentsTitleColor: Scalars["String"];
  paymentsTotalTimeAndAmountBackground: Scalars["String"];
  paymentsTotalTimeAndAmountTextColor: Scalars["String"];
  projectCommentsTextColor: Scalars["String"];
  projectCommentsTitleColor: Scalars["String"];
  projectNameTextColor: Scalars["String"];
  rateBackground: Scalars["String"];
  rateCardBackground: Scalars["String"];
  ratesDevNameColor: Scalars["String"];
  ratesDevRateColor: Scalars["String"];
  ratesRankColor: Scalars["String"];
  ratesTitleColor: Scalars["String"];
  recommendationBackground: Scalars["String"];
  recommendationsTextColor: Scalars["String"];
  recommendationsTitleColor: Scalars["String"];
  teamBackground: Scalars["String"];
  teamDevNameColor: Scalars["String"];
  teamRankColor: Scalars["String"];
  teamTitleColor: Scalars["String"];
};

export type PdfColorsInput = {
  aboutUsBackground: Scalars["String"];
  aboutUsTextColor: Scalars["String"];
  aboutUsTitleColor: Scalars["String"];
  commentsBackground: Scalars["String"];
  estimateBackground: Scalars["String"];
  estimateTableBackground: Scalars["String"];
  estimateTableBodyBackground: Scalars["String"];
  estimateTableHeadBackground: Scalars["String"];
  estimateTableRowBackground: Scalars["String"];
  estimationHeaderColor: Scalars["String"];
  estimationHourColor: Scalars["String"];
  estimationSprintColor: Scalars["String"];
  estimationStoryColor: Scalars["String"];
  estimationTaskColor: Scalars["String"];
  estimationTitleColor: Scalars["String"];
  headerBackground: Scalars["String"];
  headerColor: Scalars["String"];
  paymentHeaderBackground: Scalars["String"];
  paymentPriceWrapperBackground: Scalars["String"];
  paymentTotalPriceTextColor: Scalars["String"];
  paymentsBackground: Scalars["String"];
  paymentsDevAmountAndHoursColor: Scalars["String"];
  paymentsRankColor: Scalars["String"];
  paymentsTableBackground: Scalars["String"];
  paymentsTableBodyBackground: Scalars["String"];
  paymentsTableFooterBackground: Scalars["String"];
  paymentsTextColor: Scalars["String"];
  paymentsTitleColor: Scalars["String"];
  paymentsTotalTimeAndAmountBackground: Scalars["String"];
  paymentsTotalTimeAndAmountTextColor: Scalars["String"];
  projectCommentsTextColor: Scalars["String"];
  projectCommentsTitleColor: Scalars["String"];
  projectNameTextColor: Scalars["String"];
  rateBackground: Scalars["String"];
  rateCardBackground: Scalars["String"];
  ratesDevNameColor: Scalars["String"];
  ratesDevRateColor: Scalars["String"];
  ratesRankColor: Scalars["String"];
  ratesTitleColor: Scalars["String"];
  recommendationBackground: Scalars["String"];
  recommendationsTextColor: Scalars["String"];
  recommendationsTitleColor: Scalars["String"];
  teamBackground: Scalars["String"];
  teamDevNameColor: Scalars["String"];
  teamRankColor: Scalars["String"];
  teamTitleColor: Scalars["String"];
};

export type ProjectDeveloperEntity = {
  __typename?: "ProjectDeveloperEntity";
  color: Scalars["String"];
  developer: DeveloperEntity;
  developerId: Scalars["ID"];
  project: ProjectEntity;
  projectId: Scalars["ID"];
  rank: Scalars["String"];
  rate: Scalars["Int"];
};

export type ProjectEntity = {
  __typename?: "ProjectEntity";
  aboutUs?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  files: Array<FileEntity>;
  id: Scalars["ID"];
  isWithChart: Scalars["Boolean"];
  language: LanguageEnum;
  name: Scalars["String"];
  projectDevelopers: Array<ProjectDeveloperEntity>;
  projectEstimators: Array<UserEntity>;
  recommendations?: Maybe<Scalars["String"]>;
  sprints: Array<SprintEntity>;
  startedAt?: Maybe<Scalars["String"]>;
  /** Default value: ACTIVE */
  status: ProjectStatusEnum;
  totalHours: Scalars["Int"];
  updatedAt: Scalars["String"];
  user: UserEntity;
  userId: Scalars["String"];
};

export type ProjectEstimatorEntity = {
  __typename?: "ProjectEstimatorEntity";
  estimator: UserEntity;
  estimatorId: Scalars["ID"];
  project: ProjectEntity;
  projectId: Scalars["ID"];
};

/** Default value: ACTIVE */
export enum ProjectStatusEnum {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type ProjectsWithCountSchema = {
  __typename?: "ProjectsWithCountSchema";
  count: Scalars["Int"];
  projects: Array<ProjectEntity>;
};

export type Query = {
  __typename?: "Query";
  getDevelopersList: DevelopersListSchema;
  getFiles: FilesWithCountSchema;
  getLanguages: Array<LanguageType>;
  getPdfColors: PdfColorsEntity;
  getProject: ProjectEntity;
  getProjects: ProjectsWithCountSchema;
  getSettings?: Maybe<SettingsEntity>;
  getUser?: Maybe<UserEntity>;
  getUsers: UsersWithCountSchema;
  signin: AuthorizationResponseSchema;
};

export type QueryGetDevelopersListArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetFilesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  projectId: Scalars["Int"];
};

export type QueryGetProjectArgs = {
  projectId: Scalars["Int"];
};

export type QueryGetProjectsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUsersArgs = {
  input?: GetUsersInput;
};

export type QuerySigninArgs = {
  signinInput: SigninInput;
};

export type RemoveDeveloperSchema = {
  __typename?: "RemoveDeveloperSchema";
  developer?: Maybe<DeveloperEntity>;
  message?: Maybe<Scalars["String"]>;
  status: Scalars["Boolean"];
};

export type SettingsEntity = {
  __typename?: "SettingsEntity";
  defaultAboutUs: Scalars["String"];
  defaultComments: Scalars["String"];
  defaultRecommendations: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  mail: Scalars["String"];
  phone: Scalars["String"];
  slogan?: Maybe<SloganSchema>;
};

export type SigninInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type SloganInput = {
  en?: InputMaybe<Scalars["String"]>;
  ru?: InputMaybe<Scalars["String"]>;
  uk?: InputMaybe<Scalars["String"]>;
};

export type SloganSchema = {
  __typename?: "SloganSchema";
  en?: Maybe<Scalars["String"]>;
  ru?: Maybe<Scalars["String"]>;
  uk?: Maybe<Scalars["String"]>;
};

export type SortStoryInput = {
  sortOrder: Scalars["Int"];
  storyId: Scalars["Int"];
};

export type SortTaskInput = {
  sortOrder: Scalars["Int"];
  taskId: Scalars["Int"];
};

export type SprintEntity = {
  __typename?: "SprintEntity";
  id: Scalars["ID"];
  name: Scalars["String"];
  project: ProjectEntity;
  projectId: Scalars["Int"];
  sortOrder: Scalars["Int"];
  startedAt?: Maybe<Scalars["String"]>;
  storiesAndTasks: Array<StoryAndTaskType>;
  totalHours: Scalars["Int"];
};

export type StatusResponseSchema = {
  __typename?: "StatusResponseSchema";
  status: Scalars["Int"];
};

export type StoryAndTaskType = StoryEntity | TaskEntity;

export type StoryEntity = {
  __typename?: "StoryEntity";
  id: Scalars["ID"];
  name: Scalars["String"];
  sortOrder: Scalars["Int"];
  sprint: SprintEntity;
  sprintId: Scalars["Int"];
  startedAt?: Maybe<Scalars["String"]>;
  tasks: Array<TaskEntity>;
  totalHours: Scalars["Int"];
};

export type TaskEntity = {
  __typename?: "TaskEntity";
  createdAt: Scalars["String"];
  developer: DeveloperEntity;
  developerId: Scalars["Int"];
  duration: Scalars["Int"];
  id: Scalars["ID"];
  name: Scalars["String"];
  sortOrder: Scalars["Int"];
  sprint: SprintEntity;
  sprintId: Scalars["Int"];
  startedAt: Scalars["String"];
  story?: Maybe<StoryEntity>;
  storyId?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["String"];
};

export type UpdateDeveloperInput = {
  avatar: Scalars["String"];
  defaultRank: Scalars["String"];
  defaultRate: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  /** Default value: ACTIVE */
  status?: DeveloperStatusEnum;
};

export type UserEntity = {
  __typename?: "UserEntity";
  avatar?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberOfCreatedProjects: Scalars["Int"];
  /** Default value: MANAGER */
  role: UserRoleEnum;
  /** Default value: ACTIVE */
  status: UserStatusEnum;
  updatedAt: Scalars["String"];
};

/** Default value: MANAGER */
export enum UserRoleEnum {
  Admin = "ADMIN",
  Estimator = "ESTIMATOR",
  Manager = "MANAGER",
}

/** Default value: INACTIVE */
export enum UserStatusEnum {
  Active = "ACTIVE",
  Banned = "BANNED",
  Inactive = "INACTIVE",
}

export type UsersWithCountSchema = {
  __typename?: "UsersWithCountSchema";
  count: Scalars["Int"];
  users: Array<UserEntity>;
};

export type ChangeSettingsInput = {
  defaultAboutUs: Scalars["String"];
  defaultComments: Scalars["String"];
  defaultRecommendations: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  mail: Scalars["String"];
  phone: Scalars["String"];
  slogan?: InputMaybe<SloganInput>;
};

export type AttachDeveloperToProjectMutationVariables = Exact<{
  input: AttachDeveloperToProjectInput;
}>;

export type AttachDeveloperToProjectMutation = {
  __typename?: "Mutation";
  attachDeveloperToProject: {
    __typename?: "ProjectDeveloperEntity";
    projectId: string;
    developerId: string;
    rate: number;
    rank: string;
    color: string;
  };
};

export type ChangePdfColorsMutationVariables = Exact<{
  input: PdfColorsInput;
}>;

export type ChangePdfColorsMutation = {
  __typename?: "Mutation";
  changePdfColors: {
    __typename?: "PdfColorsEntity";
    id: string;
    headerBackground: string;
    headerColor: string;
    projectNameTextColor: string;
    estimateBackground: string;
    estimateTableBackground: string;
    estimateTableHeadBackground: string;
    estimateTableBodyBackground: string;
    estimateTableRowBackground: string;
    estimationHourColor: string;
    estimationTitleColor: string;
    estimationHeaderColor: string;
    estimationSprintColor: string;
    estimationStoryColor: string;
    estimationTaskColor: string;
    rateBackground: string;
    rateCardBackground: string;
    ratesTitleColor: string;
    ratesDevNameColor: string;
    ratesRankColor: string;
    ratesDevRateColor: string;
    paymentsBackground: string;
    paymentsTableBackground: string;
    paymentsTableBodyBackground: string;
    paymentsTableFooterBackground: string;
    paymentsTitleColor: string;
    paymentsDevAmountAndHoursColor: string;
    paymentsTextColor: string;
    paymentsRankColor: string;
    paymentsTotalTimeAndAmountTextColor: string;
    paymentsTotalTimeAndAmountBackground: string;
    paymentTotalPriceTextColor: string;
    paymentHeaderBackground: string;
    paymentPriceWrapperBackground: string;
    recommendationBackground: string;
    recommendationsTitleColor: string;
    recommendationsTextColor: string;
    teamBackground: string;
    teamTitleColor: string;
    teamRankColor: string;
    teamDevNameColor: string;
    commentsBackground: string;
    projectCommentsTitleColor: string;
    projectCommentsTextColor: string;
    aboutUsBackground: string;
    aboutUsTitleColor: string;
    aboutUsTextColor: string;
  };
};

export type ChangeProjectLanguageMutationVariables = Exact<{
  changeProjectLanguageId: Scalars["Int"];
  language: Scalars["String"];
}>;

export type ChangeProjectLanguageMutation = {
  __typename?: "Mutation";
  changeProjectLanguage: {
    __typename?: "ProjectEntity";
    id: string;
    language: LanguageEnum;
  };
};

export type ChangeSettingsMutationVariables = Exact<{
  input: ChangeSettingsInput;
}>;

export type ChangeSettingsMutation = {
  __typename?: "Mutation";
  changeSettings?: {
    __typename?: "SettingsEntity";
    defaultRecommendations: string;
    defaultComments: string;
    defaultAboutUs: string;
    mail: string;
    phone: string;
    link?: string | null;
    logo?: string | null;
    slogan?: {
      __typename?: "SloganSchema";
      en?: string | null;
      ru?: string | null;
      uk?: string | null;
    } | null;
  } | null;
};

export type ConfirmPasswordResetMutationVariables = Exact<{
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type ConfirmPasswordResetMutation = {
  __typename?: "Mutation";
  confirmPasswordReset: { __typename?: "StatusResponseSchema"; status: number };
};

export type CreateDeveloperMutationVariables = Exact<{
  input: CreateDeveloperInput;
}>;

export type CreateDeveloperMutation = {
  __typename?: "Mutation";
  createDeveloper: {
    __typename?: "DeveloperEntity";
    id: string;
    lastName: string;
    firstName: string;
    avatar?: string | null;
    status: DeveloperStatusEnum;
    defaultRate: number;
    defaultRank: string;
  };
};

export type CreateFileMutationVariables = Exact<{
  projectId: Scalars["Int"];
}>;

export type CreateFileMutation = {
  __typename?: "Mutation";
  createFile: {
    __typename?: "FileEntity";
    id: string;
    projectId: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type CreateFilePreviewMutationVariables = Exact<{
  projectId: Scalars["Int"];
}>;

export type CreateFilePreviewMutation = {
  __typename?: "Mutation";
  createFilePreview: string;
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject: {
    __typename?: "ProjectEntity";
    id: string;
    name: string;
    status: ProjectStatusEnum;
    isWithChart: boolean;
    recommendations?: string | null;
    comments?: string | null;
    aboutUs?: string | null;
  };
};

export type CreateSprintMutationVariables = Exact<{
  projectId: Scalars["Int"];
  input: CreateSprintInput;
}>;

export type CreateSprintMutation = {
  __typename?: "Mutation";
  createSprint: { __typename?: "SprintEntity"; id: string };
};

export type CreateStoryMutationVariables = Exact<{
  input: CreateStoryInput;
}>;

export type CreateStoryMutation = {
  __typename?: "Mutation";
  createStory: { __typename?: "StoryEntity"; id: string };
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutation";
  createTask: { __typename?: "TaskEntity"; id: string };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "UserEntity";
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
    status: UserStatusEnum;
    role: UserRoleEnum;
    createdAt: string;
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteProjectMutation = {
  __typename?: "Mutation";
  deleteProject: { __typename?: "StatusResponseSchema"; status: number };
};

export type DeleteSprintMutationVariables = Exact<{
  sprintId: Scalars["Int"];
}>;

export type DeleteSprintMutation = {
  __typename?: "Mutation";
  deleteSprint: { __typename?: "StatusResponseSchema"; status: number };
};

export type DeleteStoryMutationVariables = Exact<{
  storyId: Scalars["Int"];
}>;

export type DeleteStoryMutation = {
  __typename?: "Mutation";
  deleteStory: { __typename?: "StatusResponseSchema"; status: number };
};

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type DeleteTaskMutation = {
  __typename?: "Mutation";
  deleteTask: { __typename?: "StatusResponseSchema"; status: number };
};

export type DetachDeveloperFromProjectMutationVariables = Exact<{
  projectId: Scalars["Int"];
  developerId: Scalars["Int"];
}>;

export type DetachDeveloperFromProjectMutation = {
  __typename?: "Mutation";
  detachDeveloperFromProject: {
    __typename?: "StatusResponseSchema";
    status: number;
  };
};

export type EditAttachedDeveloperInProjectMutationVariables = Exact<{
  projectId: Scalars["Int"];
  developerId: Scalars["Int"];
  input: EditAttachedDeveloperInProjectInput;
}>;

export type EditAttachedDeveloperInProjectMutation = {
  __typename?: "Mutation";
  editAttachedDeveloperInProject: {
    __typename?: "ProjectDeveloperEntity";
    projectId: string;
    developerId: string;
    rate: number;
    rank: string;
    color: string;
  };
};

export type UpdateDeveloperMutationVariables = Exact<{
  input: UpdateDeveloperInput;
  id: Scalars["ID"];
}>;

export type UpdateDeveloperMutation = {
  __typename?: "Mutation";
  updateDeveloper: {
    __typename?: "DeveloperEntity";
    id: string;
    lastName: string;
    firstName: string;
    avatar?: string | null;
    status: DeveloperStatusEnum;
    defaultRate: number;
    defaultRank: string;
    createdAt: string;
  };
};

export type EditProjectMutationVariables = Exact<{
  editProjectId: Scalars["Int"];
  input: EditProjectInput;
}>;

export type EditProjectMutation = {
  __typename?: "Mutation";
  editProject: {
    __typename?: "ProjectEntity";
    id: string;
    name: string;
    status: ProjectStatusEnum;
    isWithChart: boolean;
    recommendations?: string | null;
    comments?: string | null;
    aboutUs?: string | null;
    user: {
      __typename?: "UserEntity";
      id: string;
      name: string;
      status: UserStatusEnum;
    };
    projectEstimators: Array<{
      __typename?: "UserEntity";
      id: string;
      name: string;
      status: UserStatusEnum;
    }>;
  };
};

export type EditSprintMutationVariables = Exact<{
  sprintId: Scalars["Int"];
  input: EditSprintInput;
}>;

export type EditSprintMutation = {
  __typename?: "Mutation";
  editSprint: { __typename?: "SprintEntity"; id: string; name: string };
};

export type EditStoryMutationVariables = Exact<{
  storyId: Scalars["Int"];
  input: EditStoryInput;
}>;

export type EditStoryMutation = {
  __typename?: "Mutation";
  editStory: { __typename?: "StoryEntity"; id: string; name: string };
};

export type EditTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
  input: EditTaskInput;
}>;

export type EditTaskMutation = {
  __typename?: "Mutation";
  editTask: { __typename?: "TaskEntity"; id: string };
};

export type EditUserMutationVariables = Exact<{
  input: EditUserInput;
}>;

export type EditUserMutation = {
  __typename?: "Mutation";
  editUser?: {
    __typename?: "UserEntity";
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
    status: UserStatusEnum;
    role: UserRoleEnum;
    createdAt: string;
  } | null;
};

export type MoveStoryMutationVariables = Exact<{
  input: MoveStoryInput;
}>;

export type MoveStoryMutation = {
  __typename?: "Mutation";
  moveStory: {
    __typename?: "ProjectEntity";
    id: string;
    aboutUs?: string | null;
    status: ProjectStatusEnum;
  };
};

export type MoveTaskMutationVariables = Exact<{
  input: MoveTaskInput;
}>;

export type MoveTaskMutation = {
  __typename?: "Mutation";
  moveTask: {
    __typename?: "ProjectEntity";
    id: string;
    sprints: Array<{
      __typename?: "SprintEntity";
      id: string;
      name: string;
      projectId: number;
      sortOrder: number;
      totalHours: number;
      startedAt?: string | null;
    }>;
  };
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: { __typename?: "StatusResponseSchema"; status: number };
};

export type RemoveDeveloperMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDeveloperMutation = {
  __typename?: "Mutation";
  removeDeveloper: {
    __typename?: "RemoveDeveloperSchema";
    status: boolean;
    message?: string | null;
  };
};

export type SortTaskMutationVariables = Exact<{
  input: SortTaskInput;
}>;

export type SortTaskMutation = {
  __typename?: "Mutation";
  sortTask: { __typename?: "SprintEntity"; id: string };
};

export type SprintSortingMutationVariables = Exact<{
  sprintIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type SprintSortingMutation = {
  __typename?: "Mutation";
  sprintSorting: { __typename?: "StatusResponseSchema"; status: number };
};

export type SortStoryMutationVariables = Exact<{
  input: SortStoryInput;
}>;

export type SortStoryMutation = {
  __typename?: "Mutation";
  sortStory: { __typename?: "SprintEntity"; id: string };
};

export type GetDevelopersListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetDevelopersListQuery = {
  __typename?: "Query";
  getDevelopersList: {
    __typename?: "DevelopersListSchema";
    count: number;
    developers: Array<{
      __typename?: "DeveloperEntity";
      id: string;
      firstName: string;
      lastName: string;
      defaultRank: string;
      createdAt: string;
      status: DeveloperStatusEnum;
      defaultRate: number;
      avatar?: string | null;
    }>;
  };
};

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLanguagesQuery = {
  __typename?: "Query";
  getLanguages: Array<{ __typename?: "LanguageType"; value: string }>;
};

export type GetPdfColorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPdfColorsQuery = {
  __typename?: "Query";
  getPdfColors: {
    __typename?: "PdfColorsEntity";
    id: string;
    headerBackground: string;
    headerColor: string;
    projectNameTextColor: string;
    estimateBackground: string;
    estimateTableBackground: string;
    estimateTableHeadBackground: string;
    estimateTableBodyBackground: string;
    estimateTableRowBackground: string;
    estimationHourColor: string;
    estimationTitleColor: string;
    estimationHeaderColor: string;
    estimationSprintColor: string;
    estimationStoryColor: string;
    estimationTaskColor: string;
    rateBackground: string;
    rateCardBackground: string;
    ratesTitleColor: string;
    ratesDevNameColor: string;
    ratesRankColor: string;
    ratesDevRateColor: string;
    paymentsBackground: string;
    paymentsTableBackground: string;
    paymentsTableBodyBackground: string;
    paymentsTableFooterBackground: string;
    paymentsTitleColor: string;
    paymentsDevAmountAndHoursColor: string;
    paymentsTextColor: string;
    paymentsRankColor: string;
    paymentsTotalTimeAndAmountTextColor: string;
    paymentsTotalTimeAndAmountBackground: string;
    paymentTotalPriceTextColor: string;
    paymentHeaderBackground: string;
    paymentPriceWrapperBackground: string;
    recommendationBackground: string;
    recommendationsTitleColor: string;
    recommendationsTextColor: string;
    teamBackground: string;
    teamTitleColor: string;
    teamRankColor: string;
    teamDevNameColor: string;
    commentsBackground: string;
    projectCommentsTitleColor: string;
    projectCommentsTextColor: string;
    aboutUsBackground: string;
    aboutUsTitleColor: string;
    aboutUsTextColor: string;
  };
};

export type GetProjectCustomQueryVariables = Exact<{
  projectId: Scalars["Int"];
}>;

export type GetProjectCustomQuery = {
  __typename?: "Query";
  getProject: {
    __typename?: "ProjectEntity";
    id: string;
    name: string;
    status: ProjectStatusEnum;
    isWithChart: boolean;
    recommendations?: string | null;
    comments?: string | null;
    aboutUs?: string | null;
    language: LanguageEnum;
    files: Array<{
      __typename?: "FileEntity";
      id: string;
      name: string;
      createdAt: string;
    }>;
    projectDevelopers: Array<{
      __typename?: "ProjectDeveloperEntity";
      projectId: string;
      developerId: string;
      rate: number;
      rank: string;
      color: string;
      developer: {
        __typename?: "DeveloperEntity";
        id: string;
        lastName: string;
        firstName: string;
        defaultRank: string;
        defaultRate: number;
      };
    }>;
    sprints: Array<{
      __typename?: "SprintEntity";
      id: string;
      name: string;
      sortOrder: number;
      totalHours: number;
      startedAt?: string | null;
      storiesAndTasks: Array<
        | {
            __typename?: "StoryEntity";
            id: string;
            name: string;
            sprintId: number;
            sortOrder: number;
            totalHours: number;
            startedAtStory?: string | null;
            tasks: Array<{
              __typename?: "TaskEntity";
              id: string;
              name: string;
              sprintId: number;
              storyId?: number | null;
              developerId: number;
              startedAt: string;
              sortOrder: number;
              createdAt: string;
              updatedAt: string;
              duration: number;
              developer: {
                __typename?: "DeveloperEntity";
                defaultRate: number;
                defaultRank: string;
                id: string;
                lastName: string;
                firstName: string;
                avatar?: string | null;
              };
            }>;
          }
        | {
            __typename?: "TaskEntity";
            id: string;
            name: string;
            sprintId: number;
            storyId?: number | null;
            developerId: number;
            startedAt: string;
            sortOrder: number;
            createdAt: string;
            updatedAt: string;
            duration: number;
            developer: {
              __typename?: "DeveloperEntity";
              defaultRate: number;
              defaultRank: string;
              id: string;
              lastName: string;
              firstName: string;
              avatar?: string | null;
            };
          }
      >;
    }>;
    user: {
      __typename?: "UserEntity";
      id: string;
      name: string;
      status: UserStatusEnum;
    };
    projectEstimators: Array<{
      __typename?: "UserEntity";
      id: string;
      name: string;
      status: UserStatusEnum;
    }>;
  };
};

export type GetProjectsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetProjectsQuery = {
  __typename?: "Query";
  getProjects: {
    __typename?: "ProjectsWithCountSchema";
    count: number;
    projects: Array<{
      __typename?: "ProjectEntity";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      status: ProjectStatusEnum;
      totalHours: number;
      isWithChart: boolean;
      recommendations?: string | null;
      comments?: string | null;
      aboutUs?: string | null;
      projectDevelopers: Array<{
        __typename?: "ProjectDeveloperEntity";
        developer: {
          __typename?: "DeveloperEntity";
          id: string;
          lastName: string;
          firstName: string;
          defaultRate: number;
          defaultRank: string;
        };
      }>;
      user: {
        __typename?: "UserEntity";
        id: string;
        email: string;
        name: string;
        avatar?: string | null;
        status: UserStatusEnum;
        role: UserRoleEnum;
        createdAt: string;
        updatedAt: string;
        numberOfCreatedProjects: number;
      };
    }>;
  };
};

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSettingsQuery = {
  __typename?: "Query";
  getSettings?: {
    __typename?: "SettingsEntity";
    defaultRecommendations: string;
    defaultComments: string;
    defaultAboutUs: string;
    mail: string;
    phone: string;
    link?: string | null;
    logo?: string | null;
    slogan?: {
      __typename?: "SloganSchema";
      en?: string | null;
      ru?: string | null;
      uk?: string | null;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "UserEntity";
    id: string;
    role: UserRoleEnum;
    name: string;
    email: string;
    avatar?: string | null;
    status: UserStatusEnum;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetUsersQuery = {
  __typename?: "Query";
  getUsers: {
    __typename?: "UsersWithCountSchema";
    count: number;
    users: Array<{
      __typename?: "UserEntity";
      id: string;
      email: string;
      name: string;
      avatar?: string | null;
      status: UserStatusEnum;
      role: UserRoleEnum;
      createdAt: string;
      updatedAt: string;
      numberOfCreatedProjects: number;
    }>;
  };
};

export type SigninQueryVariables = Exact<{
  signinInput: SigninInput;
}>;

export type SigninQuery = {
  __typename?: "Query";
  signin: {
    __typename?: "AuthorizationResponseSchema";
    accessToken: string;
    user?: { __typename?: "UserEntity"; name: string } | null;
  };
};

export const AttachDeveloperToProjectDocument = gql`
  mutation AttachDeveloperToProject($input: AttachDeveloperToProjectInput!) {
    attachDeveloperToProject(input: $input) {
      projectId
      developerId
      rate
      rank
      color
    }
  }
`;
export type AttachDeveloperToProjectMutationFn = Apollo.MutationFunction<
  AttachDeveloperToProjectMutation,
  AttachDeveloperToProjectMutationVariables
>;

/**
 * __useAttachDeveloperToProjectMutation__
 *
 * To run a mutation, you first call `useAttachDeveloperToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachDeveloperToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachDeveloperToProjectMutation, { data, loading, error }] = useAttachDeveloperToProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachDeveloperToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachDeveloperToProjectMutation,
    AttachDeveloperToProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachDeveloperToProjectMutation,
    AttachDeveloperToProjectMutationVariables
  >(AttachDeveloperToProjectDocument, options);
}
export type AttachDeveloperToProjectMutationHookResult = ReturnType<
  typeof useAttachDeveloperToProjectMutation
>;
export type AttachDeveloperToProjectMutationResult =
  Apollo.MutationResult<AttachDeveloperToProjectMutation>;
export type AttachDeveloperToProjectMutationOptions =
  Apollo.BaseMutationOptions<
    AttachDeveloperToProjectMutation,
    AttachDeveloperToProjectMutationVariables
  >;
export const ChangePdfColorsDocument = gql`
  mutation ChangePdfColors($input: PdfColorsInput!) {
    changePdfColors(input: $input) {
      id
      headerBackground
      headerColor
      projectNameTextColor
      estimateBackground
      estimateTableBackground
      estimateTableHeadBackground
      estimateTableBodyBackground
      estimateTableRowBackground
      estimationHourColor
      estimationTitleColor
      estimationHeaderColor
      estimationSprintColor
      estimationStoryColor
      estimationTaskColor
      rateBackground
      rateCardBackground
      ratesTitleColor
      ratesDevNameColor
      ratesRankColor
      ratesDevRateColor
      paymentsBackground
      paymentsTableBackground
      paymentsTableBodyBackground
      paymentsTableFooterBackground
      paymentsTitleColor
      paymentsDevAmountAndHoursColor
      paymentsTextColor
      paymentsRankColor
      paymentsTotalTimeAndAmountTextColor
      paymentsTotalTimeAndAmountBackground
      paymentTotalPriceTextColor
      paymentHeaderBackground
      paymentPriceWrapperBackground
      recommendationBackground
      recommendationsTitleColor
      recommendationsTextColor
      teamBackground
      teamTitleColor
      teamRankColor
      teamDevNameColor
      commentsBackground
      projectCommentsTitleColor
      projectCommentsTextColor
      aboutUsBackground
      aboutUsTitleColor
      aboutUsTextColor
    }
  }
`;
export type ChangePdfColorsMutationFn = Apollo.MutationFunction<
  ChangePdfColorsMutation,
  ChangePdfColorsMutationVariables
>;

/**
 * __useChangePdfColorsMutation__
 *
 * To run a mutation, you first call `useChangePdfColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePdfColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePdfColorsMutation, { data, loading, error }] = useChangePdfColorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePdfColorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePdfColorsMutation,
    ChangePdfColorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePdfColorsMutation,
    ChangePdfColorsMutationVariables
  >(ChangePdfColorsDocument, options);
}
export type ChangePdfColorsMutationHookResult = ReturnType<
  typeof useChangePdfColorsMutation
>;
export type ChangePdfColorsMutationResult =
  Apollo.MutationResult<ChangePdfColorsMutation>;
export type ChangePdfColorsMutationOptions = Apollo.BaseMutationOptions<
  ChangePdfColorsMutation,
  ChangePdfColorsMutationVariables
>;
export const ChangeProjectLanguageDocument = gql`
  mutation ChangeProjectLanguage(
    $changeProjectLanguageId: Int!
    $language: String!
  ) {
    changeProjectLanguage(id: $changeProjectLanguageId, language: $language) {
      id
      language
    }
  }
`;
export type ChangeProjectLanguageMutationFn = Apollo.MutationFunction<
  ChangeProjectLanguageMutation,
  ChangeProjectLanguageMutationVariables
>;

/**
 * __useChangeProjectLanguageMutation__
 *
 * To run a mutation, you first call `useChangeProjectLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectLanguageMutation, { data, loading, error }] = useChangeProjectLanguageMutation({
 *   variables: {
 *      changeProjectLanguageId: // value for 'changeProjectLanguageId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useChangeProjectLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeProjectLanguageMutation,
    ChangeProjectLanguageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeProjectLanguageMutation,
    ChangeProjectLanguageMutationVariables
  >(ChangeProjectLanguageDocument, options);
}
export type ChangeProjectLanguageMutationHookResult = ReturnType<
  typeof useChangeProjectLanguageMutation
>;
export type ChangeProjectLanguageMutationResult =
  Apollo.MutationResult<ChangeProjectLanguageMutation>;
export type ChangeProjectLanguageMutationOptions = Apollo.BaseMutationOptions<
  ChangeProjectLanguageMutation,
  ChangeProjectLanguageMutationVariables
>;
export const ChangeSettingsDocument = gql`
  mutation changeSettings($input: changeSettingsInput!) {
    changeSettings(input: $input) {
      defaultRecommendations
      defaultComments
      defaultAboutUs
      mail
      phone
      link
      logo
      slogan {
        en
        ru
        uk
      }
    }
  }
`;
export type ChangeSettingsMutationFn = Apollo.MutationFunction<
  ChangeSettingsMutation,
  ChangeSettingsMutationVariables
>;

/**
 * __useChangeSettingsMutation__
 *
 * To run a mutation, you first call `useChangeSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSettingsMutation, { data, loading, error }] = useChangeSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSettingsMutation,
    ChangeSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeSettingsMutation,
    ChangeSettingsMutationVariables
  >(ChangeSettingsDocument, options);
}
export type ChangeSettingsMutationHookResult = ReturnType<
  typeof useChangeSettingsMutation
>;
export type ChangeSettingsMutationResult =
  Apollo.MutationResult<ChangeSettingsMutation>;
export type ChangeSettingsMutationOptions = Apollo.BaseMutationOptions<
  ChangeSettingsMutation,
  ChangeSettingsMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
  mutation confirmPasswordReset($token: String!, $password: String!) {
    confirmPasswordReset(token: $token, password: $password) {
      status
    }
  }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >(ConfirmPasswordResetDocument, options);
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<
  typeof useConfirmPasswordResetMutation
>;
export type ConfirmPasswordResetMutationResult =
  Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export const CreateDeveloperDocument = gql`
  mutation createDeveloper($input: CreateDeveloperInput!) {
    createDeveloper(input: $input) {
      id
      lastName
      firstName
      avatar
      status
      defaultRate
      defaultRank
    }
  }
`;
export type CreateDeveloperMutationFn = Apollo.MutationFunction<
  CreateDeveloperMutation,
  CreateDeveloperMutationVariables
>;

/**
 * __useCreateDeveloperMutation__
 *
 * To run a mutation, you first call `useCreateDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeveloperMutation, { data, loading, error }] = useCreateDeveloperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeveloperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeveloperMutation,
    CreateDeveloperMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeveloperMutation,
    CreateDeveloperMutationVariables
  >(CreateDeveloperDocument, options);
}
export type CreateDeveloperMutationHookResult = ReturnType<
  typeof useCreateDeveloperMutation
>;
export type CreateDeveloperMutationResult =
  Apollo.MutationResult<CreateDeveloperMutation>;
export type CreateDeveloperMutationOptions = Apollo.BaseMutationOptions<
  CreateDeveloperMutation,
  CreateDeveloperMutationVariables
>;
export const CreateFileDocument = gql`
  mutation createFile($projectId: Int!) {
    createFile(projectId: $projectId) {
      id
      projectId
      name
      createdAt
      updatedAt
    }
  }
`;
export type CreateFileMutationFn = Apollo.MutationFunction<
  CreateFileMutation,
  CreateFileMutationVariables
>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileMutation,
    CreateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument,
    options
  );
}
export type CreateFileMutationHookResult = ReturnType<
  typeof useCreateFileMutation
>;
export type CreateFileMutationResult =
  Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFileMutation,
  CreateFileMutationVariables
>;
export const CreateFilePreviewDocument = gql`
  mutation createFilePreview($projectId: Int!) {
    createFilePreview(projectId: $projectId)
  }
`;
export type CreateFilePreviewMutationFn = Apollo.MutationFunction<
  CreateFilePreviewMutation,
  CreateFilePreviewMutationVariables
>;

/**
 * __useCreateFilePreviewMutation__
 *
 * To run a mutation, you first call `useCreateFilePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilePreviewMutation, { data, loading, error }] = useCreateFilePreviewMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateFilePreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFilePreviewMutation,
    CreateFilePreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFilePreviewMutation,
    CreateFilePreviewMutationVariables
  >(CreateFilePreviewDocument, options);
}
export type CreateFilePreviewMutationHookResult = ReturnType<
  typeof useCreateFilePreviewMutation
>;
export type CreateFilePreviewMutationResult =
  Apollo.MutationResult<CreateFilePreviewMutation>;
export type CreateFilePreviewMutationOptions = Apollo.BaseMutationOptions<
  CreateFilePreviewMutation,
  CreateFilePreviewMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      name
      status
      isWithChart
      recommendations
      comments
      aboutUs
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateSprintDocument = gql`
  mutation createSprint($projectId: Int!, $input: CreateSprintInput!) {
    createSprint(projectId: $projectId, input: $input) {
      id
    }
  }
`;
export type CreateSprintMutationFn = Apollo.MutationFunction<
  CreateSprintMutation,
  CreateSprintMutationVariables
>;

/**
 * __useCreateSprintMutation__
 *
 * To run a mutation, you first call `useCreateSprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSprintMutation, { data, loading, error }] = useCreateSprintMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSprintMutation,
    CreateSprintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSprintMutation,
    CreateSprintMutationVariables
  >(CreateSprintDocument, options);
}
export type CreateSprintMutationHookResult = ReturnType<
  typeof useCreateSprintMutation
>;
export type CreateSprintMutationResult =
  Apollo.MutationResult<CreateSprintMutation>;
export type CreateSprintMutationOptions = Apollo.BaseMutationOptions<
  CreateSprintMutation,
  CreateSprintMutationVariables
>;
export const CreateStoryDocument = gql`
  mutation createStory($input: CreateStoryInput!) {
    createStory(input: $input) {
      id
    }
  }
`;
export type CreateStoryMutationFn = Apollo.MutationFunction<
  CreateStoryMutation,
  CreateStoryMutationVariables
>;

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStoryMutation,
    CreateStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStoryMutation, CreateStoryMutationVariables>(
    CreateStoryDocument,
    options
  );
}
export type CreateStoryMutationHookResult = ReturnType<
  typeof useCreateStoryMutation
>;
export type CreateStoryMutationResult =
  Apollo.MutationResult<CreateStoryMutation>;
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<
  CreateStoryMutation,
  CreateStoryMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      email
      avatar
      status
      role
      createdAt
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: Int!) {
    deleteProject(id: $id) {
      status
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteSprintDocument = gql`
  mutation deleteSprint($sprintId: Int!) {
    deleteSprint(sprintId: $sprintId) {
      status
    }
  }
`;
export type DeleteSprintMutationFn = Apollo.MutationFunction<
  DeleteSprintMutation,
  DeleteSprintMutationVariables
>;

/**
 * __useDeleteSprintMutation__
 *
 * To run a mutation, you first call `useDeleteSprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSprintMutation, { data, loading, error }] = useDeleteSprintMutation({
 *   variables: {
 *      sprintId: // value for 'sprintId'
 *   },
 * });
 */
export function useDeleteSprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSprintMutation,
    DeleteSprintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSprintMutation,
    DeleteSprintMutationVariables
  >(DeleteSprintDocument, options);
}
export type DeleteSprintMutationHookResult = ReturnType<
  typeof useDeleteSprintMutation
>;
export type DeleteSprintMutationResult =
  Apollo.MutationResult<DeleteSprintMutation>;
export type DeleteSprintMutationOptions = Apollo.BaseMutationOptions<
  DeleteSprintMutation,
  DeleteSprintMutationVariables
>;
export const DeleteStoryDocument = gql`
  mutation deleteStory($storyId: Int!) {
    deleteStory(storyId: $storyId) {
      status
    }
  }
`;
export type DeleteStoryMutationFn = Apollo.MutationFunction<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useDeleteStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(
    DeleteStoryDocument,
    options
  );
}
export type DeleteStoryMutationHookResult = ReturnType<
  typeof useDeleteStoryMutation
>;
export type DeleteStoryMutationResult =
  Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($taskId: Int!) {
    deleteTask(taskId: $taskId) {
      status
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DetachDeveloperFromProjectDocument = gql`
  mutation DetachDeveloperFromProject($projectId: Int!, $developerId: Int!) {
    detachDeveloperFromProject(
      projectId: $projectId
      developerId: $developerId
    ) {
      status
    }
  }
`;
export type DetachDeveloperFromProjectMutationFn = Apollo.MutationFunction<
  DetachDeveloperFromProjectMutation,
  DetachDeveloperFromProjectMutationVariables
>;

/**
 * __useDetachDeveloperFromProjectMutation__
 *
 * To run a mutation, you first call `useDetachDeveloperFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachDeveloperFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachDeveloperFromProjectMutation, { data, loading, error }] = useDetachDeveloperFromProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      developerId: // value for 'developerId'
 *   },
 * });
 */
export function useDetachDeveloperFromProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachDeveloperFromProjectMutation,
    DetachDeveloperFromProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachDeveloperFromProjectMutation,
    DetachDeveloperFromProjectMutationVariables
  >(DetachDeveloperFromProjectDocument, options);
}
export type DetachDeveloperFromProjectMutationHookResult = ReturnType<
  typeof useDetachDeveloperFromProjectMutation
>;
export type DetachDeveloperFromProjectMutationResult =
  Apollo.MutationResult<DetachDeveloperFromProjectMutation>;
export type DetachDeveloperFromProjectMutationOptions =
  Apollo.BaseMutationOptions<
    DetachDeveloperFromProjectMutation,
    DetachDeveloperFromProjectMutationVariables
  >;
export const EditAttachedDeveloperInProjectDocument = gql`
  mutation EditAttachedDeveloperInProject(
    $projectId: Int!
    $developerId: Int!
    $input: EditAttachedDeveloperInProjectInput!
  ) {
    editAttachedDeveloperInProject(
      projectId: $projectId
      developerId: $developerId
      input: $input
    ) {
      projectId
      developerId
      rate
      rank
      color
    }
  }
`;
export type EditAttachedDeveloperInProjectMutationFn = Apollo.MutationFunction<
  EditAttachedDeveloperInProjectMutation,
  EditAttachedDeveloperInProjectMutationVariables
>;

/**
 * __useEditAttachedDeveloperInProjectMutation__
 *
 * To run a mutation, you first call `useEditAttachedDeveloperInProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAttachedDeveloperInProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAttachedDeveloperInProjectMutation, { data, loading, error }] = useEditAttachedDeveloperInProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      developerId: // value for 'developerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAttachedDeveloperInProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAttachedDeveloperInProjectMutation,
    EditAttachedDeveloperInProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditAttachedDeveloperInProjectMutation,
    EditAttachedDeveloperInProjectMutationVariables
  >(EditAttachedDeveloperInProjectDocument, options);
}
export type EditAttachedDeveloperInProjectMutationHookResult = ReturnType<
  typeof useEditAttachedDeveloperInProjectMutation
>;
export type EditAttachedDeveloperInProjectMutationResult =
  Apollo.MutationResult<EditAttachedDeveloperInProjectMutation>;
export type EditAttachedDeveloperInProjectMutationOptions =
  Apollo.BaseMutationOptions<
    EditAttachedDeveloperInProjectMutation,
    EditAttachedDeveloperInProjectMutationVariables
  >;
export const UpdateDeveloperDocument = gql`
  mutation updateDeveloper($input: UpdateDeveloperInput!, $id: ID!) {
    updateDeveloper(input: $input, id: $id) {
      id
      lastName
      firstName
      avatar
      status
      defaultRate
      defaultRank
      createdAt
    }
  }
`;
export type UpdateDeveloperMutationFn = Apollo.MutationFunction<
  UpdateDeveloperMutation,
  UpdateDeveloperMutationVariables
>;

/**
 * __useUpdateDeveloperMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperMutation, { data, loading, error }] = useUpdateDeveloperMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeveloperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeveloperMutation,
    UpdateDeveloperMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDeveloperMutation,
    UpdateDeveloperMutationVariables
  >(UpdateDeveloperDocument, options);
}
export type UpdateDeveloperMutationHookResult = ReturnType<
  typeof useUpdateDeveloperMutation
>;
export type UpdateDeveloperMutationResult =
  Apollo.MutationResult<UpdateDeveloperMutation>;
export type UpdateDeveloperMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeveloperMutation,
  UpdateDeveloperMutationVariables
>;
export const EditProjectDocument = gql`
  mutation EditProject($editProjectId: Int!, $input: EditProjectInput!) {
    editProject(id: $editProjectId, input: $input) {
      id
      name
      status
      isWithChart
      recommendations
      comments
      aboutUs
      user {
        id
        name
        status
      }
      projectEstimators {
        id
        name
        status
      }
    }
  }
`;
export type EditProjectMutationFn = Apollo.MutationFunction<
  EditProjectMutation,
  EditProjectMutationVariables
>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      editProjectId: // value for 'editProjectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProjectMutation,
    EditProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProjectMutation, EditProjectMutationVariables>(
    EditProjectDocument,
    options
  );
}
export type EditProjectMutationHookResult = ReturnType<
  typeof useEditProjectMutation
>;
export type EditProjectMutationResult =
  Apollo.MutationResult<EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<
  EditProjectMutation,
  EditProjectMutationVariables
>;
export const EditSprintDocument = gql`
  mutation editSprint($sprintId: Int!, $input: EditSprintInput!) {
    editSprint(sprintId: $sprintId, input: $input) {
      id
      name
    }
  }
`;
export type EditSprintMutationFn = Apollo.MutationFunction<
  EditSprintMutation,
  EditSprintMutationVariables
>;

/**
 * __useEditSprintMutation__
 *
 * To run a mutation, you first call `useEditSprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSprintMutation, { data, loading, error }] = useEditSprintMutation({
 *   variables: {
 *      sprintId: // value for 'sprintId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSprintMutation,
    EditSprintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditSprintMutation, EditSprintMutationVariables>(
    EditSprintDocument,
    options
  );
}
export type EditSprintMutationHookResult = ReturnType<
  typeof useEditSprintMutation
>;
export type EditSprintMutationResult =
  Apollo.MutationResult<EditSprintMutation>;
export type EditSprintMutationOptions = Apollo.BaseMutationOptions<
  EditSprintMutation,
  EditSprintMutationVariables
>;
export const EditStoryDocument = gql`
  mutation editStory($storyId: Int!, $input: EditStoryInput!) {
    editStory(storyId: $storyId, input: $input) {
      id
      name
    }
  }
`;
export type EditStoryMutationFn = Apollo.MutationFunction<
  EditStoryMutation,
  EditStoryMutationVariables
>;

/**
 * __useEditStoryMutation__
 *
 * To run a mutation, you first call `useEditStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStoryMutation, { data, loading, error }] = useEditStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditStoryMutation,
    EditStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditStoryMutation, EditStoryMutationVariables>(
    EditStoryDocument,
    options
  );
}
export type EditStoryMutationHookResult = ReturnType<
  typeof useEditStoryMutation
>;
export type EditStoryMutationResult = Apollo.MutationResult<EditStoryMutation>;
export type EditStoryMutationOptions = Apollo.BaseMutationOptions<
  EditStoryMutation,
  EditStoryMutationVariables
>;
export const EditTaskDocument = gql`
  mutation editTask($taskId: Int!, $input: EditTaskInput!) {
    editTask(taskId: $taskId, input: $input) {
      id
    }
  }
`;
export type EditTaskMutationFn = Apollo.MutationFunction<
  EditTaskMutation,
  EditTaskMutationVariables
>;

/**
 * __useEditTaskMutation__
 *
 * To run a mutation, you first call `useEditTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskMutation, { data, loading, error }] = useEditTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTaskMutation,
    EditTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditTaskMutation, EditTaskMutationVariables>(
    EditTaskDocument,
    options
  );
}
export type EditTaskMutationHookResult = ReturnType<typeof useEditTaskMutation>;
export type EditTaskMutationResult = Apollo.MutationResult<EditTaskMutation>;
export type EditTaskMutationOptions = Apollo.BaseMutationOptions<
  EditTaskMutation,
  EditTaskMutationVariables
>;
export const EditUserDocument = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      id
      name
      email
      avatar
      status
      role
      createdAt
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserMutation,
    EditUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
    EditUserDocument,
    options
  );
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const MoveStoryDocument = gql`
  mutation MoveStory($input: MoveStoryInput!) {
    moveStory(input: $input) {
      id
      aboutUs
      status
    }
  }
`;
export type MoveStoryMutationFn = Apollo.MutationFunction<
  MoveStoryMutation,
  MoveStoryMutationVariables
>;

/**
 * __useMoveStoryMutation__
 *
 * To run a mutation, you first call `useMoveStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveStoryMutation, { data, loading, error }] = useMoveStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveStoryMutation,
    MoveStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveStoryMutation, MoveStoryMutationVariables>(
    MoveStoryDocument,
    options
  );
}
export type MoveStoryMutationHookResult = ReturnType<
  typeof useMoveStoryMutation
>;
export type MoveStoryMutationResult = Apollo.MutationResult<MoveStoryMutation>;
export type MoveStoryMutationOptions = Apollo.BaseMutationOptions<
  MoveStoryMutation,
  MoveStoryMutationVariables
>;
export const MoveTaskDocument = gql`
  mutation MoveTask($input: MoveTaskInput!) {
    moveTask(input: $input) {
      id
      sprints {
        id
        name
        projectId
        sortOrder
        totalHours
        startedAt
      }
    }
  }
`;
export type MoveTaskMutationFn = Apollo.MutationFunction<
  MoveTaskMutation,
  MoveTaskMutationVariables
>;

/**
 * __useMoveTaskMutation__
 *
 * To run a mutation, you first call `useMoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveTaskMutation, { data, loading, error }] = useMoveTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveTaskMutation,
    MoveTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveTaskMutation, MoveTaskMutationVariables>(
    MoveTaskDocument,
    options
  );
}
export type MoveTaskMutationHookResult = ReturnType<typeof useMoveTaskMutation>;
export type MoveTaskMutationResult = Apollo.MutationResult<MoveTaskMutation>;
export type MoveTaskMutationOptions = Apollo.BaseMutationOptions<
  MoveTaskMutation,
  MoveTaskMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      status
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const RemoveDeveloperDocument = gql`
  mutation removeDeveloper($id: ID!) {
    removeDeveloper(developerId: $id) {
      status
      message
    }
  }
`;
export type RemoveDeveloperMutationFn = Apollo.MutationFunction<
  RemoveDeveloperMutation,
  RemoveDeveloperMutationVariables
>;

/**
 * __useRemoveDeveloperMutation__
 *
 * To run a mutation, you first call `useRemoveDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeveloperMutation, { data, loading, error }] = useRemoveDeveloperMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDeveloperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDeveloperMutation,
    RemoveDeveloperMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDeveloperMutation,
    RemoveDeveloperMutationVariables
  >(RemoveDeveloperDocument, options);
}
export type RemoveDeveloperMutationHookResult = ReturnType<
  typeof useRemoveDeveloperMutation
>;
export type RemoveDeveloperMutationResult =
  Apollo.MutationResult<RemoveDeveloperMutation>;
export type RemoveDeveloperMutationOptions = Apollo.BaseMutationOptions<
  RemoveDeveloperMutation,
  RemoveDeveloperMutationVariables
>;
export const SortTaskDocument = gql`
  mutation SortTask($input: SortTaskInput!) {
    sortTask(input: $input) {
      id
    }
  }
`;
export type SortTaskMutationFn = Apollo.MutationFunction<
  SortTaskMutation,
  SortTaskMutationVariables
>;

/**
 * __useSortTaskMutation__
 *
 * To run a mutation, you first call `useSortTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortTaskMutation, { data, loading, error }] = useSortTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortTaskMutation,
    SortTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortTaskMutation, SortTaskMutationVariables>(
    SortTaskDocument,
    options
  );
}
export type SortTaskMutationHookResult = ReturnType<typeof useSortTaskMutation>;
export type SortTaskMutationResult = Apollo.MutationResult<SortTaskMutation>;
export type SortTaskMutationOptions = Apollo.BaseMutationOptions<
  SortTaskMutation,
  SortTaskMutationVariables
>;
export const SprintSortingDocument = gql`
  mutation sprintSorting($sprintIds: [Int!]!) {
    sprintSorting(sprintIds: $sprintIds) {
      status
    }
  }
`;
export type SprintSortingMutationFn = Apollo.MutationFunction<
  SprintSortingMutation,
  SprintSortingMutationVariables
>;

/**
 * __useSprintSortingMutation__
 *
 * To run a mutation, you first call `useSprintSortingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSprintSortingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sprintSortingMutation, { data, loading, error }] = useSprintSortingMutation({
 *   variables: {
 *      sprintIds: // value for 'sprintIds'
 *   },
 * });
 */
export function useSprintSortingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SprintSortingMutation,
    SprintSortingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SprintSortingMutation,
    SprintSortingMutationVariables
  >(SprintSortingDocument, options);
}
export type SprintSortingMutationHookResult = ReturnType<
  typeof useSprintSortingMutation
>;
export type SprintSortingMutationResult =
  Apollo.MutationResult<SprintSortingMutation>;
export type SprintSortingMutationOptions = Apollo.BaseMutationOptions<
  SprintSortingMutation,
  SprintSortingMutationVariables
>;
export const SortStoryDocument = gql`
  mutation sortStory($input: SortStoryInput!) {
    sortStory(input: $input) {
      id
    }
  }
`;
export type SortStoryMutationFn = Apollo.MutationFunction<
  SortStoryMutation,
  SortStoryMutationVariables
>;

/**
 * __useSortStoryMutation__
 *
 * To run a mutation, you first call `useSortStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortStoryMutation, { data, loading, error }] = useSortStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortStoryMutation,
    SortStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortStoryMutation, SortStoryMutationVariables>(
    SortStoryDocument,
    options
  );
}
export type SortStoryMutationHookResult = ReturnType<
  typeof useSortStoryMutation
>;
export type SortStoryMutationResult = Apollo.MutationResult<SortStoryMutation>;
export type SortStoryMutationOptions = Apollo.BaseMutationOptions<
  SortStoryMutation,
  SortStoryMutationVariables
>;
export const GetDevelopersListDocument = gql`
  query getDevelopersList($offset: Int, $limit: Int) {
    getDevelopersList(offset: $offset, limit: $limit) {
      developers {
        id
        firstName
        lastName
        defaultRank
        createdAt
        status
        defaultRate
        avatar
      }
      count
    }
  }
`;

/**
 * __useGetDevelopersListQuery__
 *
 * To run a query within a React component, call `useGetDevelopersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevelopersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevelopersListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDevelopersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDevelopersListQuery,
    GetDevelopersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDevelopersListQuery,
    GetDevelopersListQueryVariables
  >(GetDevelopersListDocument, options);
}
export function useGetDevelopersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDevelopersListQuery,
    GetDevelopersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDevelopersListQuery,
    GetDevelopersListQueryVariables
  >(GetDevelopersListDocument, options);
}
export type GetDevelopersListQueryHookResult = ReturnType<
  typeof useGetDevelopersListQuery
>;
export type GetDevelopersListLazyQueryHookResult = ReturnType<
  typeof useGetDevelopersListLazyQuery
>;
export type GetDevelopersListQueryResult = Apollo.QueryResult<
  GetDevelopersListQuery,
  GetDevelopersListQueryVariables
>;
export const GetLanguagesDocument = gql`
  query GetLanguages {
    getLanguages {
      value
    }
  }
`;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLanguagesQuery,
    GetLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    options
  );
}
export function useGetLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLanguagesQuery,
    GetLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    options
  );
}
export type GetLanguagesQueryHookResult = ReturnType<
  typeof useGetLanguagesQuery
>;
export type GetLanguagesLazyQueryHookResult = ReturnType<
  typeof useGetLanguagesLazyQuery
>;
export type GetLanguagesQueryResult = Apollo.QueryResult<
  GetLanguagesQuery,
  GetLanguagesQueryVariables
>;
export const GetPdfColorsDocument = gql`
  query GetPdfColors {
    getPdfColors {
      id
      headerBackground
      headerColor
      projectNameTextColor
      estimateBackground
      estimateTableBackground
      estimateTableHeadBackground
      estimateTableBodyBackground
      estimateTableRowBackground
      estimationHourColor
      estimationTitleColor
      estimationHeaderColor
      estimationSprintColor
      estimationStoryColor
      estimationTaskColor
      rateBackground
      rateCardBackground
      ratesTitleColor
      ratesDevNameColor
      ratesRankColor
      ratesDevRateColor
      paymentsBackground
      paymentsTableBackground
      paymentsTableBodyBackground
      paymentsTableFooterBackground
      paymentsTitleColor
      paymentsDevAmountAndHoursColor
      paymentsTextColor
      paymentsRankColor
      paymentsTotalTimeAndAmountTextColor
      paymentsTotalTimeAndAmountBackground
      paymentTotalPriceTextColor
      paymentHeaderBackground
      paymentPriceWrapperBackground
      recommendationBackground
      recommendationsTitleColor
      recommendationsTextColor
      teamBackground
      teamTitleColor
      teamRankColor
      teamDevNameColor
      commentsBackground
      projectCommentsTitleColor
      projectCommentsTextColor
      aboutUsBackground
      aboutUsTitleColor
      aboutUsTextColor
    }
  }
`;

/**
 * __useGetPdfColorsQuery__
 *
 * To run a query within a React component, call `useGetPdfColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdfColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdfColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPdfColorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPdfColorsQuery,
    GetPdfColorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPdfColorsQuery, GetPdfColorsQueryVariables>(
    GetPdfColorsDocument,
    options
  );
}
export function useGetPdfColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPdfColorsQuery,
    GetPdfColorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPdfColorsQuery, GetPdfColorsQueryVariables>(
    GetPdfColorsDocument,
    options
  );
}
export type GetPdfColorsQueryHookResult = ReturnType<
  typeof useGetPdfColorsQuery
>;
export type GetPdfColorsLazyQueryHookResult = ReturnType<
  typeof useGetPdfColorsLazyQuery
>;
export type GetPdfColorsQueryResult = Apollo.QueryResult<
  GetPdfColorsQuery,
  GetPdfColorsQueryVariables
>;
export const GetProjectCustomDocument = gql`
  query GetProjectCustom($projectId: Int!) {
    getProject(projectId: $projectId) {
      id
      name
      status
      isWithChart
      recommendations
      comments
      aboutUs
      language
      files {
        id
        name
        createdAt
      }
      projectDevelopers {
        projectId
        developerId
        rate
        rank
        color
        developer {
          id
          lastName
          firstName
          defaultRank
          defaultRate
        }
      }
      sprints {
        id
        name
        sortOrder
        totalHours
        name
        startedAt
        storiesAndTasks {
          ... on StoryEntity {
            id
            name
            sprintId
            sortOrder
            startedAtStory: startedAt
            tasks {
              id
              name
              sprintId
              storyId
              developerId
              startedAt
              sortOrder
              createdAt
              updatedAt
              developer {
                defaultRate
                defaultRank
                id
                lastName
                firstName
                avatar
              }
              duration
            }
            totalHours
          }
          ... on TaskEntity {
            id
            name
            sprintId
            storyId
            developerId
            startedAt
            sortOrder
            createdAt
            updatedAt
            developer {
              defaultRate
              defaultRank
              id
              lastName
              firstName
              avatar
            }
            duration
          }
        }
      }
      user {
        id
        name
        status
      }
      projectEstimators {
        id
        name
        status
      }
    }
  }
`;

/**
 * __useGetProjectCustomQuery__
 *
 * To run a query within a React component, call `useGetProjectCustomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCustomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCustomQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectCustomQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCustomQuery,
    GetProjectCustomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectCustomQuery, GetProjectCustomQueryVariables>(
    GetProjectCustomDocument,
    options
  );
}
export function useGetProjectCustomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCustomQuery,
    GetProjectCustomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCustomQuery,
    GetProjectCustomQueryVariables
  >(GetProjectCustomDocument, options);
}
export type GetProjectCustomQueryHookResult = ReturnType<
  typeof useGetProjectCustomQuery
>;
export type GetProjectCustomLazyQueryHookResult = ReturnType<
  typeof useGetProjectCustomLazyQuery
>;
export type GetProjectCustomQueryResult = Apollo.QueryResult<
  GetProjectCustomQuery,
  GetProjectCustomQueryVariables
>;
export const GetProjectsDocument = gql`
  query getProjects($offset: Int, $limit: Int) {
    getProjects(offset: $offset, limit: $limit) {
      projects {
        id
        name
        createdAt
        updatedAt
        status
        totalHours
        isWithChart
        recommendations
        comments
        aboutUs
        projectDevelopers {
          developer {
            id
            lastName
            firstName
            defaultRate
            defaultRank
          }
        }
        user {
          id
          email
          name
          avatar
          status
          role
          createdAt
          updatedAt
          numberOfCreatedProjects
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetSettingsDocument = gql`
  query getSettings {
    getSettings {
      defaultRecommendations
      defaultComments
      defaultAboutUs
      mail
      phone
      link
      logo
      slogan {
        en
        ru
        uk
      }
    }
  }
`;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    options
  );
}
export function useGetSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    options
  );
}
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<
  typeof useGetSettingsLazyQuery
>;
export type GetSettingsQueryResult = Apollo.QueryResult<
  GetSettingsQuery,
  GetSettingsQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    getUser {
      id
      role
      name
      email
      avatar
      status
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      users {
        id
        email
        name
        avatar
        status
        role
        createdAt
        updatedAt
        numberOfCreatedProjects
      }
      count
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const SigninDocument = gql`
  query signin($signinInput: SigninInput!) {
    signin(signinInput: $signinInput) {
      accessToken
      user {
        name
      }
    }
  }
`;

/**
 * __useSigninQuery__
 *
 * To run a query within a React component, call `useSigninQuery` and pass it any options that fit your needs.
 * When your component renders, `useSigninQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSigninQuery({
 *   variables: {
 *      signinInput: // value for 'signinInput'
 *   },
 * });
 */
export function useSigninQuery(
  baseOptions: Apollo.QueryHookOptions<SigninQuery, SigninQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SigninQuery, SigninQueryVariables>(
    SigninDocument,
    options
  );
}
export function useSigninLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SigninQuery, SigninQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SigninQuery, SigninQueryVariables>(
    SigninDocument,
    options
  );
}
export type SigninQueryHookResult = ReturnType<typeof useSigninQuery>;
export type SigninLazyQueryHookResult = ReturnType<typeof useSigninLazyQuery>;
export type SigninQueryResult = Apollo.QueryResult<
  SigninQuery,
  SigninQueryVariables
>;
