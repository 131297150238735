import { useState, useEffect, useMemo } from "react";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { limitItemsCount } from "constants/index";
import ActionsTable from "components/ProjectsTable/TableActions";
import { useGetProjectsLazyQuery } from "graphql/generated";
import { ProjectsRowEntity } from "screens/ProjectsScreen/types";
import styles from "./styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useUserAccess } from "../../hooks";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

interface ProjectsTableProps {
  rows: Array<ProjectsRowEntity>;
  rowCount: number;
  removeProject: (id: number) => () => void;
  setPageNumberState: (page: number) => void;
  page: number;
}

const formatRows = (rows: ProjectsRowEntity[]) => {
  return rows.map((row: ProjectsRowEntity) => {
    const createdTime = new Date(Number(row.createdAt));
    const updatedTime = new Date(Number(row.updatedAt));
    const isTotalHoursExist = !!row.totalHours;
    return {
      ...row,
      createdAt: format(createdTime, "dd-MM-yyyy 'at' h:mm a "),
      updatedAt: format(updatedTime, "dd-MM-yyyy 'at' h:mm a "),
      totalHours: isTotalHoursExist ? row.totalHours : "-",
    };
  });
};

const ProjectsTable = ({
  rows,
  rowCount,
  removeProject,
  setPageNumberState,
  page,
}: ProjectsTableProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleEdit = (id: string | number) => {
    navigate(`/project/${id}`);
  };
  const { isEstimator, isAdmin } = useUserAccess();

  const columns = useMemo(
    () =>
      [
        {
          field: "id",
          headerName: t("id"),
          width: 50,
          minWidth: 20,
          sortable: false,
          renderCell: (params) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                fontSize="12px"
                color="#aaa">
                #{params.value}
              </Box>
            );
          },
        },
        {
          field: "name",
          headerName: t("name"),
          flex: 2,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                fontWeight="500"
                fontSize="15px"
                sx={{ cursor: "pointer", width: "100%" }}
                onClick={() => handleEdit(params.id)}>
                {params.value}
              </Box>
            );
          },
        },
        ...(isAdmin
          ? [
              {
                field: "Manager",
                headerName: t("manager"),
                sortable: false,
                renderCell: (
                  params: GridRenderCellParams<ProjectsRowEntity>
                ) => {
                  const avatarManager = params.row.user?.avatar;
                  return (
                    <Stack justifyContent="center">
                      <Avatar
                        src={`${process.env.REACT_APP_URI}/uploads/avatars/${avatarManager}`}
                        sx={{
                          width: "30px",
                          height: "30px !important",
                        }}
                      />
                    </Stack>
                  );
                },
              },
            ]
          : []),
        {
          field: "totalHours",
          flex: 0.5,
          headerName: t("totalHours"),
          sortable: false,
        },
        {
          field: "createdAt",
          headerName: t("createdAt"),
          width: 220,
          minWidth: 100,
          sortable: false,
        },
        {
          field: "updatedAt",
          headerName: t("updateAt"),
          width: 220,
          minWidth: 100,
          sortable: false,
        },
        {
          field: "status",
          headerName: t("status"),
          width: 150,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => {
            const cellColor = params.value === "ACTIVE" ? "#63b244" : "#c5ab10";

            return (
              <Box display="flex" alignItems="center" color={cellColor}>
                {params.value}
              </Box>
            );
          },
        },
        ...(!isEstimator
          ? [
              {
                field: "actions",
                headerName: t("actions"),
                renderCell: (
                  params: GridRenderCellParams<ProjectsRowEntity>
                ) => {
                  return (
                    <ActionsTable
                      {...{ params }}
                      removeProject={removeProject}
                    />
                  );
                },
                width: 20,
                minWidth: 100,
                sortable: false,
              },
            ]
          : []),
      ] as GridColumns<Partial<ProjectsRowEntity>>,
    [removeProject, i18n.language]
  );

  const [rowState, setRowState] = useState<ProjectsRowEntity[]>(rows);
  const [rowCountState, setRowCountState] = useState<number>(rowCount);

  const [getProjects, { loading }] = useGetProjectsLazyQuery();

  useEffect(() => {
    setRowCountState((prevRowCountState) => rowCount ?? prevRowCountState);
  }, [rowCount]);

  useEffect(() => {
    setRowState(formatRows(rows));
  }, [rows]);

  const handlePageChange = async (pageNumber: number) => {
    setPageNumberState(pageNumber);

    const res = await getProjects({
      variables: {
        offset: pageNumber * limitItemsCount,
        limit: limitItemsCount,
      },
    });
    const newRows = res.data && res.data.getProjects.projects;

    if (newRows) setRowState(formatRows(newRows));
  };

  return (
    <DataGrid
      page={page}
      rows={rowState}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={rowCountState}
      paginationMode="server"
      onPageChange={handlePageChange}
      loading={loading}
      checkboxSelection={false}
      autoHeight
      autoPageSize
      pagination
      disableSelectionOnClick
      disableColumnMenu
      sx={styles}
    />
  );
};

export default ProjectsTable;
