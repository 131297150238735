import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Drawer, DrawerHeader } from "./styles";
import { projectName, ROUTES } from "constants/index";
import { useGetLanguagesQuery, useGetUserQuery } from "graphql/generated";
import { useDrawerContext } from "context/DrawerContext";
import { CustomLink, Header } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

interface LayoutProps {
  children?: ReactNode;
  namePage?: string;
}

export default function Layout({ children, namePage = "" }: LayoutProps) {
  const { t } = useTranslation();
  const { open, setOpen } = useDrawerContext();

  const { data } = useGetUserQuery();
  useGetLanguagesQuery();

  const accessRoleRoutes = () => {
    return data?.getUser?.role === "ADMIN" ? ROUTES : [ROUTES[0]];
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("open", "");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        isOpen={open}
        namePage={namePage}
        userName={data?.getUser?.name}
        onOpen={handleOpen}
      />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box mr="40px">
            <Typography variant="h1">{projectName}</Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {accessRoleRoutes().map((route, index) => {
            return (
              <CustomLink
                to={route.path}
                key={index}
                open={open}
                routeName={t(`sidebar.${route.name}`)}
              />
            );
          })}
        </List>
      </Drawer>
      <Stack component="main" sx={{ flexGrow: 1, p: 3, minHeight: "100vh" }}>
        <DrawerHeader />
        {children}
      </Stack>
    </Box>
  );
}
