import { createDragColumn } from "./createDragColumn";
import { createStartDateColumn } from "./createStartDateColumn";
import { createTaskNameColumn } from "./createTaskNameColumn";
import { createTotalHoursColumn } from "./createTotalHoursColumn";
import { TFunction } from "i18next";

export const ganttWithChart = (t: TFunction) => [
  createDragColumn(t),
  createTaskNameColumn(t),
  createStartDateColumn(t),
  createTotalHoursColumn(t),
];

export const ganttWithoutChart = (t: TFunction) => [
  createDragColumn(t),
  createTaskNameColumn(t),
  createTotalHoursColumn(t),
];
