import { Box, IconButton, Typography } from "@mui/material";
import { MouseEvent, PropsWithChildren } from "react";
import LinkIcon from "@mui/icons-material/Link";

const createScrollToHandler =
  (anchor: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    document
      .querySelector(`.${anchor}`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

export const SectionHeading = ({
  children,
  anchor,
}: PropsWithChildren<{ anchor: string }>) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}>
      <Typography
        sx={{
          fontWeight: 500,
        }}>
        {children}
      </Typography>
      <IconButton
        onClick={createScrollToHandler(anchor)}
        sx={{ marginLeft: "5px" }}>
        <LinkIcon />
      </IconButton>
    </Box>
  );
};
