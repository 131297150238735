import { Popover, Stack } from "@mui/material";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { ColorPickerPopoverProps } from "./types";

export const ColorPickerPopover = ({
  idPopover,
  open,
  anchorEl,
  handleClose,
  parentStack,
  nameForChange,
  colorsStack,
  handleChangeColor,
}: ColorPickerPopoverProps) => {
  return (
    <Popover
      id={idPopover}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <Stack sx={{ padding: "20px" }}>
        <HexColorPicker
          color={
            parentStack && nameForChange
              ? (colorsStack as never)[parentStack][nameForChange]
              : "#000000"
          }
          onChange={handleChangeColor}
        />
        <HexColorInput
          color={
            parentStack && nameForChange
              ? (colorsStack as never)[parentStack][nameForChange]
              : "#000000"
          }
          onChange={handleChangeColor}
          style={{
            marginTop: 10,
          }}
        />
      </Stack>
    </Popover>
  );
};
