import React, { useState } from "react";
import { GridCellParams } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useEditAttachedDeveloperInProjectMutation } from "graphql/generated";
import { GET_PROJECT } from "graphql/queries/getProject.gql";
import { useTranslation } from "react-i18next";

interface RankCellProps {
  params: GridCellParams;
}

const RankCell: React.FC<RankCellProps> = ({ params }) => {
  const { t } = useTranslation(["project-details"]);
  const { id } = useParams();
  const [inputValue, setInputValue] = useState<string>(params.row.defaultRank);
  const [editAttachedDeveloperInProjectMutation] =
    useEditAttachedDeveloperInProjectMutation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const editDeveloperRank = async () => {
    if (!params.row.firstName) return;
    await editAttachedDeveloperInProjectMutation({
      variables: {
        projectId: Number(id),
        developerId: Number(params.id),
        input: {
          rank: inputValue,
          rate: params.row.defaultRate,
          color: params.row.color,
        },
      },
      refetchQueries: () => [
        {
          query: GET_PROJECT,
          variables: {
            projectId: Number(id),
          },
        },
      ],
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLInputElement;
      const inputValueArray = inputValue.split("");
      const cursorPosition = target.selectionStart;

      if (
        cursorPosition !== null &&
        cursorPosition > 0 &&
        inputValueArray[cursorPosition - 1] !== " "
      ) {
        const updatedCursorPosition = cursorPosition + 1;
        inputValueArray.splice(cursorPosition, 0, " ");
        const updatedValue = inputValueArray.join("");
        setInputValue(updatedValue);

        // Устанавливаем позицию курсора после добавленного пробела
        setTimeout(() => {
          target.setSelectionRange(
            updatedCursorPosition,
            updatedCursorPosition
          );
          target.focus();
        }, 0);
      }
    }
  };

  return (
    <TextField
      label={t("rank")}
      variant="filled"
      onChange={handleChange}
      value={inputValue}
      onBlur={editDeveloperRank}
      onKeyDown={handleKeyDown}
    />
  );
};

export default RankCell;
