import { Box } from "@mui/material";
import { TabPanelProps } from "./types";

export const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </Box>
  );
};
