import * as yup from "yup";
import { TFunction } from "i18next";

type SettingsTabSchemaProps = {
  isAdmin: boolean;
  isManager: boolean;
  isEstimator: boolean;
  t: TFunction;
};
export const settingsTabSchema = ({
  isAdmin,
  isManager,
  isEstimator,
  t,
}: SettingsTabSchemaProps) =>
  yup.object().shape({
    ...(!isEstimator && {
      name: yup.string().required(
        t("requiredField", {
          fieldName: t("newProject", { ns: "projects" }),
          interpolation: { escapeValue: false },
          ns: "common",
        })
      ),
      status: yup.string().required(
        t("requiredField", {
          fieldName: t("projectStatus", { ns: "projects" }),
          ns: "common",
        })
      ),
      isWithChart: yup.boolean(),
    }),
    recommendations: yup.string().nullable(),
    comments: yup.string().nullable(),
    aboutUs: yup.string().nullable(),
    ...((isManager || isAdmin) && {
      projectEstimators: yup
        .array()
        .min(1, t("errors.selectEstimatorsIsRequiredField", { ns: "common" }))
        .required(
          t("errors.selectEstimatorsIsRequiredField", { ns: "common" })
        ),
    }),
    ...(isAdmin && {
      userId: yup.string().required(
        t("requiredField", {
          fieldName: t("manager", { ns: "common" }),
          ns: "common",
        })
      ),
    }),
  });
