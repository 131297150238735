import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select";

export const useChangeLanguage = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLang = (e: SelectChangeEvent) => {
    const lang = e.target.value as string;
    return i18n.changeLanguage(lang);
  };

  return { changeLang, currentLanguage, t };
};
