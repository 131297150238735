import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userSchemaCreate } from "validations/UserValidations/userSchema";
import TextField from "@mui/material/TextField";
import { styles } from "components/ModalController/ModalCreateEditUser/styles";
import { useModalContext } from "context/ModalContext";
import { Controller } from "react-hook-form";
import {
  useEditUserMutation,
  useCreateUserMutation,
  CreateUserInput,
  UserStatusEnum,
  UserRoleEnum,
} from "graphql/generated";
import { GET_USERS } from "graphql/queries/getUsers.gql";
import {
  alertTypes,
  limitItemsCount,
  USER_ROLE,
  USER_STATUS,
} from "constants/index";
import DropzoneRHF from "components/DropzoneRHF";
import { useAlertContext } from "context/AlertContext";
import { postUploadAvatarThunk } from "../../../thunk/postUploadAvatarThunk";
import { SelectRHF } from "../../UI";
import { useTranslation } from "react-i18next";

interface ModalCreateUserProps {
  open: boolean;
}

type UserFormType = CreateUserInput & { uploadAvatar: File | null };

const ModalCreateEditUser = ({ open }: ModalCreateUserProps) => {
  const { t } = useTranslation();
  const { modalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();
  const { row, pageNumber } = modalParameters.params as {
    pageNumber: number;
    row: UserFormType & { id: number };
  };
  const isEditMode = !!row;

  const methods = useForm<UserFormType>({
    mode: "onBlur",
    resolver: yupResolver(userSchemaCreate({ t })),
    defaultValues: {
      avatar: isEditMode ? row.avatar : "",
      name: isEditMode ? row.name : "",
      email: isEditMode ? row.email : "",
      status: isEditMode ? row.status : ("INACTIVE" as UserStatusEnum),
      role: isEditMode ? row.role : ("MANAGER" as UserRoleEnum),
      uploadAvatar: null,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = methods;
  const rowAvatar = watch("avatar");

  const [createUserMutation] = useCreateUserMutation();
  const [editUserMutation] = useEditUserMutation();

  const handleClose = () => {
    modalParameters.cancelAction?.();
  };

  const handleError = (e: any) => {
    const errorObj = e.graphQLErrors[0];
    if (errorObj[0].status !== 400) {
      handleChangeAlertParameters({
        isOpen: true,
        message: errorObj[0].message,
        type: alertTypes.error,
      });
    }

    if (errorObj[0].status === 400) {
      setError(errorObj[0].field, {
        type: "custom",
        message: errorObj[0].message,
      });
      handleChangeAlertParameters({
        isOpen: true,
        message: errorObj[0].message,
        type: alertTypes.error,
      });
    }
  };

  const handleErrorFile = () => {
    handleChangeAlertParameters({
      isOpen: true,
      message: t("somethingWentWrong"),
      type: alertTypes.error,
    });
  };

  const showSuccessAlert = (message: string) => {
    handleChangeAlertParameters({
      isOpen: true,
      type: alertTypes.success,
      message,
    });
  };

  const onSubmit = async (data: UserFormType) => {
    const requestData: Omit<CreateUserInput, "avatar"> = {
      name: data.name,
      status: data.status,
      email: data.email,
      role: data.role,
    };
    const uploadFile = data.uploadAvatar;

    let responseAvatar = "";
    if (uploadFile) {
      const formData = new FormData();
      formData.append("avatar", uploadFile || "");
      const response: Response = await postUploadAvatarThunk({ formData });
      if (!response.ok) {
        handleErrorFile();
      }
      responseAvatar = await response.text();
    }

    if (isEditMode) {
      const getAvatarFieldData = () => {
        if (!uploadFile && !rowAvatar) {
          return "";
        }
        if (uploadFile) {
          return responseAvatar;
        }
        return rowAvatar;
      };

      const res = await editUserMutation({
        variables: {
          input: {
            ...requestData,
            avatar: getAvatarFieldData(),
            id: Number(row.id),
          },
        },
        onError: handleError,
        refetchQueries: () => [
          {
            query: GET_USERS,
            variables: {
              input: { offset: 0, limit: limitItemsCount },
            },
          },
        ],
      });

      if (res.data) {
        showSuccessAlert(`${t("userWasSaved")}.`);
      }
    } else {
      const res = await createUserMutation({
        variables: {
          input: { ...requestData, avatar: responseAvatar },
        },
        onError: handleError,
        refetchQueries: () => [
          {
            query: GET_USERS,
            variables: {
              input: {
                offset: pageNumber * limitItemsCount,
                limit: limitItemsCount,
              },
            },
          },
        ],
      });

      if (res.data) {
        showSuccessAlert(`${t("userWasCreated")}.`);
      }
    }
    handleClose();
    reset();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2">
          {isEditMode ? t("editUser") : t("createUser")}
        </Typography>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={styles.form}>
            <TextField
              {...register("name")}
              sx={styles.input}
              label={t("userName")}
              variant="outlined"
              helperText={errors?.name && errors?.name?.message}
              error={!!errors?.name}
              autoFocus
            />
            <Box sx={{ marginBlock: "12px" }}>
              <Controller
                name="uploadAvatar"
                control={control}
                render={({ field: { onChange } }) => (
                  <DropzoneRHF
                    name={"uploadAvatar"}
                    nameFieldToNullable={"avatar"}
                    setRHFvalue={setValue}
                    onChange={onChange}
                    avatarName={row?.avatar ?? ""}
                  />
                )}
              />
            </Box>
            <TextField
              {...register("email")}
              sx={styles.input}
              label={t("emailAddress")}
              variant="outlined"
              helperText={errors?.email && errors?.email?.message}
              error={!!errors?.email}
            />
            <SelectRHF
              name={"status"}
              options={USER_STATUS}
              sx={styles.input}
            />
            <SelectRHF name={"role"} options={USER_ROLE} sx={styles.input} />
            <Box sx={styles.buttonBox}>
              <Button variant="contained" type="submit">
                {isEditMode ? t("save") : t("create")}
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default ModalCreateEditUser;
