import { Button, Stack, Typography } from "@mui/material";
import { Dispatch, MouseEvent, SetStateAction } from "react";
import { ChildColorStackType } from "../PaletteSettingsTab/types";

type ParentStackKey =
  | "header"
  | "estimate"
  | "rate"
  | "payments"
  | "recommendation"
  | "team"
  | "comments"
  | "aboutUs";

interface ColorPickerButtonProps {
  colors: Partial<Record<ChildColorStackType, string>>;
  section: Record<string, string>;
  setParentStack: Dispatch<SetStateAction<ParentStackKey | null>>;
  parentStackKey: ParentStackKey;
  onClickByColor: (
    event: MouseEvent<HTMLButtonElement>,
    colorForStack: ChildColorStackType
  ) => void;
}

export const ColorPickerButton = ({
  colors,
  section,
  setParentStack,
  onClickByColor,
  parentStackKey,
}: ColorPickerButtonProps) => {
  return (
    <Stack gap={1}>
      {Object.keys(colors).map((key, i) => {
        const bg = colors[key as ChildColorStackType];

        return (
          <Stack key={i} direction={"row"} gap={2}>
            <Button
              sx={{
                background: bg,
                "&:hover": {
                  background: bg,
                },
              }}
              variant={"smColorPicker"}
              onClick={(e) => {
                setParentStack(parentStackKey);
                onClickByColor(e, key as ChildColorStackType);
              }}
            />
            <Typography>{section[key]}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
