import React, { useState } from "react";
import { GridCellParams } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  DeveloperEntity,
  useEditAttachedDeveloperInProjectMutation,
  useAttachDeveloperToProjectMutation,
  GetProjectCustomDocument,
} from "graphql/generated";
import { useParams } from "react-router-dom";
import { DEFAULT_DEVELOPER_COLOR } from "../../constants";
import { useTranslation } from "react-i18next";
import { getFullname } from "utils";

interface NameCellProps {
  params: GridCellParams;
  allDevelopers: Array<Partial<DeveloperEntity>>;
}

interface EditResponse {
  newDeveloperId: string;
  defaultRank?: string;
  defaultRate?: number;
  firstName?: string;
  lastName?: string;
  color?: string;
}

interface Developer extends DeveloperEntity {
  isOnProject?: boolean;
}

const NameCell: React.FC<NameCellProps> = ({ params, allDevelopers }) => {
  const { t } = useTranslation(["project-details"]);
  const [cellValue, setCellValue] = useState<string | undefined>(
    params.row?.id ? params.row.id : ""
  );
  const { id } = useParams();
  const [editAttachedDeveloperInProjectMutation] =
    useEditAttachedDeveloperInProjectMutation();
  const [attachDeveloperToProjectMutation] =
    useAttachDeveloperToProjectMutation();

  const editDeveloper = async ({
    newDeveloperId,
    defaultRank,
    defaultRate,
    color,
  }: EditResponse) => {
    await editAttachedDeveloperInProjectMutation({
      variables: {
        projectId: Number(id),
        developerId: Number(params.id),
        input: {
          rank: defaultRank as string,
          rate: defaultRate as number,
          newDeveloperId: Number(newDeveloperId),
          color: String(color),
        },
      },
      refetchQueries: [GetProjectCustomDocument],
    });
  };

  const attachDeveloper = async ({ newDeveloperId }: EditResponse) => {
    await attachDeveloperToProjectMutation({
      variables: {
        input: {
          developerId: Number(newDeveloperId),
          projectId: Number(id),
          color: DEFAULT_DEVELOPER_COLOR,
        },
      },
      refetchQueries: [GetProjectCustomDocument],
    });
  };

  const isEditMode = Boolean(params.row.firstName);

  const handleChange = ({
    newDeveloperId,
    defaultRank,
    defaultRate,
    color,
  }: EditResponse) => {
    if (isEditMode) {
      return editDeveloper({ newDeveloperId, defaultRank, defaultRate, color });
    }
    return attachDeveloper({ newDeveloperId });
  };

  const handleSelect = async (event: SelectChangeEvent) => {
    const id = event.target.value;
    const developer = allDevelopers.find((dev) => dev.id === id);

    setCellValue(id);

    if (developer) {
      await handleChange({
        newDeveloperId: developer.id as string,
        defaultRank: developer.defaultRank as string,
        defaultRate: developer.defaultRate as number,
        color: DEFAULT_DEVELOPER_COLOR,
      });
    }

    if (!isEditMode) {
      setCellValue("");
    }
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-label">{t("name")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={cellValue}
        label={t("name")}
        onChange={handleSelect}
        MenuProps={{
          style: {
            maxHeight: 250,
          },
        }}>
        {allDevelopers.map((developer: Partial<Developer>) => (
          <MenuItem
            key={developer.id}
            value={developer.id}
            sx={{
              display: developer.isOnProject ? "none" : "block",
            }}>
            {getFullname(developer.firstName, developer.lastName)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NameCell;
