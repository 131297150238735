import { Box } from "@mui/material";

interface DateHeaderProps {
  backgroundColor: string;
  priceBackground: string;
  textColor: string;
}

export const DateHeader = ({
  backgroundColor,
  priceBackground,
  textColor,
}: DateHeaderProps) => {
  return (
    <Box
      id="payment-header-background"
      sx={{
        backgroundColor: backgroundColor,
        padding: "5px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Box>Name of month</Box>
      <Box
        sx={{
          backgroundColor: priceBackground,
          padding: "5px",
          color: textColor,
          borderRadius: "4px",
        }}>
        3 400$
      </Box>
    </Box>
  );
};
