import Box from "@mui/material/Box";

interface RuFlagProps {
  width?: string | number;
  height?: string | number;
}

export const RuFlag = ({ width = "30", height = "23" }: RuFlagProps) => {
  return (
    <Box
      sx={{
        width: `${width}px`,
        height: `${height}px !important`,
        background: "#adadad",
      }}
    />
  );
};
