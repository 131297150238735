import { format } from "date-fns";
import { Task } from "../types";
import styles from "../index.module.css";
import clsx from "clsx";
import { TFunction } from "i18next";

export const createStartDateColumn = (t: TFunction) => ({
  name: "start_date",
  label: t("startedDate"),
  width: 140,
  align: "center",
  template: (task: Task) => {
    let dateStr;

    if (task.type === "project" || task.type === "story") {
      dateStr = format(task.start_date, "dd-MM-yyyy HH:mm");
    } else {
      dateStr = format(new Date(Number(task.start_date)), "dd-MM-yyyy HH:mm");
    }

    return `<div class="startedDate">
                <i class="${clsx("material-icons", styles.muiIcon)}">
                    calendar_month
                </i>
            ${dateStr}
            </div>`;
  },
});
