import { gql } from "graphql-tag";

export const GET_DEVELOPERS = gql`
  query getDevelopersList($offset: Int, $limit: Int) {
    getDevelopersList(offset: $offset, limit: $limit) {
      developers {
        id
        firstName
        lastName
        defaultRank
        createdAt
        status
        defaultRate
        avatar
      }
      count
    }
  }
`;
