import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ErrorImage from "../../../../images/defaultAvatar.png";

interface PdfRateProps {
  background: string;
  cardBackground: string;
  titleColor: string;
  devNameColor: string;
  rankColor: string;
  devRateColor: string;
}

export const PdfRate = ({
  background,
  cardBackground,
  titleColor,
  devNameColor,
  rankColor,
  devRateColor,
}: PdfRateProps) => {
  return (
    <Stack
      className="rate-block"
      sx={{ background: background, padding: "50px 100px" }}>
      <Typography
        variant={"caption"}
        sx={{ marginBottom: "10px" }}
        color={titleColor}>
        Rates
      </Typography>
      <Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "10px",
          }}>
          {Array.from({ length: 6 }, (v, k) => k).map((_, i) => {
            return (
              <Stack
                key={i}
                sx={{
                  padding: "10px",
                  background: cardBackground,
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "5px",
                }}>
                <img src={ErrorImage} alt="i" width={"40px"} height={"40px"} />
                <Stack ml={"10px"}>
                  <Typography color={devNameColor}>John Doe</Typography>
                  <Typography color={rankColor}>developer</Typography>
                </Stack>
                <Stack
                  ml={"auto"}
                  sx={{
                    // height: "100%",
                    background: "black",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                    color: "white",
                    borderRadius: "5px",
                  }}>
                  <Typography color={devRateColor}>$10/1hour</Typography>
                </Stack>
              </Stack>
            );
          })}
        </Box>
      </Stack>
    </Stack>
  );
};
