import { yupResolver } from "@hookform/resolvers/yup";
import { useSigninLazyQuery } from "graphql/generated";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { styles } from "screens/AuthScreens/styles";
import { userSchemaSignIn } from "validations/AuthValidations";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "constants/index";
import { PasswordInput } from "./PasswordInput";

interface FormData {
  email: string;
  password: string;
}

interface CustomError {
  field: keyof FormData;
  message: string;
  status: number;
}

const LogInScreen: React.FC = () => {
  const { handleChangeAlertParameters } = useAlertContext();
  const navigate = useNavigate();

  const [signIn] = useSigninLazyQuery();

  const methods = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(userSchemaSignIn),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormData) => {
    const res = await signIn({
      variables: {
        signinInput: data,
      },
    });

    if (res.error) {
      const errors = res.error?.graphQLErrors[0] as unknown as CustomError[];

      if (errors[0].status === 400 && errors[1].status === 400) {
        setError(errors[0].field, {
          type: "custom",
          message: errors[0].message,
        });
        setError(errors[1].field, {
          type: "custom",
          message: errors[1].message,
        });
      } else {
        handleChangeAlertParameters({
          isOpen: true,
          message: errors[0].message,
          type: alertTypes.error,
        });
      }
    }

    if (res.data) {
      localStorage.setItem("token", res.data.signin.accessToken);
      navigate(0);
    }
  };

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Typography sx={styles.title} align="center" variant="h4">
        Sign in
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={styles.inputContainer}>
            <TextField
              {...register("email")}
              sx={styles.input}
              label="Email Adress"
              variant="outlined"
              helperText={errors?.email && errors?.email?.message}
              error={!!errors?.email}
            />
            <PasswordInput />
          </Box>
          <Button sx={styles.button} variant="contained" type="submit">
            <Typography variant="button">sign in</Typography>
          </Button>
        </form>
      </FormProvider>
      <Box sx={styles.linksContainer}>
        <Link to="/password-reset">
          <Typography variant="body2">Forgot password?</Typography>
        </Link>
      </Box>
    </Container>
  );
};

export default LogInScreen;
