import { TFunction } from "i18next";
import * as yup from "yup";

export const hasStartedAtFieldTaskSchemaCreate = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    name: yup.string().required(
      t("requiredField", {
        fieldName: t("taskName"),
      })
    ),
    startedAt: yup
      .date()
      .typeError(
        t("requiredField", {
          fieldName: t("startedDate"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("startedDate"),
        })
      ),
    duration: yup
      .number()
      .typeError(
        t("mustBeNumber", {
          fieldName: t("duration"),
        })
      )
      .positive(
        t("positive", {
          fieldName: t("duration"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("duration"),
        })
      ),
    developerId: yup
      .number()
      .typeError(
        t("requiredField", {
          fieldName: t("selectDeveloper"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("selectDeveloper"),
        })
      ),
  });

export const hasNotStartedAtFieldTaskSchemaCreate = ({ t }: { t: TFunction }) =>
  yup.object().shape({
    name: yup.string().required(
      t("requiredField", {
        fieldName: t("taskName"),
      })
    ),
    duration: yup
      .number()
      .typeError(
        t("mustBeNumber", {
          fieldName: t("duration"),
        })
      )
      .positive(
        t("positive", {
          fieldName: t("duration"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("duration"),
        })
      ),
    developerId: yup
      .number()
      .typeError(
        t("requiredField", {
          fieldName: t("selectDeveloper"),
        })
      )
      .required(
        t("requiredField", {
          fieldName: t("selectDeveloper"),
        })
      ),
  });
