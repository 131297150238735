import { TFunction } from "i18next";

export const createDragColumn = (t: TFunction) => ({
  name: "reordering",
  label: t("drag"),
  width: 40,
  height: 20,
  align: "center",
  template: () => {
    return `<div class="wrapperIcon">
                <i class="material-icons click-drag">apps</i>
            </div>`;
  },
});
