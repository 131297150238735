import React, { Dispatch, SetStateAction, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import {
  useCreateStoryMutation,
  useEditStoryMutation,
} from "graphql/generated";
import { styles } from "components/ModalController/ModalCreateStoryTask/StoryModal/styles";
import { useModalContext } from "context/ModalContext";
import { storySchemaCreate } from "validations/StoryCreateValidations";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "constants/constants";
import { useTranslation } from "react-i18next";

interface FormData {
  name: string;
}

interface StoryModal {
  sharedName: string;
  setSharedName: Dispatch<SetStateAction<string>>;
}

const StoryModal: React.FC<StoryModal> = ({ sharedName, setSharedName }) => {
  const { t } = useTranslation();
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  const [createStoryMutation] = useCreateStoryMutation();
  const [editStoryMutation] = useEditStoryMutation();
  const { handleChangeAlertParameters } = useAlertContext();

  const { sprintId, isChangeName, defaultValues, storyId, refetchProjectData } =
    (modalParameters.params as {
      sprintId: string;
      storyId: string;
      isChangeName: boolean;
      defaultValues: {
        name: string;
      };
      refetchProjectData: () => void;
    }) ?? {};

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onSubmit",
    resolver: yupResolver(storySchemaCreate({ t })),
    defaultValues: {
      name: isChangeName ? defaultValues?.name : sharedName,
    },
  });

  const nameValue = watch("name");

  useEffect(() => {
    setSharedName(nameValue);
  }, [nameValue, setSharedName]);

  const handleClose = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  const onSubmit = async (data: FormData) => {
    if (isChangeName) {
      const response = await editStoryMutation({
        variables: {
          input: {
            name: data.name,
          },
          storyId: Number(storyId),
        },
      });

      if (response?.data?.editStory?.name) {
        handleChangeAlertParameters({
          isOpen: true,
          type: alertTypes.success,
          message: t("storyEditedSuccess"),
        });
      }
    } else {
      await createStoryMutation({
        variables: {
          input: {
            name: data.name,
            sprintId: Number(sprintId),
          },
        },
      });
    }

    await refetchProjectData?.();

    handleClose();
    reset();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
      <TextField
        {...register("name")}
        sx={styles.input}
        label={t("storyName")}
        variant="outlined"
        helperText={errors?.name && errors?.name?.message}
        error={!!errors?.name}
        autoFocus
      />
      <Box sx={styles.buttonBox}>
        <Button variant="contained" type="submit">
          {isChangeName ? t("change") : t("create")}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default StoryModal;
