import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Stack, SxProps } from "@mui/material";
import { RuFlag } from "../../../images/RuFlag";
import { LanguageType } from "../../../graphql/generated";
import { getSegmentUrl } from "../../../utils";

interface LanguagePickerProps {
  sx?: SxProps;
  width?: string;
  maxWidth?: string;
  options?: LanguageType[];
  name: string;
  inputLabel?: string;
}

export const LanguagePicker = ({
  sx,
  width = "130px",
  maxWidth = "130px",
  options,
  name,
  inputLabel,
}: LanguagePickerProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl sx={{ width, maxWidth, ...sx }}>
            <InputLabel id="lang-select-label">{inputLabel}</InputLabel>
            <Select
              labelId="lang-select-label"
              onChange={onChange}
              value={value}
              label={inputLabel}>
              {options?.map((option) => {
                return (
                  <MenuItem value={option.value} key={option.value}>
                    <Stack direction={"row"} gap={"15px"}>
                      {option.value !== "ru" ? (
                        <img
                          width={"30"}
                          src={`https://flagcdn.com/h40/${getSegmentUrl(
                            option.value
                          ).toLowerCase()}.webp`}
                          alt={"i"}
                        />
                      ) : (
                        <RuFlag />
                      )}
                      {option.value.toUpperCase()}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

interface LanguagePickerCustomProps {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  options: {
    value: string;
    label: string;
  }[];
}

export const LanguagePickerCustom = ({
  value,
  onChange,
  options,
}: LanguagePickerCustomProps) => {
  return (
    <Select
      sx={{
        ".MuiSelect-select": {
          padding: "5px 32px 5px 5px",
          color: "#fff",
          border: "none",
        },
        ".MuiSvgIcon-root": {
          fill: "#fff",
        },
        fieldset: { border: "none" },
      }}
      value={value}
      onChange={onChange}>
      {options
        ? options.map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                  {option.value === "ru" ? (
                    <RuFlag width={"20"} height={"15"} />
                  ) : (
                    <img
                      width={"20"}
                      height={"15"}
                      src={`https://flagcdn.com/h40/${getSegmentUrl(
                        option.value
                      ).toLowerCase()}.webp`}
                      alt={"i"}
                    />
                  )}
                  <div>{option.label}</div>
                </Stack>
              </MenuItem>
            );
          })
        : null}
    </Select>
  );
};
