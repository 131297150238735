import { GetProjectCustomQuery, SprintEntity } from "graphql/generated";

export const getSprints = (data?: GetProjectCustomQuery) => {
  return (data?.getProject.sprints ?? []) as SprintEntity[];
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
